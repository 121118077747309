import React, { Component, Fragment } from 'react';
import { Create } from 'react-admin';
import { NotificationCreateForm } from '../';
import {BackButton} from '../../../common';


class NotificationCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Create Notification</h1>
                <Create {...this.props} title=" ">
                    <NotificationCreateForm />
                </Create>
            </Fragment>
        );
    }
}
export default NotificationCreate;
