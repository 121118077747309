import React, {Fragment} from 'react';
import {
    TextField,
    RichTextField,
    FunctionField,
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../../common';


export const CourseCategoryShow = props => {
    return (
        <Fragment>
            <DetailsLayout {...props} options={{detailsTitle: 'category'}}>
                <DetailsTileWrapper
                    sideTitle='Course Category Details'
                    sideText='Add your Course Category here. Later you will be able
                        to distribute your courses in the Course Categories you want.'
                >
                    <TextField source='Name' label='Course Category Name' />
                    <RichTextField source='Description' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
