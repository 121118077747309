import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    BooleanInput,
    FormDataConsumer
} from 'react-admin';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {PhoneMaskedInput, ZipCodeMaskedInput, UsStates} from '../../../common';
import {emailValidation, emailAlternateValidation} from '../../../validators';
import {cellPhoneValidation, homePhoneValidation, workPhoneValidation, zipCodeValidation} from '../../../validators';
import {formatPhone} from "../../../helpers";
import {NtaSaveButton} from '../../common';


export const TeacherFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

export const TeacherFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

export const AddressHeading = () => (
    <h2>
        Mail address details
    </h2>
);

class TeacherForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        if (values.HomePhone) {
            values.HomePhone = values.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        if (values.WorkPhone) {
            values.WorkPhone = values.WorkPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        fetchIt(
            requestType,
            'teachers',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Teacher saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.HomePhone = formatPhone(values.HomePhone);
                    values.WorkPhone = formatPhone(values.WorkPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.HomePhone = formatPhone(values.HomePhone);
                    values.WorkPhone = formatPhone(values.WorkPhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        {this.props.editMode && this.props.record.Address ?
                            <SimpleForm
                                resource={this.props.resource}
                                record={this.props.record}
                                save={this.handleSubmit}
                                toolbar={<TeacherFormToolbar isDisabled={this.state.isDisabled} />}
                            >
                                <TextInput source="FirstName" className='fieldsWidthExpand' validate={required('The First Name field is required')} />
                                <TextInput source="LastName" className='fieldsWidthExpand' validate={required('The Last Name field is required')} />
                                <TextInput source="DisplayName" className='fieldsWidthExpand' validate={required('The Display Name field is required')} />
                                <FormDataConsumer>
                                    {
                                        ({formData}) => {
                                            return <TeacherFormTitle title={formData.DisplayName} />;
                                        }
                                    }
                                </FormDataConsumer>
                                <PhoneMaskedInput
                                    required
                                    source='CellPhone'
                                    validate={cellPhoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput type='email' source='Email'  className='fieldsWidthExpand' validate={emailValidation} />
                                <BooleanInput source='Active' defaultValue={this.props.editMode ? '' : true}/>

                                <AddressHeading />
                                <PhoneMaskedInput
                                    source='HomePhone'
                                    validate={homePhoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <PhoneMaskedInput
                                    source='WorkPhone'
                                    validate={workPhoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput type='email'
                                    className='fieldsWidthExpand'
                                    source='EmailAlternate'
                                    validate={emailAlternateValidation}
                                />
                                <TextInput source='Address.City'
                                    label='City'
                                    className='fieldsWidthExpand'
                                    validate={required('The City field is required')}
                                />
                                <UsStates source='Address.USState' className='fieldsWidthExpand' validate={required('The State field is required')}/>
                                <ZipCodeMaskedInput
                                    required
                                    source='Address.ZIP'
                                    label='Zip'
                                    validate={zipCodeValidation}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput
                                    multiline
                                    source='Address.StreetAddress'
                                    label='Street Address'
                                    validate={required('The Street Address field is required')}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput source='Address.StreetAddressLine2'  className='fieldsWidthExpand' label='Street Address Line 2' />
                            </SimpleForm>
                            :
                            <SimpleForm
                                resource={this.props.resource}
                                record={this.props.record}
                                save={this.handleSubmit}
                                toolbar={<TeacherFormToolbar isDisabled={this.state.isDisabled} />}
                            >
                                <TextInput source="FirstName" className='fieldsWidthExpand' validate={required('The First Name field is required')} />
                                <TextInput source="LastName" className='fieldsWidthExpand' validate={required('The Last Name field is required')} />
                                <TextInput source="DisplayName" className='fieldsWidthExpand' validate={required('The Display Name field is required')} />
                                <FormDataConsumer>
                                    {
                                        ({formData}) => {
                                            return <TeacherFormTitle title={formData.DisplayName} />;
                                        }
                                    }
                                </FormDataConsumer>
                                <PhoneMaskedInput
                                    required
                                    source='CellPhone'
                                    validate={cellPhoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput type='email' source='Email'  className='fieldsWidthExpand' validate={emailValidation} />
                                <BooleanInput source='Active' label='Is active' defaultValue={this.props.editMode ? '' : true}/>
                            </SimpleForm>
                        }
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}

export default TeacherForm;