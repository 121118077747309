import React, {Fragment, Component} from 'react';
import {GET_ONE} from 'react-admin';
import AgeIntervalForm from '../containers/AgeIntervalForm';
import {fetchIt} from "../../../../helpers/fetcher";
import {Redirect} from 'react-router';
import {BackButton} from '../../../../common';


export const AgeIntervalTitle = () => {
    return <h1 id="the-title"> </h1>;
};

class AgeIntervalEdit extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            record: {},
            redirectTo: null
        };
    }


    componentDidMount() {
        this._isMounted = true;
        const { fetchStart, fetchEnd, showNotification } = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'age_intervals',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (!response.data.FromAge) {
                        response.data.FromAge = 0;
                    }

                    let months = parseInt(response.data.FromAge);
                    let years = months / 12;
                    let theHalf = false;
                    if (Math.floor(years) < years) {
                        theHalf = true;
                    }
                    years = Math.floor(years);

                    response.data.FromAge={};
                    response.data.FromAge.Years = years;
                    response.data.FromAge.TheHalf = theHalf;

                    months = parseInt(response.data.ToAge);
                    years = months / 12;
                    theHalf = false;
                    if (Math.floor(years) < years) {
                        theHalf = true;
                    }
                    years = Math.floor(years);

                    response.data.ToAge={};
                    response.data.ToAge.Years = years;
                    response.data.ToAge.TheHalf = theHalf;

                    if (me._isMounted) {
                        me.setState({
                            record: {...response.data}
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <BackButton />
                <AgeIntervalTitle record={this.state.record} />
                <AgeIntervalForm record={this.state.record} {...this.props} editMode={true}/>
            </Fragment>
        );
    }
}
export default AgeIntervalEdit;
