import React, {Component} from "react";
import InputMask from 'react-input-mask';
import {addField, TextInput} from "react-admin";


class PhoneMaskedInput extends Component {
    beforeMaskedValueChange = (newState, userInput) => {
        let {value} = newState;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.props.input.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {start: cursorPosition, end: cursorPosition};
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    };

    onKeyDown = e => {
        // Get cursor position and key code
        let cursorPos = Number(e.target.selectionStart)
        let keyCode = Number(e.keyCode)
    
        // Do work only on backspace key and on cursor position 6 (for ')' and 10 for '-')
        if ((cursorPos === 6 || cursorPos === 10) && keyCode === 8) {
            // In my personal case I store phone nombers as just numbers 9999999999, instead of (999) 999-9999 
            e.target.value = e.target.value || ''
            e.target.value = e.target.value.replace(/\D+/g, '')

            // Simulate backspace delete
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
        }
    }

    render() {
        return (
            <InputMask
                mask="(999) 999-9999"
                maskChar={null}
                beforeMaskedValueChange={this.beforeMaskedValueChange}
                onKeyDown={this.onKeyDown}
                value={this.props.input.value}
            >
                {(inputProps) => <TextInput {...inputProps} { ...this.props }  autoComplete="nope" />}
            </InputMask>
        );
    }
}

export default addField(PhoneMaskedInput);