import React from 'react';
import {addField, Labeled} from 'react-admin';
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const BooleanFilterInput = ({input, meta: {touched, error}, source, record, id, label, ...props}) => {
    let choices = [
        {
            value: true,
            name: 'Yes'
        },
        {
            value: false,
            name: 'No'
        }
    ];

    if (props.choices) {
        choices = props.choices;
    }

    return (
        <Labeled source={source}>
            <FormControl style={{minWidth: 256, flexDirection: "row"}} error={touched && error}>
                <Select
                    native
                    style={{minWidth: 121}}
                    source={source}
                    record={record}
                    value={input.value}
                    onChange={input.onChange}
                >
                    {
                        choices.map(choice => (
                            <option key={choice.name} value={choice.value}>{choice.name}</option>
                        ))
                    }
                </Select>
                {touched && error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Labeled>
    );
};

export default addField(BooleanFilterInput); // decorate with react-final-form's <Field>
