export const choices = [
    {
        value: true,
        name: 'Active'
    },
    {
        value: false,
        name: 'Inactive'
    }
];

export const classTypeChoices = [
    {
        id: 1,
        name: 'Session Classes'
    },
    {
        id: 2,
        name: 'Independent Classes'
    },
    // {
    //     id: 3,
    //     name: 'Special Events'
    // },
    // {
    //     id: 4,
    //     name: 'Group Events'
    // },
];

export const feesTypeChoices = [
    {
        id: 'Paid Once',
        name: 'Paid Once'
    },
    {
        id: 'Paid Multiple Times',
        name: 'Paid Multiple Times'
    },
    {
        id: 'Paid Once Per Student',
        name: 'Paid Once Per Student'
    }
];

export const approvedRoles = [
    'Administrator',
    'School Manager'
];

export const weekdays = [
    {
        id: 'Monday',
        name: 'Monday'
    },
    {
        id: 'Tuesday',
        name: 'Tuesday'
    },
    {
        id: 'Wednesday',
        name: 'Wednesday'
    },
    {
        id: 'Thursday',
        name: 'Thursday'
    },
    {
        id: 'Friday',
        name: 'Friday'
    },
    {
        id: 'Saturday',
        name: 'Saturday'
    },
    {
        id: 'Sunday',
        name: 'Sunday'
    },
];
