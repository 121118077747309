import React, {Component} from 'react';
import {
    UPDATE,
    required,
    TextInput,
    Toolbar,
    TabbedForm,
    FormTab,
    ShowButton
} from 'react-admin';
import  {NtaSaveButton} from '../../common';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {Redirect} from 'react-router';
import CardActions from '@material-ui/core/CardActions';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {PhoneMaskedInput, ZipCodeMaskedInput, UsStates} from '../../../common';
import {phoneValidation, zipCodeValidation, emailValidation} from '../../../validators';
import {formatPhone} from '../../../helpers';
import {withStyles, createStyles} from '@material-ui/core/styles';


export const MyAccountFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const MyAccountFormActions = ({basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <ShowButton basePath={basePath} record={data} />
    </CardActions>
);

const styles = () => createStyles({
    errorTabButton: { color: "#f44336 !important" },
});

class MyAccountTeacherForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        if (values.HomePhone) {
            values.HomePhone = values.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.WorkPhone) {
            values.WorkPhone = values.WorkPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        values.id = this.props.id;

        const {Address, ...AccountValues} = values;

        // eslint-disable-next-line
        for(let prop in AccountValues) {
            if(AccountValues.hasOwnProperty(prop) && (AccountValues[prop] === null)){
                delete AccountValues[prop];
            }
        }

        // eslint-disable-next-line
        for(let prop in Address) {
            if(Address.hasOwnProperty(prop) && (Address[prop] === null)){
                delete Address[prop];
            }
        }

        fetchIt(
            UPDATE,
            'teacher_account',
            AccountValues
        ).then(response => {
            switch (response.status) {
                case 200:
                    Address.id = this.props.id;

                    fetchIt(
                        UPDATE,
                        'home_address',
                        Address
                    ).then(addressResponse => {
                        switch (addressResponse.status) {
                            case 200:
                                let message = 'My Account Data successfuly updated.';
                                if (addressResponse.data.Message) {
                                    message = addressResponse.data.Message;
                                }

                                showNotification(
                                    'direct_message',
                                    'info',
                                    {messageArgs: {_: message}}
                                );

                                if (me._isMounted) {
                                    me.setState({
                                        redirectTo: me.props.basePath + '/' + this.props.id + '/show'
                                    });
                                }

                                break;

                            case 400:
                                let errorMessage = 'The form is NOT valid. Please check for errors.';
                                if (typeof response.data.HasValidationErrors !== 'undefined') {
                                    errorMessage = getErrorMessage(response.data);
                                }

                                showNotification(
                                    'direct_message',
                                    'warning',
                                    {messageArgs: {_: errorMessage}}
                                );

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    {messageArgs: {_: 'Oops, something went wrong!'}}
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();

                        if (me._isMounted) {
                            me.setState({
                                isDisabled: false
                            });
                        }
                    });

                    break;

                case 400:
                    values.HomePhone = formatPhone(values.HomePhone);
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.WorkPhone = formatPhone(values.WorkPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    // Dispatch an action letting react-admin know an API call has ended
                    fetchEnd();

                    if (me._isMounted) {
                        me.setState({
                            isDisabled: false
                        });
                    }

                    break;

                default:
                    values.HomePhone = formatPhone(values.HomePhone);
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.WorkPhone = formatPhone(values.WorkPhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
                    // Dispatch an action letting react-admin know an API call has ended
                    fetchEnd();

                    if (me._isMounted) {
                        me.setState({
                            isDisabled: false
                        });
                    }
            }
        }).catch(error => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();

            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Card className="cardForm">
                <CardContent>
                    <MyAccountFormActions basePath={props.basePath} data={this.props.record} />
                    <TabbedForm
                        resource={this.props.resource}
                        record={this.props.record}
                        save={this.handleSubmit}
                        toolbar={<MyAccountFormToolbar isDisabled={this.state.isDisabled} />}
                        defaultValue={{id: this.props.record.id}}
                        classes={{ errorTabButton: this.props.classes.errorTabButton }}
                    >
                        <FormTab label="Account Data">
                            <TextInput source="DisplayName" validate={required('The Display Name field is required')} />
                            <TextInput source="FirstName" validate={required('The First Name field is required')} />
                            <TextInput source="LastName" validate={required('The Last Name field is required')} />
                            <TextInput type='email' source='Email' validate={emailValidation} />
                            <TextInput type='email' source='EmailAlternate' validate={emailValidation} />
                            <PhoneMaskedInput
                                required
                                source='HomePhone'
                                validate={phoneValidation}
                            />
                            <PhoneMaskedInput
                                required
                                source='CellPhone'
                                validate={phoneValidation}
                            />
                            <PhoneMaskedInput
                                required
                                source='WorkPhone'
                                validate={phoneValidation}
                            />
                        </FormTab>
                        <FormTab label="Home Address">
                            <TextInput
                                source='Address.City'
                                label='City'
                                validate={required('The City field is required')}
                            />
                            <UsStates
                                source='Address.USState'
                                label='State'
                                validate={required('The State field is required')}
                            />
                            <ZipCodeMaskedInput
                                required
                                source='Address.ZIP'
                                label='ZIP Code'
                                validate={zipCodeValidation}
                            />
                            <TextInput
                                source='Address.StreetAddress'
                                label='Street Address'
                                validate={required('The Street Address field is required')}
                            />
                            <TextInput
                                source='Address.StreetAddressLine2'
                                label='Street Address Line 2'
                                // validate={required('The Street Address field is required')}
                            />
                        </FormTab>
                    </TabbedForm>
                </CardContent>
             </Card>
        );
    }
}
export default withStyles(styles)(MyAccountTeacherForm);
