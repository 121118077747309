import React from 'react';
import {
    Filter,
    TextInput
} from 'react-admin';
import BooleanFilterInput from "../../../../components/BooleanFilterInput";
import {choices} from "../../../../config/constants";


export const CourseCategoriesListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Course Category Name' source='search' alwaysOn style={{width: 300}}/>
        <BooleanFilterInput source='IsActive' label='Status' choices={choices} defaultValue={true} />
    </Filter>
);
