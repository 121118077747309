import {connect} from 'react-redux';
import ChangeNotificationsStatusButton from '../components/ChangeNotificationsStatusButton';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView
};

export default connect(null, mapDispatchToProps)(
    ChangeNotificationsStatusButton
);