import React, {useState, useEffect} from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";


export const SchedulesListFilters = props => {
    const [schoolYear, setSchoolYear] = useState('');
    const [semester, setSemester] = useState('');


    useEffect(() => {
        setSemester(props.currentSemester);
    }, [props.currentSemester]); // eslint-disable-line


    const onSchoolYearChange = event => {
        event.persist();

        setSchoolYear(event.target.value);

        props.fetchSemesters({"SchoolYear": parseInt(event.target.value)});
    };


    const onSemesterChange = event => {
        event.persist();

        setSemester(event.target.value);

        let filters = {};
        if(parseInt(event.target.value)){
            filters.SemesterId = parseInt(event.target.value);
        }

        if(parseInt(schoolYear)){
            filters.SchoolYear = parseInt(schoolYear);
        }

        props.fetchTheData(filters);
    };


    const currentSchoolYear = moment().format('YYYY');


    if(!parseInt(schoolYear)){
        setSchoolYear(currentSchoolYear);
    }


    return(
        <div style={{margin: '5px 10px 15px 0px', display: 'inline-block'}}>
            <div style={{float: 'right'}}>
                <InputLabel id="semester">Semester</InputLabel>
                <Select
                    label='Semesters'
                    onChange={onSemesterChange}
                    value={semester}
                    style={{width: 300}}
                >
                    {
                        props.semesters.map(
                            semesterObject => (
                                <MenuItem key={semesterObject.id} value={semesterObject.id}>
                                    {semesterObject.name}
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </div>
            <div style={{float: 'right', marginRight: 50}}>
                <InputLabel id="School-Years">School Years</InputLabel>
                <Select
                    label='School Years'
                    onChange={onSchoolYearChange}
                    value={schoolYear}
                    style={{width: 180}}
                >
                    {
                        props.schoolYears.map(
                            schoolYear => (
                                <MenuItem key={schoolYear.id} value={schoolYear.id}>
                                    {schoolYear.name}
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </div>
        </div>
    )
};
