import {connect} from 'react-redux';
import SendEmailButton from '../components/SendEmailButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    SendEmailButton
);