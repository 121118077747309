import React, {Fragment} from 'react';
import {
    TextField,
    FunctionField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../../common';


export const FeesShow = props => {
    return (
        <Fragment>
            <DetailsLayout {...props}>
                <DetailsTileWrapper
                    sideTitle='Fee Details'
                    sideText='Add your data here.'
                >
                    <TextField source='Name' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                    <TextField source='FeeType' />
                    <TextField source='Description' />
                    <FunctionField source='Amount' render={record => ('$ ' + record.Amount)} />
                    <TextField source='CreatedBy' />
                    <FunctionField source='FromDate' render={record => record.FromDate ? record.FromDate : 'Not specified'} />                
                    <FunctionField source='ToDate' render={record => record.ToDate ? record.ToDate : 'Not specified'} />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};