import {connect} from 'react-redux';
import UserSplitButton from '../components/UserSplitButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    UserSplitButton
);