import React from 'react';
import {
    Filter,
    TextInput,
    ReferenceInput
} from 'react-admin';
import BooleanFilterInput from "../../../../components/BooleanFilterInput";
import {choices, classTypeChoices} from "../../../../config/constants";
import SelectInput from '../../../../components/SelectInput';


export const CoursesListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Course Name' source='search' alwaysOn style={{width: 300}}/>
        <ReferenceInput 
            label='Categories'
            source='Categories'
            reference='course_categories'
            allowEmpty
            emptyValue={null}
            emptyText="All"
        >
            <SelectInput optionText="Name"  />
        </ReferenceInput>
        <ReferenceInput 
            label='Course Level'
            source='CourseLevel'
            reference='course_levels'
            allowEmpty
            emptyValue={null}
            emptyText="All"
        >
            <SelectInput optionText='Name' />
        </ReferenceInput>
        <SelectInput 
            source='ClassType'
            label='Class Type'
            choices={classTypeChoices}
            optionText='name'
            allowEmpty
            emptyValue={null}
            emptyText="All"
        />
        <ReferenceInput 
            label='Age Interval'
            source='AgeInterval'
            reference='age_intervals'
            allowEmpty
            emptyValue={null}
            emptyText="All"
        >
            <SelectInput optionText='Name' />
        </ReferenceInput>
        <BooleanFilterInput source='IsActive' label='Status' choices={choices} defaultValue={true} />
    </Filter>
);