import React from 'react';
import {
    SimpleShowLayout,
    RichTextField
} from 'react-admin';


export const CourseCategoriesListDescriptionShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField source="Description" />
    </SimpleShowLayout>
);
