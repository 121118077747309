import React from 'react';
import {
    TextField,
    EmailField,
    SelectField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';
import {formatPhone} from "../../../helpers";


export const MyAccountShow = props => {
    let fieldsToFormat = [
        {
            field: 'HomePhone',
            formatter: formatPhone
        },
        {
            field: 'CellPhone',
            formatter: formatPhone
        },
    ];

    let options = {
        detailsTitle: 'account',
        removeListButton: true,
        removeDeleteButton: true,
        indexToFetch: 'home_address',
        indexToFetchName: 'Address'
    };

    const isTeacher = localStorage.getItem('account') ? (JSON.parse(localStorage.getItem('account')).Role === 'Teacher') : false;
    if (isTeacher) {
        fieldsToFormat.push({
            field: 'WorkPhone',
            formatter: formatPhone
        });

        options = {
            resource: 'teacher_account',
            detailsTitle: 'account',
            removeListButton: true,
            removeDeleteButton: true,
            indexToFetch: 'home_address',
            indexToFetchName: 'Address'
        };
    }


    return (
        <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
            {
                isTeacher ?
                    <DetailsTileWrapper
                        sideTitle='Account Details'
                        sideText=''
                    >
                        <TextField source='DisplayName' />
                        <TextField source='FirstName' />
                        <TextField source='LastName' />
                        <EmailField source='Email' />
                        <EmailField source='EmailAlternate' />
                        <TextField source='HomePhone' />
                        <TextField source='CellPhone' />
                        <TextField source='WorkPhone' />
                    </DetailsTileWrapper>
                :
                    <DetailsTileWrapper
                        sideTitle='Account Details'
                        sideText=''
                    >
                        <TextField source='FirstName' />
                        <TextField source='LastName' />
                        <EmailField source='Email' />
                        <TextField source='BirthDate' />
                        <TextField source='HomePhone' />
                        <TextField source='CellPhone' />
                        <SelectField
                            source='Gender'
                            choices={[
                                {id: 'Male', name: 'Male'},
                                {id: 'Female', name: 'Female'},
                            ]}
                        />
                        <TextField source='Role' />
                    </DetailsTileWrapper>
            }

            <DetailsTileWrapper
                sideTitle='Home Address'
                sideText=''
                recordObject='home_address'
            >
                <TextField source='home_address.City' label='City' />
                <TextField source='home_address.USState' label='State' />
                <TextField source='home_address.ZIP' label='Zip' />
                <TextField source='home_address.StreetAddress' label='Street Address' />
                {
                    isTeacher ?
                        <TextField source='home_address.StreetAddressLine2' label='Street Address Line 2' />
                    :
                        null
                }
            </DetailsTileWrapper>
        </DetailsLayout>
    );
};
