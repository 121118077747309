import { connect } from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    userLogin
} from 'react-admin';
import RegisterForm from '../components/RegisterForm';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    userLogin
};

export default connect(null, mapDispatchToProps)(
    RegisterForm
);