import React, { useCallback } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { SaveButton } from 'react-admin';

const RegisterButton = props => {
    const {isDisabled, ...otherProps} = props;

    const formState = useFormState();
    const form = useForm();
    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }

        form.submit();
    }, [
        form,
        formState.valid,
    ]);

    return (
        <SaveButton
            label="Register"
            {...otherProps}
            handleSubmitWithRedirect={handleClick}
            disabled={isDisabled}
            style={{marginTop: 15}}
        />
    );
};

export default RegisterButton;
