import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    downloadCSV
} from 'react-admin';
import {CourseLevelsListFilters} from '../';
import jsonExport from 'jsonexport/dist';
import {inArray} from "../../../../helpers";
import SplitButton from '../../../../common/containers/SplitButton';


export const exporter = records => {
    const headers = ['Id', 'Name', 'Description', 'Status'];

    const data = records.map(record => {
        let recordData = {};
        // eslint-disable-next-line
        for (const prop in record) {
            if (record.hasOwnProperty(prop) && inArray(prop, headers)) {
                recordData[prop] = record[prop];
            }
        }

        return ({
            Id: record.Id !== null ? record.Id : '',
            Name: record.Name !== null ? record.Name : '',
            Description: record.DescriptionTagless ? record.DescriptionTagless : record.Description,
            Status: record.IsActive ? 'Active' : 'Inactive',
        });
    });

    jsonExport(data, {headers}, (err, csv) => {
        downloadCSV(csv, 'CourseLevelsList');
    });
};


// export const CourseLevelsButtons = props => (
//     <DeleteButton
//         {...props}
//         bulkSuccessMessage='Levels deleted'
//         bulkErrorMessage='Levels NOT deleted'
//         confirmTitle='Delete selected levels'
//         confirmContent='Are you sure you want to delete the selected levels?'
//     />
// );


export const CourseLevelsList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Course Levels List</h1>
        <List {...props} filters={<CourseLevelsListFilters />} title=' ' exporter={exporter} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="Name" label="Level"/>
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonTd'
                    successMessage='Level deleted'
                    errorMessage='Level NOT deleted'
                    confirmTitle='Delete the level'
                    confirmContent='Are you sure you want to delete the level?'
                />
            </Datagrid>
        </List>
    </div>
);
