import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';

const exporter = records => {
    const data = records.map(record => {
        let newRecord = {};

        // eslint-disable-next-line
        for(let field in record) {
            const object = record[field];

            if(record.hasOwnProperty(field)){
                if(typeof object === "object"){
                    let text = [];

                    // eslint-disable-next-line
                    for(let prop in object) {
                        if(object.hasOwnProperty(prop)){
                            
                            text.push(prop+": " + object[prop]);
                        }
                    }

                    newRecord[field] = text.join(", ");
                } else {
                    if(field !== "id") {
                        newRecord[field] = object;
                    }
                }
            }
        }        

        return newRecord;
    });


    jsonExport(data, {}, (err, csv) => {
        downloadCSV(csv, 'Export');
    });
};

export default exporter;