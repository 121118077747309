import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    BooleanInput,
    FormDataConsumer,
} from 'react-admin';
import {NtaSaveButton} from '../../../common';
import {fetchIt} from "../../../../helpers/fetcher";
import {getErrorMessage} from "../../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import MonthsToYearsInput from '../../../../components/MonthsToYearsInput';


export const AgeIntervalFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

export const AgeIntervalFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = (props.title ? props.title : '');
    }

    return '';
};

class AgeIntervalForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let data = Object.assign({}, values);

        let fromAge = 0;
        if (values.FromAge && values.FromAge.Years) {
            fromAge = values.FromAge.Years * 12;
        }
        if (values.FromAge && values.FromAge.TheHalf) {
            fromAge += 6;
        }
        data.FromAge = fromAge;

        let toAge = 0;
        if (values.ToAge && values.ToAge.Years) {
            toAge = values.ToAge.Years * 12;
        }
        if (values.ToAge && values.ToAge.TheHalf) {
            toAge += 6;
        }
        data.ToAge = toAge;

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            data.id = this.props.id;
        }

        fetchIt(
            requestType,
            'age_intervals',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'AgeInterval saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <SimpleForm
                            resource={this.props.resource}
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<AgeIntervalFormToolbar isDisabled={this.state.isDisabled} />}
                        >
                            <TextInput source="Name" className='fieldsWidthExpand' validate={required('The Age Interval Name field is required')} />
                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return <AgeIntervalFormTitle title={formData.Name} />;
                                    }
                                }
                            </FormDataConsumer>
                            <MonthsToYearsInput source='FromAge' />
                            <MonthsToYearsInput source='ToAge' />
                            <BooleanInput source='IsActive' style={{paddingTop: 20}} />
                        </SimpleForm>
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}
export default AgeIntervalForm;
