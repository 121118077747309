import React, { Component } from 'react';
import { RegisterForm } from '../';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Notification from '../../layout/Notification';

const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    registerPaper: {
        padding: "25px 0"
    }
});


class Register extends Component {
    render() {
        const { classes, ...props } = this.props;

        return (
            <div className={classes.main}>
                <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4} >
                        <Grid container>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid item xs={12} md={9} >
                                <Paper className={classes.registerPaper}>
                                    <Typography component="h1" variant="h5" align="center">Registration</Typography>
                                    <RegisterForm {...props} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Notification />
            </div>
        );
    }
}


Register.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Register);
