import React, {Component, Fragment} from 'react';
import moment from 'moment';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import {withResizeDetector} from 'react-resize-detector';
import {styles} from './styles';
import {inArray, getErrorMessage} from './helpers';
import TimeSlot from './TimeSlot'
import DroppableTable from './DroppableTable'
import {itemTypes} from './config/constants'


class NeteaSchedulerComponent extends Component {
    _isMounted = false;
    _layoutWidth = 0;
    _oldWindowScroll = null;
    _slotDuration = null;

    constructor(props) {
        super(props);

        this.neteaSchedulerNode = React.createRef();
        this.scrollingCell = React.createRef();
        this.stickyHeadRow = React.createRef();
        this.scrollingCellTbody = React.createRef();

        if (this.props.slotDuration) {
            if (inArray(this.props.slotDuration, [5, 10, 15, 30, 60])) {
                this._slotDuration = this.props.slotDuration;
            }
        }

        let resources = [];
        if (this.props.resources) {
            resources = this.props.resources;
        }

        let weekdays = [];
        for (let t = 1; t < 8; t++) {
            weekdays.push(moment(t, 'E').format('dddd'));
        }

        let schedules = [];
        if (this.props.schedules) {
            schedules = this.props.schedules;
        }

        this.state = {
            resources,
            weekdays,
            schedules: schedules,
            rowsHeight: {},
            refresh: [],
            refreshRows: [],
            refreshSchedules: [],
            isLoading: true,
            showDialog: false,
            // tooltip: false,
            // schedule: {},
            // tooltipPosition: {
            //     top: -500,
            //     left: -1000
            // },
            // tooltipWidth: 0,
            // tooltipHeight: 0,
            width: 100,
            scrollerPosition: 0,
            scrollerWidth: 0,
            scrollingCellTbodyMarginTop: 0
        };
    }


    updateDimensions = () => {
        if (this._isMounted) {
            let newState = {
                ...this.state,
                width: this.neteaSchedulerNode.current.parentNode.offsetWidth - 35,
                scrollerPosition: this.scrollingCell.current ? this.scrollingCell.current.getBoundingClientRect().x : 0
            };
            if (this.state.scrollerWidth === 0) {
                newState.scrollerWidth = this.scrollingCell.current ? this.scrollingCell.current.offsetWidth : 0;
            }

            this.setState(newState);
        }
    };


    moveSchedule = (id, hour, minutes, oldWeekday, newWeekday, oldStudio, newStudio) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        let schedules = Object.assign({}, this.state.schedules);

        const scheduleIndex = schedules[oldWeekday][oldStudio].findIndex(schedule => schedule && (schedule.Id === id));

        fetchStart();

        if (scheduleIndex > -1) {
            let oldSchedule = Object.assign({}, schedules[oldWeekday][oldStudio][scheduleIndex]);

            oldSchedule.StartHour = hour;
            oldSchedule.StartMinutes = minutes;
            oldSchedule.DayOfWeek = newWeekday;
            oldSchedule.Studio = newStudio;

            let start = moment.utc(oldSchedule.StartHour, "H").add(oldSchedule.StartMinutes, 'minutes');
            let end = moment.utc(start.format('H:mm'), 'H:mm').add(oldSchedule.Duration, 'minutes');
            oldSchedule.top = 0;
            oldSchedule.range = moment.range(start, end);


            if (this.props.moveSchedule) {
                const data = {
                    id,
                    Studio: newStudio,
                    DayOfWeek: newWeekday,
                    StartTime: moment.utc(hour + ':' + minutes, 'H:m').format('HH:mm:ss'),
                    Duration: schedules[oldWeekday][oldStudio][scheduleIndex].Duration
                }

                this.props.moveSchedule(data).then(response => {
                    switch (response.status) {
                        case 200:
                            schedules[oldWeekday][oldStudio].splice(scheduleIndex, 1);
                            schedules[newWeekday][newStudio].push(oldSchedule);

                            schedules = this.recalculateRowsHeight(schedules, oldWeekday, oldStudio);
                            schedules = this.recalculateRowsHeight(schedules, newWeekday, newStudio);

                            if (this._isMounted) {
                                this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                            }

                            showNotification(
                                'direct_message',
                                'info',
                                {messageArgs: {_: response.data.Message}}
                            );

                            break;

                        case 400:
                        case 403:
                            let errorMessage = 'Oops, something went wrong!';
                            if (typeof response.data.HasValidationErrors !== 'undefined') {
                                errorMessage = getErrorMessage(response.data);
                            }

                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: errorMessage}}
                            );

                            if (this._isMounted) {
                                this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                            }

                            break;

                        default:
                            showNotification(
                                'direct_message',
                                'warning',
                                { messageArgs: { _: 'Oops, something went wrong!' } }
                            );
                    }
                }).catch(error => {
                    // console.log(error);

                    let errorMessage = 'Oops, something went wrong!';
                    if (
                        (typeof error.response !== 'undefined') &&
                        (typeof error.response.data !== 'undefined') &&
                        (typeof error.response.data.HasValidationErrors !== 'undefined')
                    ) {
                        errorMessage = getErrorMessage(error.response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    if (this._isMounted) {
                        this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                    }
                }).then(() => {
                    // Dispatch an action letting react-admin know an API call has ended
                    fetchEnd();
                });
            } else {
                fetchEnd();
            }
        } else {
            fetchEnd();
        }
    }


    recalculateRowsHeight = (schedules, weekday, studio) => {
        let rowsHeight = this.state.rowsHeight;

        delete rowsHeight[weekday + '_' + studio];

        let allSchedules = schedules[weekday][studio];

        const timeSlots = this.getTimeSlots();
        const timeSlotsCount = timeSlots.length;
        let targetSlots = 1;
        let slotDuration = 0;
        if (this._slotDuration) {
            slotDuration = this._slotDuration;
            targetSlots = 60 / slotDuration;
        }

        for (let outer = 0; outer < timeSlotsCount; outer++) {
            for (let inner = 0; inner < targetSlots; inner++) {
                const startMinutes = (60 / targetSlots) * inner;
                const startHour = parseInt(moment(timeSlots[outer], "h a").format("H"));

                let filteredSchedules = [];
                if (typeof allSchedules !== 'undefined') {
                    filteredSchedules = allSchedules.filter(
                        schedule => schedule.StartHour === startHour &&
                            schedule.StartMinutes >= startMinutes &&
                            schedule.StartMinutes < (startMinutes + (60 / targetSlots)));
                }

                let myHeight = 0;
                for (let schedule of filteredSchedules) {

                    let prevOverlaps = allSchedules.filter(theSchedule => {
                        const scheduleStart = moment.utc(schedule.StartHour + ':' + schedule.StartMinutes, 'H:m');
                        const theScheduleStart = moment.utc(theSchedule.StartHour + ':' + theSchedule.StartMinutes, 'H:m');

                        return (theScheduleStart.isBefore(scheduleStart) && schedule.range.overlaps(theSchedule.range, {adjacent: false}));
                    });

                    if (prevOverlaps.length > 0) {
                        prevOverlaps.sort((a, b) => (a.top < b.top ? 1 : -1));

                        myHeight = prevOverlaps[0].top + (prevOverlaps[0].BelongsToAnotherSemester ? 61 : 121);
                    }

                    const top = myHeight;
                    myHeight += (schedule.BelongsToAnotherSemester ? 61 : 121);

                    const scheduleIndex = schedules[weekday][studio].findIndex(theSchedule => theSchedule.Id === schedule.Id);
                    schedules[weekday][studio][scheduleIndex].top = top;

                    if (
                        (myHeight > 120) &&
                        (!rowsHeight[weekday + '_' + studio] ||
                            rowsHeight[weekday + '_' + studio] < myHeight)
                    ) {
                        rowsHeight[weekday + '_' + studio] = myHeight;
                    }

                }
            }
        }

        if (this._isMounted) {
            this.setState({rowsHeight});
        }

        return schedules;
    }


    stopRefreshing = refresh => {
        if (this._isMounted) {
            this.setState({refresh});
        }
    }


    componentDidMount() {
        this._isMounted = true;

        let schedules = [];
        if (this.state.schedules) {
            schedules = this.state.schedules;

            for (let weekday in schedules) {
                for (let studio in schedules[weekday]) {
                    this.recalculateRowsHeight(schedules, weekday, studio);
                }
            }
        }

        this.updateDimensions();

        window.addEventListener('scroll', this.onWindowScroll);
    }


    onWindowScroll = e => {
        if (this._oldWindowScroll === null) {
            this._oldWindowScroll = parseInt(e.currentTarget.scrollY);
        }

        let scrollingCellTbodyMarginTop = 0;

        if (this._oldWindowScroll > parseInt(e.currentTarget.scrollY)) {
            if (parseInt(e.currentTarget.scrollY) > 52) {
                this.stickyHeadRow.current.className = this.props.classes.stickyHeadRowStickedAndTranslated;
                scrollingCellTbodyMarginTop = 48;
            } else {
                this.stickyHeadRow.current.className = this.props.classes.stickyHeadRow;
                scrollingCellTbodyMarginTop = 0;
            }

            if (this._isMounted) {
                this.setState({scrollingCellTbodyMarginTop: 0})
            }
        } else if (parseInt(e.currentTarget.scrollY) > 72) {
            this.stickyHeadRow.current.className = this.props.classes.stickyHeadRowSticked;

            scrollingCellTbodyMarginTop = 48;
        }

        this.stickyHeadRow.current.scrollLeft = this.scrollingCell.current.scrollLeft;
        this._oldWindowScroll = parseInt(e.currentTarget.scrollY);

        if (this._isMounted) {
            this.setState({scrollingCellTbodyMarginTop})
        }
    };


    componentWillUnmount() {
        this._isMounted = false;

        window.removeEventListener('scroll', this.onWindowScroll);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.width && (nextProps.width !== this._layoutWidth)) {
            this._layoutWidth = nextProps.width;
            this.updateDimensions();
        }

        if (nextProps.refresh) {
            let refreshRows = [];
            let refreshSchedules = [];

            if (nextProps.refreshRows) {
                refreshRows = nextProps.refreshRows;

                if (nextProps.refreshSchedules) {
                    refreshSchedules = nextProps.refreshSchedules;
                }
            }

            this.setState({refresh: nextProps.refresh, refreshRows, refreshSchedules});
        }
    }

    onScroll = e => {
        e.persist();

        this.scrollingCell.current.scrollLeft = e.target.scrollLeft;
        this.stickyHeadRow.current.scrollLeft = e.target.scrollLeft;
    }


    getTimeSlots = () => {
        let slotTime = moment(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
        let endTime = moment(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
        endTime = this.props.maxTime ? moment(this.props.maxTime, 'HH:mm') : endTime.add(1, 'days').subtract(1, 'seconds');

        let timeSlots = [];
        while (slotTime < endTime) {
            timeSlots.push(slotTime.format("h a"));
            slotTime = slotTime.add(60, 'minutes');
        }

        return timeSlots;
    }


    timeSubSlotsRender = (weekday, resource) => {
        const timeSlots = this.getTimeSlots();
        const timeSlotsCount = timeSlots.length;
        let targetSlots = 1;
        let slotDuration = 0;
        if (this._slotDuration) {
            slotDuration = this._slotDuration;
            targetSlots = 60 / slotDuration;
        }

        let timeSubSlots = [];
        for (let outer = 0; outer < timeSlotsCount; outer++) {
            for (let inner = 0; inner < targetSlots; inner++) {
                let borderStyle = {
                    borderStyle: 'dotted',
                    borderColor: '#DBDBDB',
                    borderWidth: '0px 0px 2px 1px',
                    width:  180 / targetSlots,
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#969696',
                };
                if (inner === 0) {
                    borderStyle = {
                        borderStyle: 'solid',
                        borderColor: '#969696',
                        borderWidth: '0px 0px 2px 2px',
                        padding: 0,
                        width:  180 / targetSlots,
                    };
                }

                const startMinutes = (60 / parseInt(targetSlots)) * parseInt(inner);
                const startHour = parseInt(moment(timeSlots[outer], "h a").format("H"));

                const hasSchedules = (
                    this.state &&
                    this.state.schedules &&
                    this.state.schedules[weekday] &&
                    (typeof this.state.schedules[weekday][resource.id] !== 'undefined')
                );

                let filteredSchedules = [];
                if (hasSchedules) {
                    filteredSchedules = this.state.schedules[weekday][resource.id].filter(
                        schedule => schedule.StartHour === startHour &&
                            schedule.StartMinutes >= startMinutes &&
                            schedule.StartMinutes < (startMinutes + (60 / targetSlots)));
                }

                const timeSubSlot = (
                    <TimeSlot
                        key={'dts_' + outer + '_' + inner}
                        id={'ts_' + weekday + '_' + resource.id + '_' + outer + '_' + inner}
                        weekday={weekday}
                        resource={resource}
                        timeSlot={timeSlots[outer]}
                        targetSlots={targetSlots}
                        schedules={filteredSchedules}
                        refreshSchedules={this.state.refreshSchedules}
                        timeSlotClick={this.timeSlotClick}
                        inner={inner}
                        accept={itemTypes.SCHEDULE}
                        borderStyle={borderStyle}
                        className={this.props.classes.contentTimeslot}
                    />
                );

                timeSubSlots.push(timeSubSlot);
            }
        }

        return timeSubSlots;
    }


    resourceRender = resource => {
        let resourceColor = resource.color;
        if (resource.color === '#FAFAFA') {
            resourceColor = '#666666'
        }

        return (
            <div style={{
                display: 'table-cell',
                verticalAlign: 'middle'
            }}>
                <div style={{
                    borderLeft: '3px solid ' + resourceColor,
                    paddingLeft: 10
                }}>
                    {resource.title}
                </div>
            </div>
        );
    };


    timeSlotClick = event => {
        event.preventDefault();

        event.persist();

        const dataSet = event.target.dataset;

        const baseTime = moment(dataSet.timeSlot, "h a");
        const targetSlots = parseInt(dataSet.targetSlots);
        const timeSubslot = parseInt(dataSet.timeSubslot);
        const slotDuration = 60 / targetSlots;
        const addMinutesToBase = timeSubslot * slotDuration;
        const newTime = baseTime.add(addMinutesToBase, 'minutes');

        if (this.props.clickOnTimeSlot) {
            if (dataSet.getParent) {
                let scheduleDomObject = event.target;
                if (scheduleDomObject.dataset.getParent && !scheduleDomObject.dataset.schedule) {
                    while (scheduleDomObject.dataset.getParent && !scheduleDomObject.dataset.schedule) {
                        scheduleDomObject = scheduleDomObject.parentElement;
                    }
                }

                const schedule = this.state.schedules[scheduleDomObject.dataset.weekday][parseInt(scheduleDomObject.dataset.resource)].find(
                    theSchedule => parseInt(theSchedule.id) === parseInt(scheduleDomObject.dataset.schedule)
                );

                if (schedule && !schedule.BelongsToAnotherSemester) {
                    dataSet.Duration = schedule.Duration;
                    dataSet.Teachers = JSON.stringify(schedule.Teachers ? schedule.Teachers : []);

                    this.props.clickOnTimeSlot(event, newTime, false, dataSet);
                }
            } else {
                this.props.clickOnTimeSlot(event, newTime, true, dataSet);
            }

        }
    };


    render() {
        const {showNotification} = this.props;
        let layoutColor = 'transparent';
        if (this.props.layoutColor) {
            layoutColor = this.props.layoutColor;
        }

        return (
            <div className={this.props.classes.neteaScheduler} ref={this.neteaSchedulerNode} style={{width: this.state.width}}>
                <table className={this.props.classes.mainTable}>
                    <tbody>
                    <tr>
                        <td style={{width: 170, padding: 0}}>
                            <table className={this.props.classes.mainTable}>
                                <tbody>
                                <tr className={this.props.classes.headRow}>
                                    <td className={this.props.classes.headCell} style={{width: 170}}>
                                        <div className={this.props.classes.timeslot} style={{width: 170, borderLeft: 'none'}}> </div>
                                    </td>
                                </tr>
                                {
                                    this.state.weekdays.map((weekday, key) => (
                                        <Fragment key={'wd_' + key}>
                                            <tr className={this.props.classes.weekdayRow}>
                                                <td style={{padding: 0}}>
                                                    <div className={this.props.classes.timeslot} style={{width: 170, borderLeft: 'none', textAlign: 'left', paddingLeft: 10}}>
                                                        {weekday}
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                this.state.resources.map((resource, resKey) => (
                                                    <tr
                                                        className={this.props.classes.resourceRow}
                                                        key={'res_' + resKey}
                                                        style={{minHeight: (this.state.rowsHeight[weekday + '_' + resource.id] ? this.state.rowsHeight[weekday + '_' + resource.id] : 120)}}
                                                    >
                                                        <td style={{padding: 0}}>
                                                            <div
                                                                className={this.props.classes.contentResource}
                                                                style={resKey === this.state.resources.length - 1 ? {borderBottom: '2px solid #969696'} : {}}
                                                            >
                                                                {this.resourceRender(resource)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </Fragment>
                                    ))
                                }
                                </tbody>
                            </table>
                        </td>
                        <td className={this.props.classes.scrollingCell} ref={this.scrollingCell} style={{width: this.state.width - 160}}>
                            <table className={this.props.classes.mainTable}>
                                <thead>
                                <tr
                                    className={this.props.classes.stickyHeadRow}
                                    ref={this.stickyHeadRow}
                                    style={{width: this.state.width - 171, overflow: 'hidden', top: 0, backgroundColor: layoutColor}}
                                >
                                    <td className={this.props.classes.headCell} style={{display: 'flex', width: 'fit-content'}}>
                                        {
                                            this.getTimeSlots().map((timeSlot, key)=> (
                                                <div className={this.props.classes.timeslot} key={key}>
                                                    {timeSlot}
                                                </div>
                                            ))
                                        }
                                    </td>
                                </tr>
                                </thead>
                                <tbody id='timeSlotsBody'>
                                {
                                    this.state.weekdays.map((weekday, key) => (
                                        <Fragment key={'wdcont_' + key}>
                                            <tr
                                                className={this.props.classes.weekdayRow}
                                                ref={key === 0 ? this.scrollingCellTbody : null}
                                                style={key === 0 ? {marginTop: this.state.scrollingCellTbodyMarginTop} : {}}>
                                                <td style={{display: 'flex', padding: 0}}>
                                                    {
                                                        this.getTimeSlots().map((timeSlot, key)=> (
                                                            <div className={this.props.classes.timeslot} key={key}>

                                                            </div>
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: 0}}>
                                                    {
                                                        <DroppableTable
                                                            id={weekday}
                                                            accept={itemTypes.SCHEDULE}
                                                            resources={this.state.resources}
                                                            timeSubSlotsRender={this.timeSubSlotsRender}
                                                            weekday={weekday}
                                                            moveSchedule={this.moveSchedule}
                                                            stopRefreshing={this.stopRefreshing}
                                                            refresh={this.state.refresh}
                                                            refreshRows={this.state.refreshRows}
                                                            showNotification={showNotification}
                                                            rowsHeight={this.state.rowsHeight}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))
                                }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div
                    className={this.props.classes.scrollerWrapper}
                    onScroll={this.onScroll}
                    style={{
                        left: this.state.scrollerPosition,
                        width: this.state.width - 160
                    }}
                >
                    <div style={{width: this.state.scrollerWidth, overflowX: 'scroll', overflowY: 'hidden'}}> </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withResizeDetector,
    withStyles(styles)
)(NeteaSchedulerComponent);
