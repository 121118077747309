import React from "react";
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";


const StartDatePicker = props => {
    const renderLabel = date => {
        if (date && date.isValid()) {
            return date.format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const {
        label,
        source,
        resource,
        isRequired,
        meta,
    } = props;

    const {touched, error} = meta;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <DatePicker
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                margin="normal"
                format="MM/DD/YYYY"
                value={props.val}
                onChange={props.onChange}
                onFocus={props.onFocus ? props.onFocus : () => {}}
                // KeyboardButtonProps={{
                //     'aria-label': 'change date',
                // }}
                labelFunc={renderLabel}
                style={{marginTop: 10}}
                error={!!(touched && error)}
                helperText={touched && error}
                minDate={props.minDate}
                maxDate={props.maxDate}
            />
        </MuiPickersUtilsProvider>
    );
};

export default addField(StartDatePicker);
