import React, {Component, Fragment} from 'react';
import {GET_ONE} from 'react-admin';
import UserForm from '../containers/UserForm';
import {fetchIt} from "../../../../helpers/fetcher";
import {formatPhone} from "../../../../helpers";
import moment from "moment";
import {BackButton} from '../../../../common';
import {Redirect} from 'react-router';

export const UserTitle = () => {
    return <h1 id="the-title"> </h1>;
};

class UserEdit extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            userData: {},
            userAddressData: {},
            emergencyContactData: {},
            redirectTo: null
        };
    }

    fetchTenantUserInfo = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            GET_ONE,
            'tenant_users',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    response.data.CellPhone = formatPhone(response.data.CellPhone)
                    response.data.HomePhone = formatPhone(response.data.HomePhone)
                    response.data.BirthDate = response.data.BirthDate ? moment.utc(response.data.BirthDate, 'MM/DD/YYYY', true) : null;
                    
                    this.setState({
                        userData: response.data
                    })

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (this._isMounted) {
                        this.setState({
                            redirectTo: this.props.basePath
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

    }  

    componentDidMount() {
        this._isMounted = true;
        const {fetchStart, fetchEnd, showNotification} = this.props;


        this.fetchTenantUserInfo();
  
        // User Address Data
        fetchStart();

        fetchIt(
            GET_ONE,
            'tenant_users_address',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    this.setState({
                        userAddressData: response.data
                    })

                    break;

                case 404:
                    this.setState({
                        userAddressData: {}
                    });
                    
                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        // Emergency Contact Data
        fetchStart();

        fetchIt(
            GET_ONE,
            'users_emergency_contact',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    response.data.CellPhone = formatPhone(response.data.CellPhone)
                    response.data.BirthDate = response.data.BirthDate ? moment.utc(response.data.BirthDate, 'MM/DD/YYYY', true) : null;

                    this.setState({
                        emergencyContactData: response.data
                    });

                    break;
            
                case 404:
                    this.setState({
                        emergencyContactData: {}
                    });

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }
        
        return (
            <Fragment>
                <BackButton />
                <UserTitle record={this.state.record} />
                <UserForm 
                    userData={this.state.userData} 
                    userAddressData={this.state.userAddressData}
                    emergencyContactData={this.state.emergencyContactData} 
                    editMode={true}
                    fetchTenantUserInfo={this.fetchTenantUserInfo}
                    {...this.props}
                />
            </Fragment>
        );
    }
}

export default UserEdit;