import {GET_ONE, showNotification} from 'react-admin';
import {fetchIt, getPermissions} from "./helpers";
import {connect} from "react-redux";


export default {
    login: ({ username, password }) =>  {
        localStorage.removeItem('user');
        localStorage.removeItem('school');
        localStorage.removeItem('account');

        return fetchIt(
            'post',
            'login',
            {username, password}
        ).then(response => {
            switch (response.status) {
                case 200:
                    localStorage.setItem('user', JSON.stringify(response.data));

                    fetchIt(
                        GET_ONE,
                        'account',
                        {id: 1}
                    ).then(response => {
                        localStorage.setItem('account', JSON.stringify(response.data));

                        return Promise.resolve();
                    });

                    break;

                case 400:
                    throw new Error('The email and/or password are not correct.');

                default:
                    throw new Error('Oops, something went wrong!');
            }
        });
    },


    logout: () => {
        localStorage.removeItem('school');
        localStorage.removeItem('account');

        if(localStorage.getItem('user') !== null){
            const refreshToken = JSON.parse(window.localStorage.getItem('user')).refresh_token;
            localStorage.removeItem('user');

            return fetchIt(
                'post',
                'logout',
                {token: refreshToken}
            ).then(response => {
                return;
            }).catch(error => {
                return Promise.resolve();
            });
        }

        return Promise.resolve();
    },

    checkAuth: () => localStorage.getItem('user')
        ? Promise.resolve()
        : Promise.reject(),

    getPermissions: () => {
        return getPermissions();
    },

    checkError: error => {
        const status  = error.status;

        if (status === 403) {
            localStorage.removeItem('user');
            localStorage.removeItem('school');
            localStorage.removeItem('account');

            return Promise.reject();
        } else if (status === 400){
            return Promise.resolve();
        }
        return Promise.resolve();
    },
};

export const notify = connect(null, {showNotification})((message, type, notificationOptions) => {
    console.log(message, type, notificationOptions);
});
