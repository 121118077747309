export const notificationTypesChoices = [
    {id: '2', name: 'Dashboard'},
    {id: '1', name: 'E-mail'}
];

export const notificationTypesChoicesToList = [
    {id: 2, name: 'Dashboard'},
    {id: 1, name: 'E-mail'}
];

export {default as NotificationCreate} from './components/NotificationCreate';
export {default as NotificationCreateForm} from './containers/NotificationCreateForm';
export {NotificationsList} from './components/NotificationsList';
export {NotificationsListFilters} from './components/NotificationsListFilters';
export {NotificationsListMessageShow} from './components/NotificationsListMessageShow';
