import {connect} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import SchedulePricesInput from '../components/SchedulePricesInput';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    SchedulePricesInput
);
