import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    FormWithRedirect,
    Button,
    CREATE,
    GET_LIST,
} from 'react-admin';
import arrayMutators from 'final-form-arrays';
import {Typography} from "@material-ui/core";
import ArrowPrevIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowNextIcon from "@material-ui/icons/KeyboardArrowRight";
import ScheduleFormView from "../containers/ScheduleFormView";
import moment from "moment";
import {fetchIt} from "../../../helpers";



const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);


const StyledTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        // '&:focus': {
        //     color: '#40a9ff',
        // },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);


const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(2),
        borderBottom: '1px solid #e8e8e8',
    },
    scheduleForm: {
        backgroundColor: theme.palette.background.paper,
    },
}));


const ScheduleForm = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [form, setForm] = React.useState({});
    const [forbiddenStep, setForbiddenStep] = React.useState(1);
    const [errors, setErrors] = React.useState({});
    const [ssdErrors, setSsdErrors] = React.useState({});
    const [firstTabHasErrors, setFirstTabHasErrors] = React.useState(false);
    const [forceUpdate, setForceUpdate] = React.useState(false);


    const handleChange = (event, newValue) => {
        if ((value <= 2) && (newValue > 2)) {
            checkForOverlaps(newValue);
        } else {
            setValue(newValue);
        }
    };


    const allowStep = step => {
        setForbiddenStep(step + 1);
    };


    const disallowStep = step => {
        if (forbiddenStep > step) {
            setForbiddenStep(step);
        }
    };


    const onFormPrev = () => {
        setValue(value - 1);
    };


    const onFormNext = () => {
        if (value === 2) {
            checkForOverlaps(value + 1);
        } else {
            setValue(value + 1);
        }
    };


    const sanitizeErrors = () => {
        let newErrors = Object.assign({}, errors);
        for (let idx in errors) {
            if (newErrors[idx].Message.length > 0) {
                delete newErrors[idx];
            }
        }

        setErrors(newErrors);

        return newErrors;
    };


    const checkForOverlaps = nextStep => {
        const formState = form.getState();

        const {fetchStart, fetchEnd, showNotification} = props;

        fetchStart();

        sanitizeErrors();

        const scheduleStartDates = formState.values.StartDates.map(startDate => {
            return {
                Semester: startDate.Semester,
                StartDate: moment.utc(startDate.StartDate, 'MM/DD/YYYY h:mmA', true).format(),
                EndDate: moment.utc(startDate.EndDate, 'MM/DD/YYYY h:mmA', true).format()
            };
        });

        const scheduleDaysOfWeek = formState.values.ScheduleDaysOfWeek.map((dayOfWeek, index) => {
            return {
                Idx: index,
                Studio: dayOfWeek.Studio,
                DayOfWeek: dayOfWeek.DayOfWeek,
                StartTime: moment.utc(dayOfWeek.StartTime, 'h:mmA', true).format('HH:mm'),
                Duration: dayOfWeek.Duration,
                Teachers: dayOfWeek.Teachers,
            };
        });

        const data = {
            SemestersDates: scheduleStartDates,
            ScheduleDaysOfWeek: scheduleDaysOfWeek,
        };

        fetchIt(
            GET_LIST,
            'overlapping_validation',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (response && response.data) {
                        setValue(nextStep);
                    }

                    break;

                case 400:
                    let errorMessage = 'Overlapping schedules error';

                    if (response && response.data) {
                        let newErrors = sanitizeErrors();
                        for (let error of response.data) {
                            newErrors['sdw_' + error.Idx] = error;
                        }

                        setErrors(newErrors);
                        updateMe();
                    }

                    setValue(2);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    const handleSubmit = () => {
        const formState = form.getState();

        const {fetchStart, fetchEnd, showNotification} = props;

        fetchStart();

        const scheduleStartDates = formState.values.StartDates.map(startDate => {
            return {
                Semester: startDate.Semester,
                StartDate: moment.utc(startDate.StartDate, 'MM/DD/YYYY h:mmA', true).format(),
                EndDate: moment.utc(startDate.EndDate, 'MM/DD/YYYY h:mmA', true).format()
            };
        });

        const scheduleDaysOfWeek = formState.values.ScheduleDaysOfWeek.map((dayOfWeek, index) => {
            return {
                Idx: index,
                Studio: dayOfWeek.Studio,
                DayOfWeek: dayOfWeek.DayOfWeek,
                StartTime: moment.utc(dayOfWeek.StartTime, 'h:mmA', true).format('HH:mm'),
                Duration: dayOfWeek.Duration,
                Teachers: dayOfWeek.Teachers,
            };
        });

        const schedulePrices = formState.values.SchedulePrices.map(schedulePrice => {
            return {
                Semester: schedulePrice.Id,
                Price: schedulePrice.Price,
            };
        });

        const data = {
            Course: formState.values.Course,
            Places: formState.values.Places,
            SemestersDates: scheduleStartDates,
            ScheduleDaysOfWeek: scheduleDaysOfWeek,
            SchedulePrices: schedulePrices,
            Fees: formState.values.Fees,
        };

        fetchIt(
            CREATE,
            'schedules',
            data
        ).then(response => {
            let errorMessage = '';
            switch (response.status) {
                case 200:
                    let message = 'Schedule duration changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    props.fetchTheData();
                    props.onClose();

                    break;

                case 400:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                case 404:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

    };


    const updateMe = () => {
        setForceUpdate(!forceUpdate);
    };


    return (
        <div className={classes.scheduleForm}>
            <StyledTabs value={value} onChange={handleChange} aria-label="Schedule Form">
                <StyledTab label="COURSE & SESSION" style={firstTabHasErrors ? {color: 'red'} : {}} />
                <StyledTab label="START DATES" disabled={forbiddenStep < 2} style={Object.keys(ssdErrors).length > 0 ? {color: 'red'} : {}} />
                <StyledTab label="DAYS OF WEEK" disabled={forbiddenStep < 3} style={Object.keys(errors).length > 0 ? {color: 'red'} : {}} />
                <StyledTab label="FEES" disabled={forbiddenStep < 4} />
                <StyledTab label="PRICE & REVIEW" disabled={forbiddenStep < 5} />
            </StyledTabs>

            <FormWithRedirect
                {...props}
                record={{}}
                resource='schedules'
                mutators={{...arrayMutators}}
                render={formProps => (
                    <ScheduleFormView
                        {...formProps}
                        schoolYears={props.schoolYears}
                        activeTab={value}
                        eventInitialData={props.eventInitialData}
                        allowStep={allowStep}
                        disallowStep={disallowStep}
                        setForm={setForm}
                        errors={errors}
                        setErrors={setErrors}
                        ssdErrors={ssdErrors}
                        setSsdErrors={setSsdErrors}
                        forceUpdate={forceUpdate}
                        updateParent={updateMe}
                        setFirstTabHasErrors={setFirstTabHasErrors}
                    />
                )}
            />

            <Typography className={classes.padding} />

            <div style={{display: 'flex', flex: '0 0 auto', justifyContent: 'center', padding: 8, alignItems: 'center'}}>
                <Button
                    label="PREV"
                    onClick={onFormPrev}
                    startIcon={<ArrowPrevIcon />}
                    variant='contained'
                    disabled={value < 1}
                />

                {
                    value < 4 ?
                        <Button
                            label="NEXT"
                            onClick={onFormNext}
                            endIcon={<ArrowNextIcon />}
                            variant='contained'
                            style={{marginLeft: 8}}
                            disabled={((value > 3) || (forbiddenStep < value + 2))}
                        />
                    : null
                }

                {
                    value === 4 ?
                        <Button
                            label="FINISH"
                            onClick={handleSubmit}
                            variant='contained'
                            color='primary'
                            style={{marginLeft: 8}}
                        />
                    : null
                }
            </div>
        </div>
    );
};

export default ScheduleForm;
