import React from 'react';
import {
    Filter,
    ReferenceInput,
    TextInput
} from 'react-admin';
import {notificationTypesChoices} from '../';
import SelectInput from '../../../components/SelectInput';


export const NotificationsListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Subject & Tags' source='search' alwaysOn style={{width: 300}}/>
        <SelectInput source='NotificationType'
            label='Notifications Type' 
            choices={notificationTypesChoices} 
            optionText='name' 
            allowEmpty
            emptyValue={null}
            emptyText="All"
        />
        <ReferenceInput label='Sent By' source='CreatedBy' reference='notifications_created_by' allowEmpty emptyValue={null} emptyText="All">
            <SelectInput optionText='Name' />
        </ReferenceInput>
    </Filter>
);