import {connect} from 'react-redux';
// import { submitting } from 'react-final-form';
import CreateFamilyMemberButton from '../components/CreateFamilyMemberButton';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    // REDUX_FORM_NAME
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    // submit
};


export default connect(null, mapDispatchToProps)(
    CreateFamilyMemberButton
);
