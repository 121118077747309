import React, { Component, Fragment } from 'react';
import {
    CREATE,
    required,
    TextInput,
    SimpleForm
} from 'react-admin';
import { RegisterButton } from '../';
import { fetchIt, getErrorMessage } from '../../helpers';
import { passwordValidation, repeatPasswordValidation, emailValidation} from '../../validators';



class RegisterForm extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            isDisabled: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        const { fetchStart, fetchEnd, showNotification, userLogin } = this.props;
        const me = this;

        if (me._isMounted) {
            me.setState({
                isDisabled: true
            });
        }

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            CREATE,
            'register',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Registration successful.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    const auth = {
                        username: values.ContactEmail,
                        password: values.Password
                    };

                    userLogin(auth);

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.basePath = this.props.basePath;
        props.record = this.props.record;

        return (
            <Fragment>
                <SimpleForm
                    { ...props }
                    save={this.handleSubmit}
                    toolbar={<RegisterButton isDisabled={this.state.isDisabled} />}
                    align="center"
                >
                    <TextInput
                        source='OrganizationName'
                        validate={required('The Organization Name field is required')}
                        fullWidth
                    />
                    <TextInput
                        source='ContactFirstName'
                        validate={required('The Contact First Name field is required')}
                        fullWidth
                    />
                    <TextInput
                        source='ContactLastName'
                        validate={required('The Contact Last Name field is required')}
                        fullWidth
                    />
                    <TextInput
                        source='ContactEmail'
                        type='email'
                        validate={emailValidation}
                        fullWidth
                    />
                    <TextInput
                        source='Password'
                        type='password'
                        validate={passwordValidation}
                        fullWidth
                    />
                    <TextInput
                        source='RepeatPassword'
                        type='password'
                        validate={repeatPasswordValidation}
                        fullWidth
                    />
                    <TextInput
                        source='Url'
                        label='URL'
                        type='url'
                        fullWidth
                    />
                </SimpleForm>
            </Fragment>
        );
    }
}
export default RegisterForm;
