import {connect} from 'react-redux';
import ChangeActiveStatusButton from '../components/ChangeActiveStatusButton';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView
};

export default connect(null, mapDispatchToProps)(
    ChangeActiveStatusButton
);