import React, {useState} from 'react';
import {useInput, SelectInput as RaSelectInput} from 'react-admin';


const SelectInput = props => {
    const [value, setValue] = useState(0);
    const {input: {onChange}} = useInput(props);

    const inputOnChange = event => {
        setValue(event.target.value);

        let newValue = event.target.value;
        if (newValue === 0) {
            onChange();
        } else {
            onChange(event.target.value);
        }
    };

    return (
        <RaSelectInput
            {...props}
            options={{onChange: event => inputOnChange(event)}}
            emptyText='All'
            emptyValue={0}
            value={value}
        />
    );
};

export default SelectInput;
