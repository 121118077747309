import React from 'react';
import {
    List,
    Datagrid,
    SelectField,
    TextField,
    NumberField,
    FunctionField
} from 'react-admin';
import {NotificationsListFilters, NotificationsListMessageShow, notificationTypesChoicesToList} from '../';
import {renderReadBy, renderNotificationTags} from '../../../helpers';


export const NotificationsList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Notifications List</h1>
        <List {...props} filters={<NotificationsListFilters />} title=' ' bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid expand={<NotificationsListMessageShow {...props} />}>
                <TextField source='Subject' />
                <SelectField
                    source='NotificationType'
                    label='Type'
                    choices={notificationTypesChoicesToList}
                    translateChoice={false}
                />
                <FunctionField
                    source='NotificationTags'
                    label='Tags'
                    render={renderNotificationTags}
                    sortable={false}
                />
                <NumberField source='SentTo' />
                <FunctionField source='ReadBy' label='Read by' render={renderReadBy} />
                <TextField source='CreatedBy' label='Sent by' />
                <TextField source='SentTime' label='Sent on' />
            </Datagrid>
        </List>
    </div>
);
