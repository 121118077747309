import React, {Fragment, useState, useEffect} from 'react';
import {GET_LIST, ReferenceField} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';
import {Grid, Typography, Divider} from '@material-ui/core';
import {fetchIt} from '../../../helpers';
import moment from 'moment';
import TypographyField from './TypographyField';
import CurrencyInput from "../../../components/CurrencyInput";


const SchedulePricesInput = props => {
    const [activeTab, setActiveTab] = useState(0);
    const [prices, setPrices] = useState([]);
    const form = useForm();
    const formState = useFormState();

    const {fetchStart, fetchEnd, showNotification, setForm} = props;

    useEffect(() => {
        if (
            (typeof props.activeTab !== 'undefined') &&
            (props.activeTab !== activeTab) &&
            (props.activeTab === 4)
        ) {
            const semestersDates = formState.values.StartDates.map(startDate => {
                return {
                    Semester: startDate.Semester,
                    StartDate: moment.utc(startDate.StartDate, 'MM/DD/YYYY h:mmA', true).format(),
                    EndDate: moment.utc(startDate.EndDate, 'MM/DD/YYYY h:mmA', true).format()
                };
            });

            const scheduleDaysOfWeek = formState.values.ScheduleDaysOfWeek.map((dayOfWeek, index) => {
                return {
                    Idx: index,
                    Studio: dayOfWeek.Studio,
                    DayOfWeek: dayOfWeek.DayOfWeek,
                    StartTime: moment.utc(dayOfWeek.StartTime, 'h:mmA', true).format('HH:mm'),
                    Duration: dayOfWeek.Duration,
                };
            });

            const data = {
                Course: formState.values.Course,
                SemestersDates: semestersDates,
                ScheduleDaysOfWeek: scheduleDaysOfWeek,
            };

            fetchStart();

            fetchIt(
                GET_LIST,
                'price_and_preview',
                data
            ).then(response => {
                switch (response.status) {
                    case 200:
                        if (response && response.data) {
                            const semesterStartDates = formState.values.StartDates;
                            const prices = response.data.map(price => {
                                const semesterStartDate = semesterStartDates.find(sDate => (sDate.Semester === price.Id));

                                if (typeof semesterStartDate !== 'undefined') {
                                    price.StartDate = moment.utc(semesterStartDate.StartDate, 'MM/DD/YYYY h:mmA').format('MM/DD/YYYY');
                                    price.EndDate = moment.utc(semesterStartDate.EndDate, 'MM/DD/YYYY h:mmA').format('MM/DD/YYYY');
                                }

                                form.change('Price' + price.Id, price.Price);

                                return price;
                            });

                            setPrices(prices);

                            form.change('SchedulePrices', prices);
                            setForm(form);
                        }

                        break;

                    case 404:
                        let errorMessage = '';

                        if (!response.data.HasValidationErrors) {
                            errorMessage = response.data.ErrorMessage;
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        );
                }
            }).catch(error => {

            }).then(() => {
                // Dispatch an action letting react-admin know an API call has ended
                fetchEnd();
            });
        } else {
            setPrices([]);
        }

        setActiveTab(props.activeTab);
    }, [props.activeTab]); // eslint-disable-line


    const onChange = (event, index) => {
        event.persist();

        let newPrices = prices;
        newPrices[index].Price = parseFloat(event.target.value) ? parseFloat(event.target.value) : 0.00;
        setPrices(newPrices);

        form.change('SchedulePrices', newPrices);
        setForm(form);
    };


    if (prices.length > 0) {
        return (
            <Fragment>
                {
                    prices.map((price, index) =>  {
                        let record = price;
                        record.Course = formState.values.Course;

                        return (
                            <div key={price.Id} style={{marginTop: 20}}>
                                <ReferenceField
                                    basePath='schedules'
                                    source='Course'
                                    reference='courses'
                                    resource='courses'
                                    linkType={false}
                                    record={record}
                                >
                                    <TypographyField source='Name' variant='h5' index={index + 1} />
                                </ReferenceField>

                                <Grid container spacing={5}>
                                    <Grid item lg={2} sm={6} xs={12}>
                                        <TypographyField source='StartDate' label='Start Date' record={record} variant='body2' component='span' />
                                    </Grid>
                                    <Grid item lg={2} sm={6} xs={12}>
                                        <TypographyField source='EndDate' label='End Date' record={record} variant='body2' component='span' />
                                    </Grid>
                                    <Grid item lg={4} sm={6} xs={12}>
                                        <TypographyField source='Name' label='Session' record={record} variant='body2' component='span' />
                                    </Grid>
                                    <Grid item lg={2} sm={6} xs={12}>
                                        <TypographyField source='NumberOfClasses' label='Classes' record={record} variant='body2' component='span' />
                                    </Grid>
                                    <Grid item lg={2} sm={6} xs={12}>
                                        <CurrencyInput
                                            label='Schedule Price *'
                                            source={'Price' + price.Id}
                                            val={price.Price}
                                            inDialog={true}
                                            onChange={value => onChange(value, index)}
                                            step={0.05}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider style={{marginTop: 15, marginBottom: 40}} />
                            </div>
                        );
                    })
                }
            </Fragment>
        );
    }

    return (
        <Typography variant='h6'>Processing...</Typography>
    );
};

export default SchedulePricesInput; // decorate with react-final-form's <Field>
