import React, { Fragment } from 'react';

export const renderReadBy = record => {return record.NotificationType === 1 ? '-' : record.ReadBy};
export const renderNotificationTags = record => {
    record.NotificationTags = record.NotificationTags || [];

    return record.NotificationTags.map((tag, key)=> {
        return (
            <Fragment key={key}>{tag}<br/></Fragment>
        );
    })
};
export const renderYearsFromMonths = (record, field) => {
    const months = record[field];
    if (!months) {
        return 0;
    }

    let years = months / 12;
    if (Math.floor(years) < years) {
        years = Math.floor(years) + 0.5;
    }

    return years;
};
