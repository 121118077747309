import {connect} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import CourseCategoryForm from '../components/CourseCategoryForm';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    CourseCategoryForm
);