import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    SelectArrayInput,
    BooleanInput,
    FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {NtaSaveButton} from '../../common';
import {fetchIt, getErrorMessage, formatPhone, getWeekdayChoices} from "../../../helpers";
import {Redirect} from 'react-router';
import {emailValidation} from '../../../validators';
import {PhoneMaskedInput, ZipCodeMaskedInput, UsStates, TimePicker} from '../../../common';
import {phoneValidation, faxValidation, zipCodeValidation} from '../../../validators';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';


export const SchoolEditToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


export const SchoolTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};


export const AddressHeading = () => (
    <h2>
        Address
    </h2>
);


class SchoolEditForm extends Component {
    _isMounted = false;


    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };


    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        if (values.Phone) {
            values.Phone = values.Phone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.Fax) {
            values.Fax = values.Fax.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.WorkStartTime) {
            values.WorkStartTime = values.WorkStartTime.format('HH:mm:ss');
        }
        if (values.WorkEndTime) {
            values.WorkEndTime = values.WorkEndTime.format('HH:mm:ss');
        }

        values.id = this.props.id;

        fetchIt(
            UPDATE,
            'schools',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'School saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    values.Phone = formatPhone(values.Phone);
                    values.Fax = formatPhone(values.Fax);
                    values.WorkStartTime = values.WorkStartTime ? moment.utc(values.WorkStartTime, 'HH:mm:ss', true) : null;
                    values.WorkEndTime = values.WorkEndTime ? moment.utc(values.WorkEndTime, 'HH:mm:ss', true) : null;

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.Phone = formatPhone(values.Phone);
                    values.Fax = formatPhone(values.Fax);
                    values.WorkStartTime = values.WorkStartTime ? moment.utc(values.WorkStartTime, 'HH:mm:ss', true) : null;
                    values.WorkEndTime = values.WorkEndTime ? moment.utc(values.WorkEndTime, 'HH:mm:ss', true) : null;

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <SimpleForm
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<SchoolEditToolbar isDisabled={this.state.isDisabled} />}
                        >
                            <TextInput source="SchoolName" className='fieldsWidthExpand' validate={required('The School Name field is required')} />
                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return <SchoolTitle title={formData.SchoolName} />;
                                    }
                                }
                            </FormDataConsumer>

                            {
                                ! (
                                    Object.keys(this.props.record).length === 0 &&
                                    this.props.record.constructor === Object
                                ) ?
                                    <RichTextInput source="Description" />
                                : null
                            }

                            <PhoneMaskedInput
                                required
                                source='Phone'
                                validate={phoneValidation}
                                className='fieldsWidthExpand'
                            />
                            <PhoneMaskedInput
                                required
                                source='Fax'
                                validate={faxValidation}
                                className='fieldsWidthExpand'
                            />
                            <TextInput type='email' source='Email' className='fieldsWidthExpand' validate={emailValidation} />
                            <TextInput source='ContactPerson' className='fieldsWidthExpand' validate={required('The Contact Person field is required')} />
                            <TextInput source='Director' className='fieldsWidthExpand' validate={required('The Director field is required')} />
                            <TextInput type='url' label='URL' source='URL' className='fieldsWidthExpand' />

                            <SelectArrayInput source="WorkDays" choices={getWeekdayChoices} className='fieldsWidthExpand' />

                            <TimePicker source="WorkStartTime" />
                            <TimePicker source="WorkEndTime" />

                            <BooleanInput source='IsActive' defaultValue={this.props.editMode ? '' : true}/>

                            <AddressHeading />
                            <TextInput 
                                source='Address.City'
                                label='City' className='fieldsWidthExpand'
                                validate={required('The City field is required')}
                            />
                            <UsStates source='Address.USState' className='fieldsWidthExpand' validate={required('The State field is required')}/>
                            <ZipCodeMaskedInput
                                required
                                source='Address.ZIP'
                                label='ZIP Code'
                                validate={zipCodeValidation}
                                className='fieldsWidthExpand'
                            />
                            <TextInput
                                multiline
                                source='Address.StreetAddress'
                                label='Street Address'
                                validate={required('The Street Address field is required')}
                                className='fieldsWidthExpand'
                            />
                            <TextInput source='Address.StreetAddressLine2' className='fieldsWidthExpand' label='Street Address Line 2' />
                        </SimpleForm>
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}

SchoolEditForm.propTypes = {
    record: PropTypes.object.isRequired
};

SchoolEditForm.defaultProps = {
    record: {}
};

export default SchoolEditForm;
