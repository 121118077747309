import React from 'react';
import {
    Filter,
    ReferenceInput,
    TextInput
} from 'react-admin';
import SelectInput from '../../../components/SelectInput';


export const StudiosListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Studio Name' source='search' alwaysOn style={{width: 300}}/>
        <ReferenceInput label='Location' source='SchoolLocationId' reference='locations' allowEmpty emptyValue={null} emptyText="All">
            <SelectInput optionText='LocationName' />
        </ReferenceInput>
    </Filter>
);