import {connect} from 'react-redux';
import SendActivationEmailButton from '../components/SendActivationEmailButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    SendActivationEmailButton
);