import React, {useCallback} from 'react';
import {useFormState, useForm} from 'react-final-form';
import {SaveButton} from 'react-admin';

const NtaSaveButton = props => {

    const {isDisabled, onCloseClick, showNotification, ...otherProps} = props;

    const formState = useFormState();
    const form = useForm();
    const handleClick = useCallback(() => {

        if (!formState.valid) {
            if(showNotification){
                showNotification(
                    'direct_message',
                    'warning',
                    { messageArgs: { _: 'The form is not valid. Please check for errors' } }
                );
            }

            return;
        }

        if (onCloseClick) {
            onCloseClick();
        }

        form.submit();
    }, [
        form,
        formState.valid,
        onCloseClick,
        showNotification
    ]);

    return <SaveButton {...otherProps} handleSubmitWithRedirect={handleClick} disabled={isDisabled} />;
};

export default NtaSaveButton;
