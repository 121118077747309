import React, {Fragment, useState, useEffect} from 'react';
import {addField, GET_LIST} from 'react-admin';
import {useForm, useFormState} from "react-final-form";
import {Grid, Typography} from "@material-ui/core";
import {fetchIt} from "../../../helpers";
import {StartDatePicker} from '../../../common';
import moment from "moment";


const ScheduleDatesInput = props => {
    const [hasChanged, setHasChanged] = useState(false);
    const [startDates, setStartDates] = useState([]);
    const [metas, setMetas] = useState({});
    const form = useForm();
    const formState = useFormState();

    const {fetchStart, fetchEnd, showNotification} = props;
    const semesters = formState.values.Semesters;


    useEffect(() => {
        if ((typeof semesters !== 'undefined') && (semesters.length > 0)) {

            // Dispatch an action letting react-admin know an API call is ongoing
            fetchStart();

            fetchIt(
                GET_LIST,
                'semesters',
                {ids: semesters}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        if (response && response.data) {
                            let newStartDates = [];

                            for (let t = 0; t < response.data.length; t++) {
                                let startDate = {
                                    Semester: response.data[t].Id,
                                    Name: response.data[t].Name,
                                    FromDate: moment.utc(response.data[t].FromDate, 'MM/DD/YYYY h:mmA', true).add(1, 'days').format('YYYY-MM-DD'),
                                    ToDate: moment.utc(response.data[t].ToDate, 'MM/DD/YYYY h:mmA', true).format('YYYY-MM-DD'),
                                };

                                const neededStartDate = startDates.find((sdElement, index) => (sdElement.Semester === response.data[t].Id));

                                if (typeof neededStartDate !== 'undefined') {
                                    startDate.StartDate = neededStartDate.StartDate;
                                    startDate.EndDate = neededStartDate.EndDate;
                                } else {
                                    startDate.StartDate = moment.utc(response.data[t].FromDate, 'MM/DD/YYYY h:mmA').format('MM/DD/YYYY h:mmA');
                                    startDate.EndDate = moment.utc(response.data[t].ToDate, 'MM/DD/YYYY h:mmA').format('MM/DD/YYYY h:mmA');
                                }

                                newStartDates.push(startDate);
                            }

                            form.change('StartDates', newStartDates);

                            setStartDates(newStartDates);
                            setHasChanged(!hasChanged);
                        }

                        break;

                    case 404:
                        let errorMessage = '';

                        if (!response.data.HasValidationErrors) {
                            errorMessage = response.data.ErrorMessage;
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        );
                }
            }).catch(error => {

            }).then(() => {
                // Dispatch an action letting react-admin know an API call has ended
                fetchEnd();
            });
        } else {
            setStartDates([]);
        }
    }, [semesters]); // eslint-disable-line


    const onChange = (value, startDateId, index, type) => {
        let newStartDates = startDates;
        let newStartDate = '';
        if (value) {
            newStartDate = value.format('MM/DD/YYYY h:mmA');
        }

        validateStartDate(value, startDateId, index, type);

        newStartDates[index][type] = newStartDate;
        setStartDates(newStartDates);
        form.change('StartDates', newStartDates);
        setHasChanged(!hasChanged);
    };


    const validateStartDate = (value, startDateId, index, type) => {
        let newMetas = metas;
        newMetas[type + startDateId] = {touched: true, error: undefined};

        let errors = props.errors;
        delete errors[type + startDateId];

        let theOtherType = 'StartDate';
        if (type === 'StartDate') {
            theOtherType = 'EndDate';
        }

        const theOtherDate = moment.utc(startDates[index][theOtherType], 'MM/DD/YYYY h:mmA');
        if (theOtherDate.isValid()) {
            newMetas[theOtherType + startDateId] = {touched: true, error: undefined};
            delete errors[theOtherType + startDateId];
        }

        if (!value || !value.isValid()) {
            newMetas[type + startDateId] = {touched: true, error: 'Invalid date'};
            errors[type + startDateId] = 1;
        } else {
            if (theOtherDate.isValid()) {
                if (type === 'StartDate') {
                    if (theOtherDate.isSameOrBefore(value)) {
                        newMetas[type + startDateId] = {touched: true, error: 'The Schedule Start Date should be before Schedule End Date'};
                        newMetas[theOtherType + startDateId] = {touched: true, error: 'The Schedule End Date should be after Schedule Start Date'};
                        errors[type + startDateId] = 1;
                        errors[theOtherType + startDateId] = 1;
                    }
                } else {
                    if (theOtherDate.isSameOrAfter(value)) {
                        newMetas[type + startDateId] = {touched: true, error: 'The Schedule End Date should be after Schedule Start Date'};
                        newMetas[theOtherType + startDateId] = {touched: true, error: 'The Schedule Start Date should be before Schedule End Date'};
                        errors[theOtherType + startDateId] = 1;
                        errors[type + startDateId] = 1;
                    }
                }
            } else {
                newMetas[theOtherType + startDateId] = {touched: true, error: 'Invalid date'};
                errors[theOtherType + startDateId] = 1;
            }
        }

        setMetas(newMetas);
        props.setErrors(errors);
        props.updateParent();

        setHasChanged(!hasChanged);
    }


    if (startDates.length > 0) {
        return (
            <Fragment>
                {
                    startDates.map((aDate, index) =>  (
                        <div key={aDate.Semester} style={{marginTop: 20}}>
                            <Typography variant="subtitle2" style={{marginBottom: 0}}>{aDate.Name}</Typography>
                            <Grid container spacing={5}>
                                <Grid item sm={6} xs={12}>
                                    <StartDatePicker
                                        componentProps={{style: {marginTop: 0}}}
                                        label='Schedule Start Date *'
                                        source={'ScheduleStartDates.StartDate' + aDate.Semester}
                                        val={moment.utc(aDate.StartDate, 'MM/DD/YYYY h:mmA')}
                                        onChange={value => onChange(value, aDate.Semester, index, 'StartDate')}
                                        meta={metas['StartDate' + aDate.Semester] ? metas['StartDate' + aDate.Semester] : {touched: false, error: undefined}}
                                        minDate={aDate.FromDate}
                                        maxDate={aDate.ToDate}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <StartDatePicker
                                        componentProps={{style: {marginTop: 0}}}
                                        label='Schedule End Date *'
                                        source={'ScheduleStartDates.EndDate' + aDate.Semester}
                                        val={moment.utc(aDate.EndDate, 'MM/DD/YYYY h:mmA')}
                                        onChange={value => onChange(value, aDate.Semester, index, 'EndDate')}
                                        meta={metas['EndDate' + aDate.Semester] ? metas['EndDate' + aDate.Semester] : {touched: false, error: undefined}}
                                        minDate={aDate.FromDate}
                                        maxDate={aDate.ToDate}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))
                }
            </Fragment>
        );
    }

    return (
        <Typography variant="h6">No semesters selected</Typography>
    );
};

export default addField(ScheduleDatesInput); // decorate with react-final-form's <Field>
