import {required} from "react-admin";

export const zipCodeRegExValidation = (message = 'The ZIP Code is invalid') =>
    value => {
        const zipCodeRegEx = /^.{5}(?:-.{4})?$/;

        return !value || zipCodeRegEx.test(value.substring(0, 10)) ? undefined : message;
    }

export const zipCodeValidation = [
    required('The ZIP Code field is required'),
    zipCodeRegExValidation('The ZIP Code is invalid')
];
