import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    downloadCSV
} from 'react-admin';
import {CourseCategoriesListFilters} from '../';
import jsonExport from 'jsonexport/dist';
import {inArray} from '../../../../helpers';
import SplitButton from '../../../../common/containers/SplitButton';


export const exporter = records => {
    const headers = ['Id', 'Name', 'Description', 'Status'];

    const data = records.map(record => {
        let recordData = {};
        // eslint-disable-next-line
        for (const prop in record) {
            if (record.hasOwnProperty(prop) && inArray(prop, headers)) {
                recordData[prop] = record[prop];
            }
        }

        return ({
            ...recordData,
            Description: record.DescriptionTagless ? record.DescriptionTagless : record.Description,
            Status: record.IsActive ? 'Active' : 'Inactive',
        });
    });

    jsonExport(data, {headers}, (err, csv) => {
        downloadCSV(csv, 'CourseCategoriesList');
    });
};



export const CourseCategoriesList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Course Categories List</h1>
        <List {...props} filters={<CourseCategoriesListFilters />} title=' ' exporter={exporter} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="Name" label="Category"/>
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonTd'
                    successMessage='Category deleted'
                    errorMessage='Category NOT deleted'
                    confirmTitle='Delete the category'
                    confirmContent='Are you sure you want to delete the category?' 
                />
            </Datagrid>
        </List>
    </div>
);
