import React from 'react';
import {
    Filter,
    TextInput
} from 'react-admin';


export const LocationsListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Location name' source='search' alwaysOn style={{width: 300}}/>
    </Filter>
);
