import React, {Fragment, Component} from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
    EditButton,
    ShowButton
} from 'react-admin';
import TeacherSendEmailButton  from '../containers/TeacherSendEmailButton';



class TeacherSplitButton extends Component {
    _isMounted = false;
    anchorRef = null;
    options = [];

    constructor(props) {
        super(props);

        this.anchorRef = React.createRef();
    }

    state = {
        open: false,
        selectedIndex: 0,
        record: {}
    };

    componentDidMount () {
        this._isMounted = true;

        this.setState({
            record: this.props.record
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.record && this._isMounted) {
            this.setState({
                record: nextProps.record
            });
        }
    }

    showActionsButtons = () => {
        switch (this.options[this.state.selectedIndex]) {
            case "Edit":
                return <EditButton 
                            variant="contained" 
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0, 
                                minHeight: 32,
                                padding: '4px 8px'
                            }}
                            record={this.props.record} 
                            basePath={this.props.basePath} 
                        />;

            case "Send Email":
                return <TeacherSendEmailButton label="Send Email" record={this.props.record} />;

            default:
                return <ShowButton
                            label="Details"
                            variant="contained" 
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0, 
                                minHeight: 32,
                                padding: '4px 8px'
                            }} 
                            record={this.props.record} 
                            basePath={this.props.basePath} 
                        />;
        }
    };

    handleMenuItemClick = (event, index) => {
        this.setState({ 
            open: false, 
            selectedIndex: index
        });
    };

    handleToggle = (event, index) => {
        this.setState({ open: !this.state.open });
    };

    handleClose = (event, index) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ open: false});
    };

    render() {
        this.options = ['Details', 'Edit', 'Send Email'];

        return (
            <Fragment>
                <div ref={this.anchorRef} style={{minWidth: 140}}>
                    {this.showActionsButtons()}
                    
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                        style={{minWidth: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </div>
                <Popper 
                    open={this.state.open} 
                    anchorEl={this.anchorRef.current} 
                    transition 
                    placement='bottom-start' 
                    style={{zIndex: 999}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        {this.options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === this.state.selectedIndex}
                                                onClick={event => this.handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

export default TeacherSplitButton;