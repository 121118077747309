import React from 'react';
import moment from "moment";
// import inflection from 'inflection';


export const inArray = (needle = '', haystack = []) => {
    return (Array.isArray(haystack) && (haystack.indexOf(needle) > -1));
};


export const getErrorMessage = data => {
    let errorMessage = 'Oops, something went wrong';
    if (typeof data.HasValidationErrors !== 'undefined') {
        if (data.HasValidationErrors) {
            errorMessage = data.Errors.map((message, key) => {
                return (
                    <p key={key}>{message.Message}</p>
                );
            });
            errorMessage = data.Errors[0].Message;
        } else {
            errorMessage = data.ErrorMessage;
        }
    } else if (data.error_description !== 'undefined') {
        errorMessage = data.error_description;
    }

    return errorMessage;
};


// function keysSorter(input) {
//     if (Array.isArray(input)) {
//         return input.sort();
//     }
//
//     if (typeof input === 'object') {
//         return keysSorter(Object.keys(input))
//         .sort((a, b) => Number(a) - Number(b))
//         .map(key => input[key]);
//     }
//
//     return input;
// }


// export const extractParamFromQuery = query => {
//     // Create an object with no prototype
//     const ret = Object.create(null);
//
//     if (typeof query !== 'string') {
//         return ret;
//     }
//
//     query = query.trim().replace(/^[?#&]/, '');
//
//     if (!query) {
//         return ret;
//     }
//
//     for (const param of query.split('&')) {
//         let [key, value] = param.replace(/\+/g, ' ').split('=');
//
//
//         // Missing `=` should be `null`:
//         // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
//         value = value === undefined ? null : value;
//         ret[key] = value;
//     }
//
//     return Object.keys(ret).sort().reduce((result, key) => {
//         const value = ret[key];
//         if (Boolean(value) && typeof value === 'object' && !Array.isArray(value)) {
//             // Sort object keys, not values
//             result[key] = keysSorter(value);
//         } else {
//             result[key] = value;
//         }
//
//         return result;
//     }, Object.create(null));
// };


export const formatPhone = phoneNumber => {
    if(!phoneNumber || phoneNumber === ""){
        return "";
    }

    phoneNumber = phoneNumber.replace(/\(|\)|\s|-/gi, "");

    const firstPart = phoneNumber.substring(0, 3);
    const secondPart = phoneNumber.substring(3, 6);
    const thirdPart = phoneNumber.substring(6);

    return "(" + firstPart + ") " + secondPart + "-" + thirdPart;
};


// export const getDataUrl = (file, callback2) => {
//     var callback = function (srcOrientation) {
//         var reader2 = new FileReader();
//         reader2.onload = function (e) {
//             var srcBase64 = e.target.result;
//             var img = new Image();
//
//             img.onload = function () {
//                 var width = img.width,
//                     height = img.height,
//                     canvas = document.createElement('canvas'),
//                     ctx = canvas.getContext("2d");
//
//                 // set proper canvas dimensions before transform & export
//                 if (4 < srcOrientation && srcOrientation < 9) {
//                     canvas.width = height;
//                     canvas.height = width;
//                 } else {
//                     canvas.width = width;
//                     canvas.height = height;
//                 }
//
//                 // transform context before drawing image
//                 switch (srcOrientation) {
//                     case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
//                     case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
//                     case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
//                     case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
//                     case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
//                     case 7: ctx.transform(0, -1, -1, 0, height, width); break;
//                     case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
//                     default: break;
//                 }
//
//                 // draw image
//                 ctx.drawImage(img, 0, 0);
//
//                 // export base64
//                 callback2(canvas.toDataURL());
//             };
//
//             img.src = srcBase64;
//         };
//
//         reader2.readAsDataURL(file);
//     };
//
//     var reader = new FileReader();
//     reader.onload = function (e) {
//
//         var view = new DataView(e.target.result);
//         if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);
//         var length = view.byteLength, offset = 2;
//         while (offset < length) {
//             var marker = view.getUint16(offset, false);
//             offset += 2;
//             if (marker === 0xFFE1) {
//                 if (view.getUint32(offset += 2, false) !== 0x45786966) return callback(-1);
//                 var little = view.getUint16(offset += 6, false) === 0x4949;
//                 offset += view.getUint32(offset + 4, little);
//                 var tags = view.getUint16(offset, little);
//                 offset += 2;
//                 for (var i = 0; i < tags; i++)
//                     if (view.getUint16(offset + (i * 12), little) === 0x0112)
//                         return callback(view.getUint16(offset + (i * 12) + 8, little));
//             }
//             else if ((marker & 0xFF00) !== 0xFF00) break;
//             else offset += view.getUint16(offset, false);
//         }
//         return callback(-1);
//     };
//     reader.readAsArrayBuffer(file);
// };


export const capitalize = s => {
    if (typeof s !== 'string') {
        return '';
    }

    return s.charAt(0).toUpperCase() + s.slice(1);
};


// export const getLabelFromSource = source => {
//     return source ? inflection.transform(source, ['underscore', 'humanize']) : '';
// };


export const getUserName = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.FirstName + ' ' + account.LastName);
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("Whoops!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);

            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.FirstName + ' ' + account.LastName);
        }
    }, 200);
});


export const getPermissions = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.Role);
    }

    if(localStorage.getItem('user') === null) {
        reject(new Error("No account data!"));
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("No account data!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);
            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.Role);
        }
    }, 200);
});


export const formatWorkHour = time => {
    if(!time || time === ""){
        return "";
    }

    return moment(time, 'HH:mm:ss').format('h:mm A');
};


export const getWeekdays = () => {
    let weekdays = [];
    for (let t = 1; t < 8; t++) {
        weekdays.push(moment(t, 'E').format('dddd'));
    }

    return weekdays;
};


export const getWeekdayChoices = getWeekdays().map(weekDay => ({id: weekDay, name: weekDay}));
