import React, {Component} from 'react';
import {
    CREATE,
    UPDATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {NtaSaveButton} from '../../common';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {ColorInput} from 'react-admin-color-input';


export const StudioFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


class StudioForm extends Component {
    _isMounted = false;

    state = {
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    nameOnChange = e => {
        this.updateTitle(e.currentTarget.value);
    };

    updateTitle = newTitle => {
        if (this.props.updateTitle) {
            let title = '';
            if (newTitle) {
                title = newTitle;
            }
            this.props.updateTitle(title);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.updateTitle(nextProps.record.Name);
        }
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        fetchIt(
            requestType,
            'studios',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Studio saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Card className="cardForm">
                <CardContent>
                    <SimpleForm
                        record={this.props.record}
                        resource="studios"
                        save={this.handleSubmit}
                        toolbar={<StudioFormToolbar isDisabled={this.state.isDisabled} />}
                    >
                        <TextInput source="Name"  className='fieldsWidthExpand' onChange={this.nameOnChange} validate={required('The Studio Name field is required')} />

                        <ReferenceInput
                            required
                            label='Location'
                            source='SchoolLocationId'
                            resource='locations'
                            reference='locations'
                            filter={{IsActive: true}}
                            perPage={1000}
                            validate={required('The location field is required')}
                            className='fieldsWidthExpand'
                        >
                            <SelectInput optionText='LocationName' />
                        </ReferenceInput>

                        {
                            ! this.props.editMode ||
                            ! (
                                Object.keys(this.props.record).length === 0 &&
                                this.props.record.constructor === Object
                            ) ?
                                <RichTextInput source="Description" />
                            : null
                        }

                        <BooleanInput source='IsActive' defaultValue={this.props.editMode ? '' : true} />

                        <ColorInput source='DisplayColor' picker='Sketch'  className='fieldsWidthExpand' />

                    </SimpleForm>
                </CardContent>
             </Card>
        );
    }
}
export default StudioForm;
