import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField
} from 'react-admin';
import {LocationsListFilters} from '../';
import {formatPhone, exporter} from "../../../helpers";
import SplitButton from '../../../common/containers/SplitButton';


export const LocationsList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Locations List</h1>
        <List {...props} exporter={exporter} filters={<LocationsListFilters />} title=' ' sort={{field: 'id', order: 'DESC'}} bulkActionButtons={false}>
            <Datagrid>
                <TextField source='LocationName' label="Location" />
                <FunctionField label="Phone" render={record => {return `${record.Phone}` === "null" ? '' : formatPhone(`${record.Phone}`)}} />
                <TextField source='ContactPerson' label="Contact" />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonTdSmall'
                    successMessage={'Locations deleted'}
                    errorMessage={'Locations NOT deleted'}
                    confirmTitle={'Delete the locations'}
                    confirmContent={'Are you sure you want to delete the location?'}
                />
            </Datagrid>
        </List>
    </div>
);
