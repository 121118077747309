import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import { connect } from "react-redux";
import Calendar from '../components/Calendar';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    Calendar
);
