import React, {Component, Fragment} from 'react';
import {GET_ONE} from 'react-admin';
import MyAccountForm from '../containers/MyAccountForm';
import MyAccountTeacherForm from '../containers/MyAccountTeacherForm';
import {fetchIt} from "../../../helpers/fetcher";
import {formatPhone} from "../../../helpers";
import moment from "moment";
import {BackButton} from '../../../common';


const isTeacher = localStorage.getItem('account') ? (JSON.parse(localStorage.getItem('account')).Role === 'Teacher') : false;

class MyAccountEdit extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            record: {
                Address: {}
            }
        };
    }


    componentDidMount() {
        this._isMounted = true;
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            isTeacher ? 'teacher_account' : 'account',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    response.data.HomePhone = formatPhone(response.data.HomePhone);
                    response.data.CellPhone = formatPhone(response.data.CellPhone);

                    if (isTeacher) {
                        response.data.WorkPhone = formatPhone(response.data.WorkPhone);
                        if (me._isMounted) {
                            me.setState({
                                record: {...response.data}
                            });
                        }
                    } else {
                        response.data.BirthDate = response.data.BirthDate ? moment.utc(response.data.BirthDate, 'MM/DD/YYYY', true) : null;

                        let record = response.data;
                        fetchIt(
                            GET_ONE,
                            'home_address',
                            {id: record.Id}
                        ).then(addressResponse => {
                            if (addressResponse.status === 200) {
                                addressResponse.data.HomePhone = formatPhone(addressResponse.data.HomePhone);

                                record.Address = addressResponse.data;
                            }
                        }).catch(error => {

                        }).then(() => {
                            if (me._isMounted) {
                                me.setState({record});
                            }

                            // Dispatch an action letting react-admin know an API call has ended
                            fetchEnd();
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Edit My Account Data</h1>
                {
                    isTeacher ?
                        <MyAccountTeacherForm record={this.state.record} {...this.props} />
                    :
                        <MyAccountForm record={this.state.record} {...this.props} />
                }
            </Fragment>
        );
    }
}
export default MyAccountEdit;
