import React, {Component, Fragment} from 'react';
import {TextField, Datagrid, ReferenceField} from 'react-admin';
import {TeacherAssignmentsListFilters} from '../'


class TeacherAssignmentsGrid extends Component {
    render() {
        const {fetchTheData, schoolYears, semesters, fetchAssignments, fetchSemesters, ...other} = this.props;

        if(!this.props.data || (Object.keys(this.props.data).length === 0 )){
            return(
                <Fragment>
                    <TeacherAssignmentsListFilters schoolYears={schoolYears} semesters={semesters} fetchAssignments={fetchAssignments} fetchSemesters={fetchSemesters}/>
                    <div className="NoDataLabel">
                        No data.
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <TeacherAssignmentsListFilters schoolYears={schoolYears} semesters={semesters} fetchAssignments={fetchAssignments} fetchSemesters={fetchSemesters}/>
                <Datagrid {...other} record={this.props.record}>
                    <ReferenceField 
                        source="Studio" 
                        reference="studios" 
                        link={(record, reference) => `/${reference}/${record.Studio}/show`}
                        sortable={false}
                    >
                        <TextField source="Name" />
                    </ReferenceField>
                    <TextField source="DayOfWeek" sortable={false} />
                    <TextField source="StartTime" sortable={false} />
                    <TextField source="ScheduleName" sortable={false} />
                </Datagrid>
            </Fragment>
        );
    }
}

export default TeacherAssignmentsGrid;