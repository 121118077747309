import React, {Fragment, Component} from 'react';
import {Create} from 'react-admin';
import {AgeIntervalForm} from '../';
import {BackButton} from '../../../../common';


class AgeIntervalCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Create Age Interval</h1>
                <Create {...this.props} title=" ">
                    <AgeIntervalForm />
                </Create>
            </Fragment>
        );
    }
}
export default AgeIntervalCreate;
