import React from 'react';
import {addField, NumberInput} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';


const styles = {
    numberInput: {
        '& input': {paddingLeft: 15}
    },
};


const CurrencyInput = withStyles(styles)(({input, meta, source, record, id, label, classes, inDialog, val, ...props}) => {
    return (
        <div style={{display: "flex"}}>
            <span style={{alignSelf: "flex-end", marginBottom: 34, marginRight: -9}}>$</span>

            <NumberInput
                label={label ? label : undefined}
                source={source}
                defaultValue={val ? val : '0.00'}
                step={0.05}
                inputProps={{
                    min: 0.00
                }}
                format={v => parseFloat(v) >= 0 ? parseFloat(v) : ' '}
                parse={v => parseFloat(v) > 0 ? parseFloat(v) : '0.00'}
                meta={meta}
                {...props}
                className={classes.numberInput}
            />
        </div>
    );
});

export default addField(CurrencyInput); // decorate with react-final-form's <Field>
