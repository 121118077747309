import React, { Fragment } from 'react';
import {
    TextField,
    FunctionField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';
import {formatPhone} from "../../../helpers";


export const LocationShow = props => {
    const fieldsToFormat = [
        {
            field: 'Phone',
            formatter: formatPhone
        },
        {
            field: 'Fax',
            formatter: formatPhone
        },
    ];

    const options = {
        detailsTitle: 'location',
        titleField: 'LocationName', 
    };

    return (
        <Fragment>
            <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
                <DetailsTileWrapper
                    sideTitle='Location Details'
                    sideText='Add your school location here. Later you will be able
                        to distribute your classes in the locations you want.'
                >
                    <TextField source='LocationName' />
                    <TextField source='Phone' />
                    <TextField source='Email' />
                    <TextField source='ContactPerson' />
                    <TextField source='Fax' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                </DetailsTileWrapper>
                <DetailsTileWrapper
                    sideTitle='Location Address'
                    sideText='This address will appear on your invoices.'
                    recordObject='Address'
                >
                    <TextField source='Address.StreetAddress' label='Street Address' />
                    <TextField source='Address.USState' label='State' />
                    <TextField source='Address.ZIP' label='Zip' />
                    <TextField source='Address.StreetAddressLine2' label='Street Address Line 2' />
                    <TextField source='Address.City' label='City' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
