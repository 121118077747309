import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import {connect} from "react-redux";
import CourseEdit from '../components/CourseEdit';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(CourseEdit);
