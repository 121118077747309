import React, {Component, Fragment} from 'react';
import {GET_LIST} from 'react-admin';
import {fetchIt} from "../../../../helpers";
import TenantUserFamilyGrid from "./TenantUserFamilyGrid";


class TenantUserFamilyList extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            data: {},
            ids: [],
            currentSort: {field: "id", order: "DESC"}
        };
    }


    fetchMembers = (sort, memberId) => {
        if (!sort) {
            sort = {field: "id", order: "DESC"};
        }

        if (!memberId) {
            memberId = this.props.record[this.props.source];
        }

        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'users_family_members',
            {id: memberId, sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let data = {};
                    let ids = [];
                    for (let t = 0; t < response.data.length; t++) {
                        data[response.data[t].Id + ''] = response.data[t];
                        ids.push(response.data[t].Id);
                    }

                    if (me._isMounted) {
                        me.setState({
                            data,
                            ids,
                            currentSort: sort
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchMembers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Fragment>
                <TenantUserFamilyGrid
                    handleRefresh={this.fetchMembers}
                    currentSort={this.state.currentSort}
                    setSort={this.setSort}
                    ids={this.state.ids}
                    data={this.state.data}
                    record={{Id: this.props.record[this.props.source]}}
                    basePath={this.props.basePath}
                    fetchTheData={this.props.fetchTheData}
                />
            </Fragment>
        );
    }
}

export default TenantUserFamilyList;