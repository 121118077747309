import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField
} from 'react-admin';
import {exporter} from "../../../../helpers";
import {FeesListFilter} from './FeesListFilter';
import SplitButton from '../../../../common/containers/SplitButton';

export const FeesList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Fees List</h1>
        <List filters={<FeesListFilter />} {...props} exporter={exporter} title=' ' sort={{field: 'id', order: 'DESC'}} bulkActionButtons={false}>
            <Datagrid>
                <TextField source='Name' label="Name" />
                <TextField source='FeeType' label="Fee Type" />
                <FunctionField source='Amount' label="Amount" render={record => ('$ ' + record.Amount)} />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonTdSmall'
                    successMessage='Fee deleted'
                    errorMessage='Fee NOT deleted'
                    confirmTitle='Delete the fee'
                    confirmContent='Are you sure you want to delete the fee?'
                />
            </Datagrid>
        </List>
    </div>
);

