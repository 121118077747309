import {connect} from 'react-redux';
import ResetPasswordButton from '../components/ResetPasswordButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    ResetPasswordButton
);