import {connect} from 'react-redux';
import TeacherSplitButton from '../components/TeacherSplitButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    TeacherSplitButton
);