import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {TeacherForm} from '../';
import {BackButton} from '../../../common';


const TeacherCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <h1>Create Teacher</h1>
            <Create {...props} title=" ">
                <TeacherForm style={{marginTop: 0}} />
            </Create>
        </Fragment>
    );
};

export default TeacherCreate;
