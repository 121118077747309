import React, {Component, Fragment} from 'react';
import {
    EditButton,
    GET_ONE,
    ListButton
} from 'react-admin';
import {fetchIt, capitalize} from "../../../helpers";
import {DeleteButton} from '../../../common';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import DetailsContent from './DetailsContent';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import SendActivationEmailButton  from '../../tenants/users/containers/SendActivationEmailButton';
import ResetPasswordButton  from '../../tenants/users/containers/ResetPasswordButton';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import {Redirect} from 'react-router';


const innerTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        "fontFamily": "\"proxima-nova\", sans-serif",
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontWeight: 500,
                color: '#9f9f9f',
                fontSize: 14,
                lineHeight: 1.57
            },

        },

        MuiInputLabel: {
            shrink: {
                transform: 'none'
            }
        },
    }
});


const styles = ({
    lightGrey: {
        color: "#9f9f9f"
    },

    detailsTopContainer: {
        display: "flex",
        justifyContent: "space-between"
    },

    leftColumnTitle: {
        marginTop: 25
    },

    backToList: {
        textTransform: "capitalize !important",
        fontSize: "16px !important",
        color: "#9f9f9f !important"
    }
});

export const UserActionButton = props => {
    return(
        <Fragment>
            {
                props.IsActive ?
                    <ResetPasswordButton record={props.record} />
                :
                    <SendActivationEmailButton record={props.record} />
            }
        </Fragment>
    );
}

export const DetailsTitle = ({ record, options }) => {
    // let detailsTitle = 'item';
    // if (options && options.detailsTitle) {
    //     detailsTitle = options.detailsTitle;
    // }
    let titleField = 'Name';
    if (options && options.titleField) {
        titleField = options.titleField;
    }

    let lastNameField = 'Name';
    if (options && options.lastNameField) {
        lastNameField = options.lastNameField;
    }

    return  (
        <Fragment>
            {
                options && options.lastNameField ?
                    <Typography variant="h4" component="h1" style={{marginBottom: '0.1em'}}>
                        {record[lastNameField] ? record[titleField] + " " + record[lastNameField] : ''}
                    </Typography>
                :
                    <Typography variant="h4" component="h1" style={{marginBottom: '0.1em'}}>
                        {record[titleField] ? record[titleField] : ''}
                    </Typography>
            }
        </Fragment>
    );
};


export const DetailsActions = ({ basePath, data, resource, options }) => {

    if(data.IsDeleted) {
        return null;
    }

    let detailsTitle = 'item';
    if (options && options.detailsTitle) {
        detailsTitle = options.detailsTitle;
    }

    return (
        <div style={{display: "flex", alignItems: "flex-end"}}>
            <EditButton basePath={basePath} record={data}/>
            {
                options && options.removeDeleteButton === true ?
                null :
                <DeleteButton
                    basePath={basePath}
                    record={data}
                    resource={resource}
                    successMessage={capitalize(detailsTitle) + ' deleted'}
                    errorMessage={capitalize(detailsTitle) + ' NOT deleted'}
                    confirmTitle={'Delete the ' + detailsTitle}
                    confirmContent={'Are you sure you want to delete the ' + detailsTitle + '?'}
                />
            }

            {
                options && options.UserActionButton ?
                    <UserActionButton IsActive={data.IsActive} record={data} />
                :
                    null
            }

        </div>
    );
};


class DetailsLayout extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            record: {},
            isLoading: true,
            redirectTo: null,
            refresh: () => {this.fetchTheData()}
        };
    }


    fetchTheData = detailsId => {
        const { fetchStart, fetchEnd, showNotification } = this.props;
        const me = this;

        if(!detailsId) {
            detailsId = this.props.id;
        }
        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        const resource = me.props.options.resource || this.props.resource;

        fetchIt(
            GET_ONE,
            resource,
            {id: detailsId}
        ).then(response => {
            switch (response.status) {
                case 200:
                    // Dispatch an action letting react-admin know an API call has ended
                    fetchEnd();

                    if (me.props.fieldsToFormat) {
                        // eslint-disable-next-line
                        for (const fieldToFormat of me.props.fieldsToFormat) {
                            if (response.data[fieldToFormat.field]) {
                                response.data[fieldToFormat.field] = fieldToFormat.formatter(response.data[fieldToFormat.field]);
                            }
                        }
                    }

                    if (me._isMounted) {
                        me.setState({
                            record: {...response.data},
                            isLoading: false
                        });
                    }

                    if (me.props.options.indexToFetch) {

                        let indexToFetch = [];

                        if(Array.isArray(me.props.options.indexToFetch)) {
                            indexToFetch = me.props.options.indexToFetch;
                        } else {
                            indexToFetch.push(me.props.options.indexToFetch);
                        }

                        for (let t = 0; t < indexToFetch.length; t++) {
                            // Dispatch an action letting react-admin know an API call is ongoing
                            fetchStart();

                            fetchIt(
                                GET_ONE,
                                indexToFetch[t],
                                {id: me.props.id}
                            ).then(theResponse => {
                                if (theResponse.status === 200) {
                                    if (me.props.options.fetchedFieldsToFormat && me.props.options.fetchedFieldsToFormat[t]) {
                                        // eslint-disable-next-line
                                        for (const fieldToFormat of me.props.options.fetchedFieldsToFormat[t]) {
                                            if (theResponse.data[fieldToFormat.field]) {
                                                theResponse.data[fieldToFormat.field] = fieldToFormat.formatter(theResponse.data[fieldToFormat.field]);
                                            }
                                        }
                                    }

                                    if (me._isMounted) {
                                        let state = me.state;
                                        state.record[indexToFetch[t]] = theResponse.data;
                                        me.setState({...state});
                                    }
                                }
                            }).catch(error => {

                            }).then(() => {
                                // Dispatch an action letting react-admin know an API call has ended
                                fetchEnd();
                            });
                        }
                    }

                    break;

                case 403:
                    if (response.data.ErrorMessage) {
                        showNotification(
                            response.data.ErrorMessage,
                            'warning',
                            { messageArgs: { _: response.data.ErrorMessage } }
                        );
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchTheData();
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    renderChildren() {
        const {classes, ...props} = this.props;
        const state = this.state;

        if (!Array.isArray(props.children)) {
            let fetchTheData = "";

            if(props.children.props.needsTheFetcher) {
                fetchTheData = this.fetchTheData;
            }

            return (
                <DetailsContent
                    {...props}
                    record={state.record}
                    isLoading={state.isLoading}
                    sideTitle={props.children.props.sideTitle || ''}
                    sideText={props.children.props.sideText || ''}
                    fetchTheData={fetchTheData}
                >
                    {props.children.props.children}
                </DetailsContent>
            );
        }

        let showServiceInfo = true;

        let key = 0;

        return props.children.map((child) => {
            key++;
            let withServiceInfo = false;
            const {CreatedTime, CreatedBy, ModifiedTime, ModifiedBy, ...withoutServiceInfo} = state.record;

            if (showServiceInfo) {
                withServiceInfo = state.record;
                showServiceInfo = false;
            }

            if (
                child.props.recordObject &&
                (!state.record.hasOwnProperty(child.props.recordObject) || 
                !state.record[child.props.recordObject]) &&
                !child.props.passComponent
            ) {
                return (
                    <DetailsContent
                        key={key}
                        {...props}
                        record={withServiceInfo ? withServiceInfo : withoutServiceInfo}
                        isLoading={state.isLoading}
                        sideTitle={child.props.sideTitle || ''}
                        sideText={child.props.sideText || ''}
                    >
                        <Fragment>
                            <div className="NoDataLabel">
                                No data.
                            </div>
                        </Fragment>
                    </DetailsContent>
                )
            }

            let fetchTheData = "";

            if(child.props.needsTheFetcher) {
                fetchTheData = this.fetchTheData;
            }

            return (
                <DetailsContent
                    key={key}
                    {...props}
                    record={withServiceInfo ? withServiceInfo : withoutServiceInfo}
                    isLoading={state.isLoading}
                    sideTitle={child.props.sideTitle || ''}
                    sideText={child.props.sideText || ''}
                    fetchTheData={fetchTheData}
                >
                    {child.props.children}
                </DetailsContent>
            );
        });
    }

    render() {
        const {classes} = this.props;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <MuiThemeProvider theme={innerTheme}>
                <div className="wrapperContent">
                    <Grid container spacing={2} className='labeled-value'>
                        <Grid item xs={12}>
                            {
                                this.props.options && this.props.options.removeListButton ?
                                null :
                                <ListButton
                                    basePath={this.props.basePath}
                                    icon={<ArrowBack style={{fontSize: 16, marginRight: '-0.5em'}} />}
                                    label='Back'
                                    className={classes.backToList}
                                />
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.detailsTopContainer}>
                                <DetailsTitle record={this.state.record} options={this.props.options} />
                                <DetailsActions
                                    basePath={this.props.basePath}
                                    data={this.state.record}
                                    resource={this.props.resource}
                                    options={this.props.options}
                                />
                            </div>
                            <Divider />
                        </Grid>
                        {this.renderChildren()}
                    </Grid>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(DetailsLayout);
