import React, {Component} from 'react'
import {itemTypes} from './config/constants'
import {DropTarget} from 'react-dnd'
import moment from 'moment'
import {inArray} from './helpers';
import Row from './Row';


class DroppableTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            refreshRows: []
        };
    }

    handleDrop = event => {
        if (event.nativeEvent) {
            event.persist();
        }

        const item = this.props.item;
        const pixelsPerMinute = 180 / 60;
        let dropZone = event.target;
        if (dropZone.dataset.getParent) {
            while (dropZone.dataset.getParent) {
                dropZone = dropZone.parentElement;
            }
        }

        const dataSet = dropZone.dataset;
        const dropZoneX = dropZone.getBoundingClientRect().x;
        const slotDuration = 60 / parseInt(dataSet.targetSlots);
        const mouseDragOffset = item.mouseX - item.itemX;
        const mouseDragOffsetInMinutes = mouseDragOffset / pixelsPerMinute;
        const mouseOffset = event.clientX - dropZoneX;
        const mouseOffsetInMinutes = mouseOffset / pixelsPerMinute;
        const dropZoneTime = moment.utc(dataSet.timeSlot, 'h a').add(
            slotDuration * parseInt(dataSet.timeSubslot) + mouseOffsetInMinutes - mouseDragOffsetInMinutes,
            'minutes'
        );

        const minutes = parseInt(dropZoneTime.format('m'));

        const targetSlot = Math.floor(minutes / slotDuration);
        const targetSlotMinutes = targetSlot * slotDuration;
        const newScheduleTime = moment.utc(dropZoneTime.format('h a'), 'h a').add(targetSlotMinutes, 'minutes');
        // console.log('Dropped Item: ', item);
        // console.log('newScheduleTime: ', newScheduleTime.format());

        this.props.moveSchedule(
            item.id,
            parseInt(newScheduleTime.format('H')),
            parseInt(newScheduleTime.format('m')),
            item.schedule.DayOfWeek,
            dataSet.weekday,
            parseInt(item.schedule.Studio),
            parseInt(dataSet.resource)
        );
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            !this.state ||
            (nextProps && nextProps.refresh && inArray(this.props.id, nextProps.refresh)) ||
            (nextProps.didDrop && this.props.isOver)
        ) {
            if (nextProps && nextProps.refresh && inArray(this.props.id, nextProps.refresh)) {
                let newRefresh = [];
                for (let t = 0; t < nextProps.refresh.length; t++) {
                    if (nextProps.refresh[t] !== this.props.id) {
                        newRefresh.push(nextProps.refresh[t]);
                    }
                }

                if (!nextProps.refreshRows || (nextProps.refreshRows.length === 0)) {
                    let refreshRows = [];
                    for (let resource of this.props.resources) {
                        refreshRows.push(this.props.weekday + '_' + resource.id);
                    }

                    if (this._isMounted) {
                        this.setState({refreshRows});
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({refreshRows: nextProps.refreshRows});
                    }
                }

                this.props.stopRefreshing(newRefresh);
            }

            return true;
        }

        return false;
    }


    componentDidMount() {
        this._isMounted = true;
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return this.props.connectDropTarget(
            <table
                onDrop={item => this.handleDrop(item)}
                style={{
                    zIndex: 2,
                    borderSpacing: 0,
                    borderCollapse: 'collapse'
                }}
            >
                <tbody>
                {
                    this.props.resources.map((resource, resKey) => {
                        return (
                            <Row
                                key={resKey}
                                id={this.props.weekday + '_' + resource.id}
                                weekday={this.props.weekday}
                                resource={resource}
                                timeSubSlotsRender={this.props.timeSubSlotsRender}
                                refresh={this.props.refreshRows}
                                rowsHeight={this.props.rowsHeight}
                            />
                        );
                    })
                }
                </tbody>
            </table>
        )

    }

}

export default DropTarget(
    itemTypes.SCHEDULE,
    {
        drop: () => ({name: 'DroppableTable' + (new Date().getTime())}),
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        didDrop: monitor.didDrop(),
        clientOffset: monitor.getClientOffset(),
        item: monitor.getItem(),
    }),
)(DroppableTable)
