import React, {Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {classTypeChoices} from '../../../config/constants';

import {
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    required,
    NumberInput,
    FormDataConsumer,
    GET_ONE
} from 'react-admin';
import FormHelperText from "@material-ui/core/FormHelperText";
import AdagioAutoComplete from "../../../components/AdagioAutoComplete";
import {useForm, useFormState} from 'react-final-form';
import {fetchIt} from "../../../helpers";
import moment from 'moment';
import ScheduleDatesInput from "../containers/ScheduleDatesInput";
import ScheduleDaysOfWeekInput from "../containers/ScheduleDaysOfWeekInput";
import SchedulePricesInput from "../containers/SchedulePricesInput";
import {Grid} from '@material-ui/core';


const acOptionRenderer = choice => {
    return choice ? `${choice.Name}` : '';
};

const useStyles = makeStyles(theme => ({
    scheduleInnerForm: {
        position: 'relative',
        height: 395,
        backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: 20,
        zIndex: 0,
        height: 430,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto'
    },
    tabPanelActive: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: 20,
        zIndex: 10,
        height: 430,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto'
    },
}));

const ScheduleFormView = formProps => {
    const [defaultSemester, setDefaultSemester] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);
    const form = useForm();
    const formState = useFormState();
    const firstTab = useRef(null);
    const classes = useStyles();
    const {fetchStart, fetchEnd, showNotification} = formProps;

    const courseField = form.getFieldState('Course');
    const courseFieldTouched = courseField ? courseField.touched : false;
    const courseFieldValue = courseField ? courseField.value : undefined;
    const propsForceUpdate = formProps.forceUpdate;


    useEffect(() => {
        if (forceUpdate !== propsForceUpdate) {
            setForceUpdate(propsForceUpdate);
        }
    }, [forceUpdate, propsForceUpdate]);


    useEffect(() => {
        if (!courseFieldValue && (courseFieldTouched || (formProps.activeTab > 0))) {
            formProps.setFirstTabHasErrors(true);
        } else {
            formProps.setFirstTabHasErrors(false);
        }
    }, [courseField, courseFieldTouched, courseFieldValue, formProps.activeTab]); // eslint-disable-line


    useLayoutEffect(() => {
        if (!courseFieldValue && !courseFieldTouched && (formProps.activeTab === 1)) {
            form.focus('Course');
            form.blur('Course');
        }
    }, [courseField, courseFieldTouched, courseFieldValue, formProps.activeTab]); // eslint-disable-line


    let schoolYear = moment().format('YYYY');
    if (formProps.scheduleFilters && formProps.scheduleFilters.SchoolYear) {
        schoolYear = parseInt(formProps.scheduleFilters.SchoolYear);
    }


    let filteredSemester = 0;
    if (formProps.scheduleFilters && formProps.scheduleFilters.SemesterId) {
        filteredSemester = parseInt(formProps.scheduleFilters.SemesterId);
    }


    useEffect(() => {
        if (formState.values.SchoolYear && filteredSemester && (defaultSemester !== filteredSemester)) {
            form.change('Semesters', [filteredSemester]);
            setDefaultSemester(filteredSemester);
        }
    }, [formState.values.SchoolYear, filteredSemester]); // eslint-disable-line


    const semesters = formState.values.Semesters;
    const course = formState.values.Course;
    const places = formState.values.Places;
    const scheduleDaysOfWeek = formState.values.ScheduleDaysOfWeek;
    const ssdErrorsLength = Object.keys(formProps.ssdErrors).length;

    useEffect(() => {
        formProps.setForm(form);

        if ((typeof semesters !== 'undefined') && (semesters.length > 0)) {
            formProps.allowStep(1);

            if (
                (typeof course !== 'undefined') &&
                (parseInt(course) > 0) &&
                (typeof places !== 'undefined') &&
                (parseInt(places) > 0) &&
                (ssdErrorsLength === 0)
            ) {
                formProps.allowStep(2);

                if ((typeof scheduleDaysOfWeek !== 'undefined') && (scheduleDaysOfWeek.length > 0)) {
                    formProps.allowStep(4);
                } else {
                    formProps.disallowStep(3);
                }
            } else {
                formProps.disallowStep(2);
            }
        } else {
            formProps.disallowStep(1);
        }
    }, [semesters, course, places, scheduleDaysOfWeek, ssdErrorsLength]); // eslint-disable-line


    const onCourseChange = value => {
        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'courses',
            {id: value}
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (response && response.data && response.data.Places) {
                        form.change('Places', response.data.Places);
                        firstTab.current.focus();
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    return (
        <form className={classes.scheduleInnerForm}>
            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-0`}
                className={formProps.activeTab === 0 ? classes.tabPanelActive : classes.tabPanel}
            >
                <Grid container spacing={5}>
                    <Grid item lg={3} md={6} xs={12}>
                        <SelectInput
                            source="SchoolYear"
                            defaultValue={schoolYear}
                            choices={formProps.schoolYears}
                            style={{width: '100%'}}
                        />
                    </Grid>

                    <Grid item lg={9} md={6} xs={12}>
                        <FormDataConsumer>
                            {
                                ({formData}) =>
                                    <Fragment>
                                        <ReferenceArrayInput
                                            label='Sessions *'
                                            source='Semesters'
                                            resource='semesters'
                                            reference='semesters'
                                            sort={{field: 'Name', order: 'ASC'}}
                                            filter={{IsActive: true, SchoolYear: formData.SchoolYear}}
                                            perPage={1000}
                                            validate={required('The Sessions field is required')}
                                        >
                                            <SelectArrayInput
                                                optionText='Name'
                                                style={{width: '100%'}}
                                            />
                                        </ReferenceArrayInput>
                                        <FormHelperText>You can choose more than one session</FormHelperText>
                                    </Fragment>
                            }
                        </FormDataConsumer>
                    </Grid>

                    <Grid item lg={3} md={6} xs={12}>
                        <SelectInput
                            source="ClassType"
                            choices={classTypeChoices}
                            defaultValue={1}
                            validate={required('The Class Type field is required')}
                            style={{width: '100%'}}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <FormDataConsumer>
                            {
                                ({formData}) =>
                                    <Fragment>
                                        <ReferenceInput
                                            required
                                            label="Select a course"
                                            source="Course"
                                            reference="courses"
                                            sort={{field: 'Name', order: 'ASC'}}
                                            filter={{IsActive: true, ClassType: formData.ClassType}}
                                            perPage={1000}
                                            validate={required('The Course field is required')}
                                        >
                                            <AdagioAutoComplete
                                                label="Select a course *"
                                                optionRenderer={acOptionRenderer}
                                                onChange={onCourseChange}
                                                fullwidth
                                            />
                                        </ReferenceInput>
                                        <FormHelperText>Start typing a course and select from suggested results</FormHelperText>
                                    </Fragment>
                            }
                        </FormDataConsumer>
                    </Grid>

                    <Grid item lg={3} md={6} xs={12}>
                        <NumberInput
                            source="Places"
                            inputProps={{
                                min: 1,
                                ref: firstTab
                            }}
                            defaultValue={1}
                            format={v => parseInt(v) >= 0 ? parseInt(v) : ' '}
                            parse={v => parseInt(v) > 0 ? parseInt(v) : 1}
                            validate={required('The Places field is required')}
                        />
                    </Grid>
                </Grid>
            </div>


            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-1`}
                className={formProps.activeTab === 1 ? classes.tabPanelActive : classes.tabPanel}
            >
                <ScheduleDatesInput
                    source='ScheduleStartDates'
                    errors={formProps.ssdErrors}
                    setErrors={formProps.setSsdErrors}
                    updateParent={formProps.updateParent}
                />
            </div>

            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-2`}
                className={formProps.activeTab === 2 ? classes.tabPanelActive : classes.tabPanel}
            >
                <ScheduleDaysOfWeekInput
                    source='ScheduleDaysOfWeek'
                    eventInitialData={formProps.eventInitialData}
                    errors={formProps.errors}
                    setErrors={formProps.setErrors}
                    forceUpdate={forceUpdate}
                    updateParent={formProps.updateParent}
                />
            </div>

            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-3`}
                className={formProps.activeTab === 3 ? classes.tabPanelActive : classes.tabPanel}
            >
                <ReferenceArrayInput
                    label='Fees'
                    source='Fees'
                    resource='fees'
                    reference='fees'
                    filter={{IsActive: true}}
                    perPage={1000}
                >
                    <SelectArrayInput
                        optionText='Name'
                        style={{width: '100%'}}
                    />
                </ReferenceArrayInput>
                <FormHelperText>You can choose more than one fee</FormHelperText>
            </div>

            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-4`}
                className={formProps.activeTab === 4 ? classes.tabPanelActive : classes.tabPanel}
            >
                <SchedulePricesInput
                    activeTab={formProps.activeTab}
                    setForm={formProps.setForm}
                />
            </div>
        </form>
    );
};

export default ScheduleFormView;
