import {required} from "react-admin";
import moment from 'moment';

export const startEndDateSequenceValidation = (message = 'Ends At date should be after Starts At date') =>
    (value, allValues) => {
        let start = allValues.start;
        if (typeof allValues.start === 'string') {
            start = moment(allValues.start);
        }

        let end = allValues.end;
        if (typeof allValues.end === 'string') {
            end = moment(allValues.end);
        }

        return (start && end && !end.isAfter(start)) ? message : undefined;
    };

export const startsAtValidation = [
    required('The Starts at field is required'),
    startEndDateSequenceValidation()
];

export const endsAtValidation = [
    required('The Ends at field is required'),
    startEndDateSequenceValidation()
];
