import React, { Component, Fragment } from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    BooleanInput,
    FormDataConsumer,
} from 'react-admin';
import { fetchIt } from "../../../../helpers/fetcher";
import { getErrorMessage } from "../../../../helpers";
import { Redirect } from 'react-router';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { PhoneMaskedInput } from '../../../../common';
import { emailValidation } from '../../../../validators';
import { phoneValidation } from '../../../../validators';
import { formatPhone } from "../../../../helpers";
import {NtaSaveButton} from '../../../common';

export const StaffFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

export const StaffFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

export const AddressHeading = () => (
    <h2>
        Address
    </h2>
);

class StaffForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }
        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        fetchIt(
            requestType,
            'staff',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Staff saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    values.CellPhone = formatPhone(values.CellPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.CellPhone = formatPhone(values.CellPhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <SimpleForm
                            resource={this.props.resource}
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<StaffFormToolbar isDisabled={this.state.isDisabled} />}
                        >
                            <TextInput source="FirstName" className='fieldsWidthExpand' validate={required('The First Name field is required')} />
                            <TextInput source="LastName"className='fieldsWidthExpand' validate={required('The Last Name field is required')} />
                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return <StaffFormTitle title={formData.FirstName + " " + formData.LastName} />;
                                    }
                                }
                            </FormDataConsumer>
                            <PhoneMaskedInput
                                required
                                source='CellPhone'
                                validate={phoneValidation}
                                className='fieldsWidthExpand'
                            />
                            <TextInput type='email' source='Email'className='fieldsWidthExpand' validate={emailValidation} />
                            <BooleanInput source='IsActive'  defaultValue={this.props.editMode ? '' : true} />
                        </SimpleForm>
                    </CardContent> 
                 </Card>
            </Fragment>
        );
    }
}

export default StaffForm;