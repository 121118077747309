import React from 'react';
import {
    Show,
    SimpleShowLayout,
    RichTextField,
    FunctionField
} from 'react-admin';


export const NotificationsListMessageShow = props => (
        <Show
            {...props}
            title=" "
        >
            <SimpleShowLayout>
                {props.record.Message ?
                    <RichTextField source="Message" />
                    :
                    <FunctionField label="Message" render={record => "Not specified"} />
                }
            </SimpleShowLayout>
        </Show>
    );
