import {required, regex} from "react-admin";

export const positiveNumberValidation = (message = 'This field should be a positive number') =>
    (value, allValues) => value >= 0 ? undefined : message;

export const maximum720Validation = (message = 'This field can not exceed 720') =>
    (value, allValues) => parseInt(value) <= 720 ? undefined : message;

export const requiredPositiveNumberValidation = [
    required('This field is required'),
    regex(/^[-+]?[0-9]*\.?[0-9]+$/, 'This field should be a number'),
    positiveNumberValidation('This field should be a positive number')
];

export const durationValidation = [
    required('The Duration field is required'),
    regex(/^\d+$/, 'The Duration field should be a number'),
    positiveNumberValidation('The Duration field should be a positive number'),
    maximum720Validation('The Duration field can not exceed 720 minutes')
];

