import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Confirm, useNotify, useRedirect, useRefresh, useDelete} from 'react-admin';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {withStyles, createStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {fade} from '@material-ui/core/styles/colorManipulator';


const sanitizeRestProps = ({
                               basePath,
                               classes,
                               dispatch,
                               filterValues,
                               handleSubmit,
                               handleSubmitWithRedirect,
                               invalid,
                               label,
                               pristine,
                               resource,
                               saving,
                               selectedIds,
                               submitOnEnter,
                               redirect,
                               onClick,
                               dataProvider,
                               successMessage,
                               errorMessage,
                               bulkSuccessMessage,
                               bulkErrorMessage,
                               confirmTitle,
                               confirmContent,
                               ...rest
                           }) => rest;

const styles = theme => createStyles({
    deleteButton: {
        // color: theme.palette.error.main,
        color: '#f44336 !important',
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
});


const DeleteButton = props => {
    const [isOpen, setIsOpen] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [deleteIt, {loading}] = useDelete(
        props.resource,
        props.record ? props.record.id : 0,
        {},
        {
            undoable: false,
            onSuccess: response => {
                const {
                    basePath,
                    successMessage,
                } = props;

                redirect(basePath);
                refresh(true);

                switch (response.status) {
                    case 200:
                        redirect(basePath);
                        refresh(true);
                        notify('direct_message', 'info', {_: successMessage}, false);

                        break;

                    case 400:
                        refresh(true);
                        let message = props.errorMessage;
                        if (response.data.ErrorMessage && (response.data.ErrorMessage.length > 0)) {
                            message = response.data.ErrorMessage;
                        }
                        notify('direct_message', 'warning', {_: message});

                        break;

                    default:
                        notify('direct_message', 'warning', {_: 'Oops, something went wrong!'});
                }
            },
            onFailure: error => {
                notify('direct_message', 'warning', {_: error.message});
            },
        }
    );


    // const [deleteThem, restOfIt] = useDeleteMany(
    //     props.resource,
    //     props.selectedIds ? props.selectedIds : [],
    //     {},
    //     {
    //         undoable: false,
    //         onSuccess: response => {
    //             const {
    //                 basePath,
    //                 bulkSuccessMessage,
    //             } = props;
    //
    //             redirect(basePath);
    //             refresh(true);
    //
    //             switch (response.status) {
    //                 case 200:
    //                     redirect(basePath);
    //                     refresh(true);
    //                     notify(bulkSuccessMessage, 'info', {}, true);
    //
    //                     break;
    //
    //                 case 400:
    //                     let message = props.bulkErrorMessage;
    //                     if (response.data.ErrorMessage && (response.data.ErrorMessage.length > 0)) {
    //                         message = response.data.ErrorMessage;
    //                     }
    //                     notify('direct_message', 'warning', {_: message});
    //                     refresh(true);
    //
    //                     break;
    //
    //                 default:
    //                     notify('Oops, something went wrong!', 'warning', {});
    //             }
    //         },
    //         onFailure: error => {
    //             console.log('MY ERROR: ', error);
    //             notify('direct_message', 'warning', {_: error.message});
    //         },
    //     }
    // );


    const handleClick = e => {
        e.stopPropagation();

        setIsOpen(true);
    };


    const handleDialogClose = () => {
        setIsOpen(false);
    };


    const handleDelete = () => {
        handleDialogClose();

        if (props.selectedIds) {
            // deleteThem();
        } else if (props.record) {
            try {
                deleteIt();
            } catch (e) {
                console.log(e);
            }
        }
    };


    return (
        <Fragment>
            <Button
                onClick={handleClick}
                label="Delete"
                className={classnames(
                    'nta-delete-button',
                    props.classes.deleteButton,
                    props.className
                )}
                key="button"
                disabled={loading}
                {...sanitizeRestProps(props)}
            >
                <DeleteIcon/>
            </Button>
            <Confirm
                isOpen={isOpen}
                title={props.confirmTitle}
                content={props.confirmContent}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

DeleteButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    bulkSuccessMessage: PropTypes.string,
    bulkErrorMessage: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string
};

DeleteButton.defaultProps = {
    successMessage: 'Element deleted',
    errorMessage: 'Element NOT deleted',
    bulkSuccessMessage: 'Elements deleted',
    bulkErrorMessage: 'Elements NOT deleted',
    confirmTitle: 'Delete element',
    confirmContent: 'Are you sure you want to delete this element?'
};

export default withStyles(styles)(DeleteButton);
