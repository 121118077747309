import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    downloadCSV
} from 'react-admin';
import {UsersListFilters} from '../../';
import {DeleteButton} from '../../../../common';
import {formatPhone} from '../../../../helpers';
import UserSplitButton  from '../containers/UserSplitButton';
import jsonExport from 'jsonexport/dist';


export const UsersBulkActionButtons = props => (
    <Fragment>
        <DeleteButton
            {...props}
            bulkSuccessMessage='Users deleted'
            bulkErrorMessage='Users NOT deleted'
            confirmTitle='Delete selected users'
            confirmContent='Are you sure you want to delete the selected users?'
        />
    </Fragment>
);


export const exporter = records => {
    const headers = ['Id', 'FirstName', 'LastName', 'Email', 'CellPhone', 'Status', 'AreNotificationsEnabled'];

    const data = records.map(record => {
        return ({
            Id: record.Id !== null ? record.Id : '',
            FirstName: record.FirstName !== null ? record.FirstName : '',
            LastName: record.LastName !== null ? record.LastName : '',
            Email: record.Email !== null ? record.Email : '',
            CellPhone: record.CellPhone !== null ? formatPhone(record.CellPhone) : '',
            Status: record.IsActive ? 'Active' : 'Inactive',
            AreNotificationsEnabled: record.AreNotificationsEnabled ? 'Yes' : 'No',
        });
    });

    jsonExport(data, {headers}, (err, csv) => {
        downloadCSV(csv, 'CustomersList');
    });
};



export const isDeletedRowStyle = (record) => ({
    backgroundColor: record.IsDeleted ? '#eaeaea' : 'white',
});


export const UsersList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Customers List</h1>
        <List
            filters={<UsersListFilters />}
            {...props}
            title=' '
            bulkActionButtons={false}
            exporter={exporter}
            sort={{field: 'id', order: 'DESC'}}
        >
            <Datagrid rowStyle={isDeletedRowStyle}>
                <FunctionField
                    label='Name'
                    render={record => `${record.FirstName} ${record.LastName}`}
                    sortBy="FirstName"
                />
                <TextField source='Email' />
                <FunctionField
                    label='Phone'
                    render={record => {return `${record.CellPhone}` === 'null' ? '' : formatPhone(`${record.CellPhone}`)}}
                />
                <FunctionField
                    label='Status'
                    source='IsActive'
                    render={
                        record => {
                            return record.IsDeleted ?
                                "Deleted"
                                :
                                record.IsActive ? 'Active' : 'Inactive'
                        }
                    }
                />
                <TextField source='Role' />
                <UserSplitButton  cellClassName='splitButtonRight'/>
            </Datagrid>
        </List>
    </div>
);
