import React, {Component, Fragment} from 'react';
import {GET_ONE} from 'react-admin';
import StaffForm from '../containers/StaffForm';
import {fetchIt} from "../../../../helpers/fetcher";
import {formatPhone} from "../../../../helpers";
import {BackButton} from '../../../../common';
import {Redirect} from 'react-router';


export const StaffTitle = () => {
    return <h1 id="the-title"> </h1>;
};

class StaffEdit extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            record: {},
            redirectTo: null
        };
    }


    componentDidMount() {
        this._isMounted = true;
        const { fetchStart, fetchEnd, showNotification } = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'staff',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    response.data.CellPhone = formatPhone(response.data.CellPhone);

                    if (me._isMounted) {
                        me.setState({
                            record: {...response.data}
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <BackButton />
                <StaffTitle record={this.state.record} />
                <StaffForm record={this.state.record} {...this.props} editMode={true}/>
            </Fragment>
        );
    }
}
export default StaffEdit;
