import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import {connect} from "react-redux";
import TeachersAssignmentsList from '../components/TeachersAssignmentsList';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    TeachersAssignmentsList
);
