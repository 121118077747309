import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {UserForm} from '../../';
import {BackButton} from '../../../../common';


const UserCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <h1>Create User</h1>
            <Create {...props} title=" ">
                <UserForm style={{marginTop: 0}} />
            </Create>
        </Fragment>
    );
};

export default UserCreate;
