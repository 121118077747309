import React, {useState} from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";


export const TeacherAssignmentsListFilters = props => { 
    const [schoolYear, setSchoolYear] = useState('0');
    const [semester, setSemester] = useState('0');

    const onSchoolYearChange = (event) => {
        event.persist();
        setSchoolYear(event.target.value);

        let filters = {};
        if(parseInt(event.target.value)){
            filters.SchoolYear = parseInt(event.target.value);
        }

        delete filters.SemesterId;

        setSemester('0');

        props.fetchAssignments(filters);
        props.fetchSemesters({"SchoolYear": parseInt(event.target.value)});
    };

    const onSemesterChange = (event) => {
        event.persist();
        setSemester(event.target.value);

        let filters = {};
        if(parseInt(event.target.value)){
            filters.SemesterId = parseInt(event.target.value);
        }

        if(parseInt(schoolYear)){
            filters.SchoolYear = parseInt(schoolYear);
        }

        props.fetchAssignments(filters);
    };

    const currentSchoolYear = moment().format('YYYY');

    if(!parseInt(schoolYear)){
        setSchoolYear(currentSchoolYear);
    }

    return(
        <div style={{marginBottom: 15, display: 'inline-block'}}>
            <div style={{float: 'left', marginRight: 50}}>
                <InputLabel id="School-Years">School Years</InputLabel>
                <Select
                    label='School Years'
                    onChange={onSchoolYearChange}
                    value={schoolYear}
                    style={{width: 180}}
                >
                    {props.schoolYears.map(schoolYear => <MenuItem key={schoolYear.id} value={schoolYear.id}>{schoolYear.name}</MenuItem>)}
                </Select>
            </div>
            <div style={{float: 'left'}}>
                <InputLabel id="semester">Semester</InputLabel>
                <Select
                    label='Semesters'
                    onChange={onSemesterChange}
                    value={semester}
                    style={{width: 300}}
                >
                    <MenuItem value="0">All</MenuItem>
                    {props.semesters.map(semesterObject => <MenuItem key={semesterObject.id} value={semesterObject.id}>{semesterObject.name}</MenuItem>)}
                </Select>
            </div>
        </div>
    )
};