import React from 'react';
import {
    Filter,
    TextInput
} from 'react-admin';


export const TeachersListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by Name & Email' source='search' alwaysOn style={{width: 300}}/>
    </Filter>
);
