import React, {Component, Fragment} from 'react';
import {TextField, FunctionField, Datagrid, ShowButton} from 'react-admin';
import {formatPhone} from "../../../../helpers";
import {UserFamilyDetails} from './UserFamilyDetails';
import CreateFamilyMemberButton from '../containers/CreateFamilyMemberButton';


export const FamilyMemberShowButton = props => {
    const {handleRefresh, fetchTheData, ...other} = props;
    let fetchTheDataHandler = fetchTheData;

    if(typeof fetchTheDataHandler !== 'function') {
        fetchTheDataHandler = () => {};
    }

    return(
        <ShowButton
            {...other}
            onClick={() => {fetchTheDataHandler(props.record.id); handleRefresh(null, props.record.id);}}
            label="User Details"
            basePath='/tenant_users'
        />
    );
};

class TenantUserFamilyGrid extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            showDialog: false,
            tenantFamilyMemberData: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDialogOpen = (e, record) => {
        e.preventDefault();

        if (record.CellPhone) {
            record.CellPhone = formatPhone(record.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10));
        }

        if (record.HomePhone) {
            record.HomePhone = formatPhone(record.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10));
        }

        if (this._isMounted) {
            this.setState({showDialog: true, tenantFamilyMemberData: record});
        }
    };

    onDialogClose = () => {
        if (this._isMounted) {
            this.setState({showDialog: false});
        }
    };

    render() {
        const {handleRefresh, fetchTheData, ...other} = this.props;

        const dataGridProps = Object.assign({}, other);
        dataGridProps.resource = 'tenant_users';

        if(!this.props.data || (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object)){
            return(
                <Fragment>
                    <div style={{ position: 'absolute', right: 15, top: 25 }}>
                        <CreateFamilyMemberButton handleRefresh={handleRefresh} record={this.props.record}/>
                    </div>
                    <div className="NoDataLabel">
                        No data.
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <div style={{position: 'absolute', right: 15, top: 25}}>
                    <CreateFamilyMemberButton handleRefresh={handleRefresh} record={this.props.record}/>
                </div>
                <Datagrid expand={<UserFamilyDetails {...other} />} {...dataGridProps} record={this.props.record}>
                    <FunctionField
                        source='FirstName'
                        label="Name"
                        render={record => `${record.FirstName} ${record.LastName}`}
                        sortable={false}
                    />
                    <TextField source='Role' sortable={false}/>
                    <FunctionField
                        label='Status'
                        source='IsActive'
                        render={record => record.IsActive ? 'Active' : 'Inactive'}
                        sortable={false}
                    />

                    <FamilyMemberShowButton fetchTheData={fetchTheData} handleRefresh={handleRefresh}/>
                </Datagrid>
            </Fragment>
        );
    }
}

export default TenantUserFamilyGrid;
