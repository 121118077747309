import React, {Component, Fragment} from 'react'
import NeteaScheduler from 'netea-scheduler'
import {fetchIt, getErrorMessage, inArray} from "../../../helpers";
import {
    GET_LIST,
    UPDATE,
    DELETE
} from 'react-admin';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddNewIcon from '@material-ui/icons/Add';
// import ScheduleIcon from "@material-ui/icons/ChromeReaderMode";
import TeachersIcon from '@material-ui/icons/People';
import DurationIcon from '@material-ui/icons/Alarm';
import DeleteWholeIcon from '@material-ui/icons/DeleteSweep';
import DeleteIcon from '@material-ui/icons/Delete';
import {animation, contextMenu, Item, Menu, theme} from "react-contexify";
import ScheduleForm from '../containers/ScheduleForm';
import {Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import * as Moment from 'moment';
import {extendMoment} from 'moment-range';
import Popper from '@material-ui/core/Popper';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import {SchedulesListFilters} from './SchedulesListFilters';


const moment = extendMoment(Moment);


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h3">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


export default class SchedulesList extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        let weekdays = [];
        for (let t = 1; t < 8; t++) {
            weekdays.push(moment(t, 'E').format('dddd'));
        }

        this.state = {
            weekdays,
            studios: [],
            schedules: [],
            filters: {IsActive: true},
            isLoading: true,
            showDialog: false,
            isDisabled: false,
            tooltip: false,
            event: {},
            tooltipPosition: {
                top: -500,
                left: -1000
            },
            tooltipWidth: 0,
            tooltipHeight: 0,
            slotWidth: 20,
            teachers: [],
            formIndex: 0,
            schoolYears: [],
            isDurationOpened: false,
            duration: 0,
            isTeachersOpened: false,
            scheduleTeachers: [],
            selectedSchedule: 0,
            selectedScheduleDataset: {},
            selectedParentSchedule: 0,
            openDeleteSdw: false,
            openDeleteWholeSchedule: false,
            schedulerMinTime: '05:00:00',
            schedulerMaxTime: '22:00:00',
            currentSchoolYear: moment().format('YYYY'),
            currentSemester: '0',
            semesters: [],
            refresh: [],
            refreshRows: [],
            refreshSchedules: [],
        };
    }


    fetchSchoolYears = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        const sort = {field: "Id", order: "DESC"};

        fetchIt(
            GET_LIST,
            'school_years',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schoolYears = [];

                    for (let t = 0; t < response.data.length; t++) {
                        schoolYears.push({id: response.data[t].Id, name: response.data[t].Name});
                    }

                    if (me._isMounted) {
                        me.setState({
                            schoolYears
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    fetchSemesters = filters => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'semesters',
            {filter: filters}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let semesters = [];
                    let currentSemester = response.data[0].Id;

                    for (let t = 0; t < response.data.length; t++) {
                        if (response.data[t].IsCurrent) {
                            currentSemester = response.data[t].Id;
                        }
                        semesters.push({id: response.data[t].Id, name: response.data[t].Name});
                    }

                    if (me._isMounted) {
                        me.setState({
                            currentSemester,
                            semesters
                        });
                    }

                    if(parseInt(currentSemester)){
                        filters.SemesterId = parseInt(currentSemester);
                    }

                    this.fetchTheData(filters);

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    fetchTeachers = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        const sort = {field: "Name", order: "ASC"};

        fetchIt(
            GET_LIST,
            'teachers',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (me._isMounted) {
                        me.setState({
                            teachers: response.data
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    componentDidMount() {
        this._isMounted = true;

        this.fetchTeachers();
        this.fetchSchoolYears();
        this.fetchSemesters({"SchoolYear": parseInt(moment().format('YYYY'))});
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    fetchTheData = filter => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        if(!filter){
            filter = this.state.filters;
        } else {
            let stateFilters = Object.assign({}, this.state.filters);
            filter = Object.assign(stateFilters, filter);
        }

        if (me._isMounted) {
            me.setState({
                isLoading: true,
                filters: filter
            });
        }

        fetchIt(
            GET_LIST,
            'studios',
            {filter: {IsActive: true}}
        ).then(response => {
            switch (response.status) {
                case 200:
                    fetchStart();

                    let studios = [];

                    // eslint-disable-next-line
                    for (const studio in response.data) {
                        const studioColor = response.data[studio].DisplayColor;
                        if (studioColor === '#FAFAFA') {
                            response.data[studio].DisplayColor = '#666666';
                        }

                        studios.push(
                            {
                                id: response.data[studio].id,
                                title: response.data[studio].Name,
                                scheduleColor: response.data[studio].DisplayColor,
                                color: studioColor
                            }
                        )
                    }


                    let schedules = {};
                    for (let weekday of this.state.weekdays) {
                        schedules[weekday] = {};

                        for (let studio of studios) {
                            schedules[weekday][studio.id] = [];
                        }
                    }


                    fetchIt(
                        GET_LIST,
                        'schedules',
                        {filter}
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                let myData = response.data;
                                let schedulerMinTime = moment.utc(this.state.schedulerMinTime, "HH:mm:ss");
                                let schedulerMaxTime = moment.utc(this.state.schedulerMaxTime, "HH:mm:ss");

                                for (let t = 0; t < myData.length; t++) {
                                    if (
                                        myData[t] &&
                                        myData[t].Id
                                    ) {
                                        if (typeof schedules[myData[t].DayOfWeek] === 'undefined') {
                                            schedules[myData[t].DayOfWeek] = {};
                                        }
                                        if (typeof schedules[myData[t].DayOfWeek][myData[t].Studio] === 'undefined') {
                                            schedules[myData[t].DayOfWeek][myData[t].Studio] = [];
                                        }

                                        const startHour = moment.utc(myData[t].StartHour, "H");
                                        const start = moment.utc(myData[t].StartHour, "H").add(myData[t].StartMinutes, 'minutes');
                                        const end = moment.utc(start.format('H:mm'), 'H:mm').add(myData[t].Duration, 'minutes');
                                        const maxEndHour = moment.utc('23:59', 'H:mm');

                                        if (schedulerMinTime.isAfter(startHour)) {
                                            schedulerMinTime = moment.utc(startHour.format('H'), 'H');
                                        }

                                        if (end.isAfter(maxEndHour)) {
                                            schedulerMaxTime = moment.utc(maxEndHour.format('H:mm'), 'H:mm');
                                        } else if (schedulerMaxTime.isBefore(end)) {
                                            schedulerMaxTime = moment.utc(end.format('H:mm'), 'H:mm');
                                        }

                                        myData[t].top = 0;
                                        myData[t].range = moment.range(start, end);

                                        schedules[myData[t].DayOfWeek][myData[t].Studio].push(myData[t]);
                                    }
                                }


                                if (me._isMounted) {
                                    me.setState({
                                        studios,
                                        schedules,
                                        schedulerMinTime: schedulerMinTime.format('HH:mm:ss'),
                                        schedulerMaxTime: schedulerMaxTime.format('HH:mm:ss'),
                                        isLoading: false
                                    });
                                }

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    clickOnTimeSlot = (event, time, isTimeSlot, dataSet) => {
        event.persist();

        if (isTimeSlot) {
            if (this._isMounted) {
                this.setState(
                    {
                        scheduleTeachers: [],
                        duration: 0,
                        selectedSchedule: 0,
                        selectedParentSchedule: 0
                    }
                );
            }

            contextMenu.show({
                id: 'ClickOnEmptyTimeSlotContextMenu',
                event: event,
                props: {start: time, dataSet}
            });
        } else {
            let schedule = event.target;
            if (schedule.dataset.getParent && !schedule.dataset.schedule) {
                while (schedule.dataset.getParent && !schedule.dataset.schedule) {
                    schedule = schedule.parentElement;
                }
            }

            const scheduleTeachersJson = JSON.parse(dataSet.Teachers);
            let scheduleTeachers = [];
            for (const teacher of scheduleTeachersJson) {
                scheduleTeachers.push(teacher.Id);
            }

            const duration = parseInt(dataSet.Duration);

            if (this._isMounted) {
                this.setState(
                    {
                        scheduleTeachers,
                        duration,
                        selectedSchedule: parseInt(schedule.dataset.schedule),
                        selectedScheduleDataset: schedule.dataset,
                        selectedParentSchedule: parseInt(schedule.dataset.parentSchedule)
                    }
                );
            }

            contextMenu.show({
                id: 'ClickOnScheduleContextMenu',
                event,
                props: {start: time, dataSet, schedule}
            });
        }

        return false;
    };


    onCloseClick = () => {
        if (this._isMounted) {
            this.setState({showDialog: false});
        }
    };


    onNewScheduleClick = event => {
        const start = event.props.start;
        const end = moment(event.props.start.format('HH:mm'), 'HH:mm').add(45, 'minutes');

        if (this._isMounted) {
            this.setState({event: {start, end, dataSet: event.props.dataSet}, showDialog: true});
        }
    };


    // onEditScheduleClick = data => {
    //     console.log(data);
    // };


    moveSchedule = data => {
        return fetchIt(
            UPDATE,
            'schedule_move',
            data
        );
    };


    refreshScheduler = (refresh, refreshRows, selectedSchedule) => {
        if (!refreshRows) {
            refreshRows = [];
        }

        let refreshSchedules = this.state.refreshSchedules;
        if (selectedSchedule) {
            if (inArray(selectedSchedule, refreshSchedules)) {
                refreshSchedules[0] = !refreshSchedules[0];
            } else {
                refreshSchedules = [true, selectedSchedule];
            }
        }

        this.setState({refresh, refreshRows, refreshSchedules});
    };


    onDurationSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'schedule_duration',
            {id: me.state.selectedSchedule, Duration: me.state.duration}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Duration = me.state.duration;
                    }

                    if (me._isMounted) {
                        me.setState({schedules, isDurationOpened: false, durationAnchorEl: undefined});
                    }

                    let message = 'Schedule duration changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.refreshScheduler(
                        [me.state.selectedScheduleDataset.weekday],
                        [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                        me.state.selectedSchedule
                    );

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onDurationCancelClick = event => {
        if (this._isMounted) {
            this.setState({isDurationOpened: false, durationAnchorEl: undefined});
        }
    };


    onDurationChange = event => {
        if (this._isMounted) {
            this.setState({duration: event.target.value});
        }
    };


    onDurationClick = event => {
        if (this._isMounted) {
            this.setState({isDurationOpened: true, durationAnchorEl: event.props.schedule});
        }
    };


    onTeachersSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'schedule_teachers',
            {
                id: me.state.selectedSchedule,
                Teachers: me.state.scheduleTeachers.map(teacher => ({Id: teacher, IsPrimary: true}))
            }
        ).then(response => {
            switch (response.status) {
                case 200:
                    const teachers = me.state.scheduleTeachers.map(
                        teacher => me.state.teachers.find(tchr => (teacher === tchr.Id))
                    );

                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Teachers = teachers;
                    }

                    if (me._isMounted) {
                        me.setState({schedules, isTeachersOpened: false, teachersAnchorEl: undefined});
                    }

                    let message = 'Schedule Teachers changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.refreshScheduler(
                        [me.state.selectedScheduleDataset.weekday],
                        [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                        me.state.selectedSchedule
                    );

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onTeachersCancelClick = () => {
        if (this._isMounted) {
            this.setState({isTeachersOpened: false, teachersAnchorEl: undefined});
        }
    };


    onTeachersChange = event => {
        if (this._isMounted) {
            this.setState({scheduleTeachers: event.target.value});
        }
    };


    onTeachersClick = event => {
        if (this._isMounted) {
            this.setState({isTeachersOpened: true, teachersAnchorEl: event.props.schedule});
        }
    };


    openDeleteSchedule = () => {
        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: true});
        }
    };


    onScheduleCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: false});
        }
    };


    onScheduleDelete = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            DELETE,
            'schedules',
            {id: this.state.selectedParentSchedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule deleted!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.fetchTheData();

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            this.onScheduleCancel();

            fetchEnd();
        });
    };


    openDeleteSdw = () => {
        if (this._isMounted) {
            this.setState({openDeleteSdw: true});
        }
    };


    onSdwCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteSdw: false});
        }
    };


    onSdwDelete = () => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            DELETE,
            'schedule_day_of_week',
            {id: this.state.selectedSchedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].splice(scheduleIndex, 1);;
                    }

                    if (me._isMounted) {
                        me.setState({schedules});
                    }

                    let message = 'Schedule Day of week deleted!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.refreshScheduler(
                        [me.state.selectedScheduleDataset.weekday],
                        [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                        me.state.selectedSchedule
                    );

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            this.onSdwCancel();

            fetchEnd();
        });
    };


    render() {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        return (
            <Fragment>
                <SchedulesListFilters
                    schoolYears={this.state.schoolYears}
                    semesters={this.state.semesters}
                    currentSemester={this.state.currentSemester}
                    fetchTheData={this.fetchTheData}
                    fetchSemesters={this.fetchSemesters}
                />

                {
                    !this.state.isLoading ?
                        <NeteaScheduler
                            // minTime='05:00:00'
                            // maxTime='23:59:59'
                            minTime={this.state.schedulerMinTime}
                            maxTime={this.state.schedulerMaxTime}
                            resources={this.state.studios}
                            schedules={this.state.schedules}
                            layoutColor='#fafafa'
                            slotDuration={15} // This can be 5, 10, 15 or 30 minutes
                            clickOnTimeSlot={this.clickOnTimeSlot}
                            fetchStart={fetchStart}
                            fetchEnd={fetchEnd}
                            showNotification={showNotification}
                            moveSchedule={this.moveSchedule}
                            refreshSchedules={this.state.refreshSchedules}
                            refresh={this.state.refresh}
                            refreshRows={this.state.refreshRows}
                        />
                    : null
                }

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth='md'
                    fullWidth
                    open={this.state.showDialog}
                    onClose={this.onCloseClick}
                    aria-label='Schedule a course'
                >
                    <DialogTitle onClose={this.onCloseClick}>
                        Schedule a course
                    </DialogTitle>

                    <DialogContent>
                        <ScheduleForm
                            schoolYears={this.state.schoolYears}
                            eventInitialData={this.state.event}
                            onClose={this.onCloseClick}
                            fetchTheData={this.fetchTheData}
                            scheduleFilters={this.state.filters}
                        />
                    </DialogContent>
                </Dialog>


                <Menu
                    id='ClickOnEmptyTimeSlotContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    <Item onClick={this.onNewScheduleClick}>
                        <AddNewIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>Create New Schedule</div>
                    </Item>
                </Menu>


                <Menu
                    id='ClickOnScheduleContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    {/*<Item onClick={this.onEditScheduleClick}>*/}
                    {/*    <ScheduleIcon />*/}
                    {/*    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>*/}
                    {/*        Edit Schedule (opens Schedule wizard with 5 tabs, populated data)*/}
                    {/*    </div>*/}
                    {/*</Item>*/}
                    <Item onClick={this.onTeachersClick}>
                        <TeachersIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Teachers (Manage Teacher for the specific day)
                        </div>
                    </Item>
                    <Item aria-describedby='durationPopper' onClick={this.onDurationClick}>
                        <DurationIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Duration (Manage duration for the specific day)
                        </div>
                    </Item>
                    <Item onClick={this.openDeleteSdw}>
                        <DeleteIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Delete (Schedule Day)
                        </div>
                    </Item>
                    <Item onClick={this.openDeleteSchedule}>
                        <DeleteWholeIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Delete Schedule (Deletes whole schedule)
                        </div>
                    </Item>
                </Menu>


                <Popper
                    id='durationPopper'
                    open={this.state.isDurationOpened}
                    anchorEl={this.state.durationAnchorEl}
                    placement='top'
                    // placement='right-start'
                    transition
                    style={{zIndex: 20}}
                >
                    <form
                        onSubmit={this.onDurationSubmit}
                        style={{padding: 10, border: '1px solid #000000', background: '#ffffff'}}
                    >
                        <label>Duration: </label>
                        <input
                            name="Duration"
                            type="number"
                            value={this.state.duration}
                            onChange={this.onDurationChange}
                        />

                        <button type="submit">Save</button>
                        <button onClick={this.onDurationCancelClick}>Cancel</button>
                    </form>
                </Popper>


                <Popper
                    id='teachersPopper'
                    open={this.state.isTeachersOpened}
                    anchorEl={this.state.teachersAnchorEl}
                    placement='top'
                    // placement='right-start'
                    transition
                    style={{zIndex: 20}}
                >
                    <form
                        onSubmit={this.onTeachersSubmit}
                        style={{padding: 10, border: '1px solid #000000', background: '#ffffff'}}
                    >
                        <label>Teachers: </label>
                        <Select
                            name="Teachers"
                            multiple
                            value={this.state.scheduleTeachers}
                            onChange={this.onTeachersChange}
                            input={<Input />}
                        >
                            {
                                this.state.teachers.map(teacher => (
                                    <MenuItem key={teacher.Id} value={teacher.Id}>
                                        {teacher.Name}
                                    </MenuItem>
                                ))
                            }
                        </Select>

                        <button type="submit">Save</button>
                        <button onClick={this.onTeachersCancelClick}>Cancel</button>
                    </form>
                </Popper>


                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteSdw}
                >
                    <DialogTitle id="confirmation-dialog-title">Delete Schedule day of week</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this Schedule day of week?
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.onSdwCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.onSdwDelete} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteWholeSchedule}
                >
                    <DialogTitle id="confirmation-dialog-title">Delete Whole Schedule</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this Whole Schedule?
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.onScheduleCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.onScheduleDelete} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}
