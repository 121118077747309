import React, {Fragment, Component} from 'react'
import {GET_LIST} from "react-admin";
import {fetchIt} from "../../../helpers";
import moment from "moment/moment";


let teachers = [];
let courses = [];

export default class Tooltip extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            hasMoved: false,
            teachers,
            courses
        };
    }


    componentDidMount() {
        this._isMounted = true;
        const me = this;

        if (teachers && (teachers.length === 0)) {
            fetchIt(
                GET_LIST,
                'teachers'
            ).then(response => {
                teachers = response.data;

                if (me._isMounted) {
                    this.setState({teachers});
                }
            });
        }

        if (courses && (courses.length === 0)) {
            fetchIt(
                GET_LIST,
                'courses'
            ).then(response => {
                courses = response.data;

                if (me._isMounted) {
                    this.setState({courses});
                }
            });
        }

        const width = this.divElement.clientWidth;
        this.props.getWidth(width);

        const height = this.divElement.clientWidth;
        this.props.getHeight(height);
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.tooltipPosition && nextProps.tooltipPosition.left > 0 && !this.state.hasMoved) {
            if (this._isMounted) {
                this.setState({hasMoved: true});
            }

            const width = this.divElement.clientWidth;
            this.props.getWidth(width);
        }
    }

    render() {
        const event = this.props.event;
        const studio = this.props.resources.find(studio => {
            return studio.id === parseInt(event.resourceId);
        });

        let eventTeachers = [];
        if (event.Teachers && this.state.teachers && (this.state.teachers.length > 0)) {
            for (let t = 0; t < event.Teachers.length; t++) {
                const teacher = this.state.teachers.find(teacher => {
                    return event.Teachers[t] === teacher.id;
                });

                if (teacher) {
                    eventTeachers.push(teacher.Name);
                }
            }
        }

        let eventDescription = null;
        if (event.DescriptionTagless) {
            eventDescription = event.DescriptionTagless;
        } else if (event.Description) {
            eventDescription = event.Description.replace(/<[^>]+>/g, '');
        }

        if (eventDescription && (eventDescription.length > 180)) {
            eventDescription = eventDescription.substring(0, 180) + '...';
        }

        // let eventCourse = this.state.courses.find(course => {
        //     return event.Course === course.id;
        // });

        return (
            <div
                style={{
                    position: 'absolute',
                    zIndex: 10001,
                    padding: 10,
                    background: 'white',
                    border: '2px solid #979797',
                    color: '#606060',
                    maxWidth: 350,
                    top: this.props.tooltipPosition.top,
                    left: this.props.tooltipPosition.left,
                    lineHeight: 1.8
                }}
                ref={(divElement) => this.divElement = divElement}
            >
                <div style={{margin: '10px 0px', color: '#403D39', fontWeight: 'bold'}}>{event.title}</div>

                {/*{*/}
                {/*    eventCourse ?*/}
                {/*        <div>Course: {eventCourse.Name}</div>*/}
                {/*        : null*/}
                {/*}*/}

                {
                    event.IsEvent ?
                        <Fragment>
                            <div>{moment(event.start).format('M/D/YY h:mm a')} - {moment(event.end).format('M/D/YY h:mm a')}</div>
                        </Fragment>
                    :
                        <Fragment>
                            <div>{eventTeachers.join(', ')}</div>
                            <div>{moment(event.start).format('ddd h:mm a')} - {moment(event.end).format('h:mm a')}</div>
                        </Fragment>
                }

                {
                    event.Price ?
                        <div style={{fontWeight: 'bold'}}>${event.Price}</div>
                    : null
                }

                <hr style={{margin: '10px 0px 20px 0px', border: '1px solid #606060'}} />

                {
                    studio ?
                        <div style={{lineHeight: 1, color: 'black', margin: '10px 0px 0px 0px', borderLeft: '3px solid ' + studio.eventColor, padding: '0px 10px'}}>{studio.title}</div>
                    : null
                }

                {
                    event.AgesName ?
                        <div>Age: {event.AgesName}</div>
                    : null
                }

                <hr style={{margin: '10px 0px', border: '1px solid #606060'}} />

                {
                    eventDescription ?
                        <div>{eventDescription}</div>
                    : null
                }

                <div style={{fontWeight: 'bold'}}>{event.Occurrence ? 'Repeats' : 'Does not repeat'}</div>

                {
                    event.IsEvent ?
                        <div>{eventTeachers.join(', ')}</div>
                    : null
                }

            </div>
        );
    };
}
