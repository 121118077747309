import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';


export default () => (
    <div className="wrapperContent">
        <Card>
            <Title title="Welcome to the administration" />
            <CardContent>This is the Dashboard</CardContent>
        </Card>
    </div>
);