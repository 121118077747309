import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    downloadCSV
} from 'react-admin';
import {CoursesListFilters} from '../';
import SplitButton  from '../../../../common/containers/SplitButton';
import jsonExport from 'jsonexport/dist';
import {inArray} from "../../../../helpers";


export const exporter = (records, fetchRelatedRecords) => {
    const headers = ['Id', 'Name', 'Categories', 'CourseLevel', 'ClassType', 'Description', 'AgeInterval', 'Duration', 'SingleClassPrice', 'Status', 'CreatedTime', 'CreatedBy', 'ModifiedBy', 'ModifiedTime'];

    fetchRelatedRecords(records, 'Categories', 'course_categories').then(categories => {
        fetchRelatedRecords(records, 'CourseLevel', 'course_levels').then(courseLevels => {
            fetchRelatedRecords(records, 'AgeInterval', 'age_intervals').then(ageIntervals => {
                const data = records.map(record => {
                    let recordData = {};
                    // eslint-disable-next-line
                    for (const prop in record) {
                        if (record.hasOwnProperty(prop) && inArray(prop, headers)) {
                            recordData[prop] = record[prop];
                        }
                    }

                    return ({
                        ...recordData,
                        Description: record.DescriptionTagless ? record.DescriptionTagless : record.Description,
                        Categories: record.Categories.map(category => {
                            return categories[category].Name
                        }).join(', '),
                        CourseLevel: courseLevels[record.CourseLevel].Name,
                        AgeInterval: ageIntervals[record.AgeInterval].Name,
                        Status: record.IsActive ? 'Active' : 'Inactive',
                    });
                });

                jsonExport(data, {headers}, (err, csv) => {
                    downloadCSV(csv, 'CoursesList');
                });
            });
        });
    });
};



export const CoursesList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Courses List</h1>
        <List {...props} filters={<CoursesListFilters />} title=' ' exporter={exporter} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="Name" />
                <ReferenceArrayField source="Categories" reference="course_categories" linkType="show" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="Name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceField source="CourseLevel" label="Level" reference="course_levels" linkType="show">
                    <TextField source="Name" />
                </ReferenceField>
                <ReferenceField source="AgeInterval" label="Age" reference="age_intervals" linkType="show">
                    <TextField source="Name" />
                </ReferenceField>
                <NumberField source="SingleClassPrice" label="Price" />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonRight'
                    successMessage='Course deleted'
                    errorMessage='Course NOT deleted'
                    confirmTitle='Delete the course'
                    confirmContent='Are you sure you want to delete the course?' 
                />
            </Datagrid>
        </List>
    </div>
);
