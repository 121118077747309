import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {StaffForm} from '../';
import {BackButton} from '../../../../common';


const StaffCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <h1>Create Staff</h1>
            <Create {...props} title=" ">
                <StaffForm />
            </Create>
        </Fragment>
    );
};

export default StaffCreate;
