import React, {Component} from 'react';
import {SaveButton} from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import {fetchIt} from "../../../../helpers/fetcher"
import {getErrorMessage} from "../../../../helpers";


class SendActivationEmailButton extends Component {
    _isMounted = false;

    state = {
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSendClick = () => {
        const {showNotification} = this.props;
        this.props.fetchStart();

        fetchIt(
            "SEND_ACTIVATION_EMAIL",
            'tenant_users',
            {id: this.props.record.Id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Email is sent.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                    let errorMessage = 'There was a problem with sending activation email.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            this.props.fetchEnd();
        });
    };

    render() {
        return (
            <SaveButton
                variant="contained"
                label="Send Activation Email"
                handleSubmitWithRedirect={this.handleSendClick}
                disabled={this.state.isDisabled}
                icon={<SendIcon />}
                style={{marginTop: 5}}
            />
        );
    }
}

export default SendActivationEmailButton;