import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton
} from 'react-admin';
import {renderYearsFromMonths} from '../../../../helpers';
// import {exporter} from "../../../studios";
// import {DeleteButton} from '../../../common';


// const AgeIntervalsBulkActionButtons = props => (
//     <Fragment>
//         <DeleteButton
//             {...props}
//             bulkSuccessMessage='Age Intervals deleted'
//             bulkErrorMessage='Age Intervals NOT deleted'
//             confirmTitle='Delete selected Age Interval'
//             confirmContent='Are you sure you want to delete the selected Age Intervals?'
//         />
//     </Fragment>
// );

export const AgeIntervalsList = props => (
    <div className="wrapperContent">
        <h1>Age Intervals List</h1>
        <List {...props} title=' ' exporter={false} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}} >
            <Datagrid >
                <TextField source='Name' />
                <FunctionField source='FromAge' render={record => renderYearsFromMonths(record, 'FromAge') + ' yrs.'} />
                <FunctionField source='ToAge' render={record => renderYearsFromMonths(record, 'ToAge') + ' yrs.'} />
                <FunctionField 
                    label='Status' 
                    source='IsActive' 
                    cellClassName='singleButtonRight' 
                    render={record => record.IsActive ? 'Active' : 'Inactive'} 
                />
                <EditButton style={{float: 'right'}} />
            </Datagrid>
        </List>
    </div>
);
