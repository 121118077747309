import React, {Component, Fragment} from 'react';
import {
    CREATE,
    UPDATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    TextField,
    BooleanInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {NtaSaveButton} from '../../../common';
import {getErrorMessage, fetchIt} from "../../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';


export const CourseCategoryFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


class CourseCategoryForm extends Component {
    _isMounted = false;

    state = {
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    nameOnChange = e => {
        this.updateTitle(e.currentTarget.value);
    };

    updateTitle = newTitle => {
        if (this.props.updateTitle) {
            let title = '';
            if (newTitle) {
                title = newTitle;
            }
            this.props.updateTitle(title);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.updateTitle(nextProps.record.Name);
        }
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        fetchIt(
            requestType,
            'course_categories',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Course Category saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Card className="cardForm">
                <CardContent>
                    <SimpleForm
                        record={this.props.record}
                        resource="course_categories"
                        save={this.handleSubmit}
                        toolbar={<CourseCategoryFormToolbar isDisabled={this.state.isDisabled} />}
                    >
                        <TextInput
                            source="Name"
                            label='Category Name'
                            onChange={this.nameOnChange}
                            validate={required('The Category Name field is required')}
                            className='fieldsWidthExpand'
                        />

                        {
                            ! this.props.editMode ||
                            ! (
                                Object.keys(this.props.record).length === 0 &&
                                this.props.record.constructor === Object
                            ) ?
                                <RichTextInput source="Description" />
                            : null
                        }

                        <BooleanInput source='IsActive' defaultValue={this.props.editMode ? '' : true} />

                        {
                            this.props.editMode ?
                                <Fragment>
                                    <TextField source='CreatedBy' />
                                    <TextField source='ModifiedBy' />
                                    <TextField source='CreatedTime' />
                                    <TextField source='ModifiedTime' />
                                </Fragment>
                            : null
                        }
                    </SimpleForm>
                </CardContent>
             </Card>
        );
    }
}
export default CourseCategoryForm;
