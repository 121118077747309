import React, {Component} from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";

class DateTimePicker extends Component {

    constructor (props) {
        super(props);

        this.state = {
            touched: false
        };
    }

    renderLabel = (date) => {
        if (date.isValid()) {
          return date.format(this.props.dateFormat ? this.props.dateFormat : 'MM/DD/YYYY');
        } else {
          return '';
        }
    };

    onBlur = () => {
        this.setState({touched: true})
    }

    onChange = dateTime => {
        this.setState({touched: true});
        this.props.input.onChange(dateTime);
    }

    render() {
        const {
            label,
            source,
            resource,
            isRequired,
            meta,
            disablePast,
        } = this.props;

        const {error} = meta;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <KeyboardDateTimePicker
                    className='fieldsWidthExpand'
                    margin="normal"
                    id="date-time-picker"
                    label={
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    }
                    value={this.props.input.value}
                    // onChange={this.props.input.onChange}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    format={this.props.dateFormat ? this.props.dateFormat : 'MM/DD/YYYY'}
                    style={{marginTop: 15, paddingBottom: 15}}
                    error={!!(this.state.touched && error)}
                    helperText={this.state.touched && error}
                    disablePast={disablePast}
                    labelFunc={this.renderLabel}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
export default addField(DateTimePicker);
