import {required} from "react-admin";

const passwordFieldValidation = (message = 'The two password fields should match') =>
    (value, allValues) => value === allValues.RepeatPassword ? undefined : message;

const repeatPasswordFieldValidation = (message = 'The two password fields should match') =>
    (value, allValues) => (!allValues.Password || (value === allValues.Password)) ? undefined : message;

export const passwordValidation = [required('The Password field is required'), passwordFieldValidation()];
export const repeatPasswordValidation = [repeatPasswordFieldValidation(), required('The Repeat Password field is required')];
