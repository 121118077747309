import React from 'react';
import './App.css'
import {Admin, Resource} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
import NotificationIcon from '@material-ui/icons/NotificationsActiveRounded';
import SchoolIcon from '@material-ui/icons/School';
import MyAccountIcon from '@material-ui/icons/AccountCircleRounded';
import EventIcon from "@material-ui/icons/Event";
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import {createMuiTheme} from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import {generateClassName} from './generateClassName';
import {NotificationCreate, NotificationsList} from './resources/notifications';
import {LocationsList, LocationShow, LocationEdit,  LocationCreate} from './resources/locations';
import {SchoolShow, SchoolEdit} from './resources/schools';
import FourOFour from './components/FourOFour';
import {Login} from './authorization';
import Routes from './Routes';
import {LayoutWithTheme, Dashboard} from './layout';
import {StudiosList, StudioShow, StudioCreate, StudioEdit} from './resources/studios';
import {MyAccountShow, MyAccountEdit} from './resources/account';
import {TeachersList, TeacherShow, TeacherCreate, TeacherEdit} from './resources/teachers';
import Teachers from '@material-ui/icons/People';
import {HolidaysList, HolidayCreate, HolidayEdit, HolidayShow} from './resources/holidays';
import HolidayIcon from '@material-ui/icons/WbSunny';
import {UsersList, UserShow, UserEdit, UserCreate} from './resources/tenants';
import Calendar from "./resources/calendar/containers/Calendar";
import {SchedulesList} from "./resources/schedules";
import {AgeIntervalsList, AgeIntervalCreate, AgeIntervalEdit} from './resources/settings/AgeIntervals';
import {StaffList, StaffShow, StaffEdit, StaffCreate} from './resources/settings/Staff'
import {
    CourseCategoriesList,
    CourseCategoryCreate,
    CourseCategoryEdit,
    CourseCategoryShow,
    CourseLevelsList,
    CourseLevelCreate,
    CourseLevelEdit,
    CourseLevelShow,
    CourseShow,
    CoursesList,
    CourseCreate,
    CourseEdit
} from './resources/courses';
import {FeesList, FeesShow, FeesEdit, FeesCreate} from './resources/settings/fees';

import {inArray} from './helpers';
import {approvedRoles} from './config/constants';



const App = () => (
    <JssProvider generateClassName={generateClassName}>
        <Admin
            theme={THEME}
            layout={LayoutWithTheme}
            catchAll={FourOFour}
            authProvider={authProvider}
            dataProvider={dataProvider}
            loginPage={Login}
            dashboard={Dashboard}
            customRoutes={Routes}
        >
            {permissions => [
                <Resource
                    name="account"
                    show={MyAccountShow}
                    edit={MyAccountEdit}
                    icon={MyAccountIcon}
                    options={{label: 'My Account'}}
                />,

                <Resource
                    name="notifications"
                    list={NotificationsList}
                    create={NotificationCreate}
                    icon={NotificationIcon}
                    options={{label: 'Notifications'}}
                />,

                <Resource
                    name="schools"
                    show={SchoolShow}
                    edit={inArray(permissions, approvedRoles) ? SchoolEdit : null}
                    icon={SchoolIcon}
                    options={{label: 'School'}}
                />,

                <Resource
                    name="locations"
                    show={LocationShow}
                    list={LocationsList}
                    create={LocationCreate}
                    edit={LocationEdit}
                />,

                <Resource
                    name="studios"
                    show={StudioShow}
                    list={StudiosList}
                    create={StudioCreate}
                    edit={StudioEdit}
                />,

                <Resource
                    name="teachers"
                    show={TeacherShow}
                    list={TeachersList}
                    create={TeacherCreate}
                    edit={TeacherEdit}
                    icon={Teachers}
                    options={{ label: 'Teachers' }}
                />,

                <Resource
                    name="holidays"
                    show={HolidayShow}
                    list={HolidaysList}
                    create={HolidayCreate}
                    edit={HolidayEdit}
                    icon={HolidayIcon}
                    options={{label: 'Holidays'}}
                />,

                <Resource
                    name="tenant_users"
                    show={UserShow}
                    edit={UserEdit}
                    list={UsersList}
                    create={UserCreate}
                    icon={PeopleIcon}
                    options={{label: 'Customers'}}
                />,

                <Resource
                    name="calendar"
                    list={Calendar}
                    icon={EventIcon}
                    options={{label: 'Calendar'}}
                />,

                <Resource
                    name='courses'
                    show={CourseShow}
                    list={CoursesList}
                    create={CourseCreate}
                    edit={CourseEdit}
                />,

                <Resource
                    name='course_categories'
                    show={CourseCategoryShow}
                    list={CourseCategoriesList}
                    create={CourseCategoryCreate}
                    edit={CourseCategoryEdit}
                />,

                <Resource
                    name='course_levels'
                    show={CourseLevelShow}
                    list={CourseLevelsList}
                    create={CourseLevelCreate}
                    edit={CourseLevelEdit}
                />,

                <Resource
                    name='courses_menu'
                    options={{ label: 'Courses' }}
                    list={CourseCategoriesList}
                />,

                <Resource
                    name='schedules'
                    list={SchedulesList}
                    options={{label: 'Schedules'}}
                />,

                <Resource
                    name='age_intervals'
                    list={AgeIntervalsList}
                    create={AgeIntervalCreate}
                    edit={AgeIntervalEdit}
                />,

                 <Resource
                    name="fees"
                    list={FeesList}
                    show={FeesShow}
                    edit={FeesEdit}
                    create={FeesCreate}
                />,

                <Resource
                    name='semesters'
                    // list={StaffList}
                    // show={StaffShow}
                    // create={StaffCreate}
                    // edit={StaffEdit}
                />,

                <Resource
                    name='settings'
                    list={AgeIntervalsList}
                />,

                permissions === 'School Manager' ?
                    (
                        <Resource
                            name='staff'
                            list={StaffList}
                            show={StaffShow}
                            create={StaffCreate}
                            edit={StaffEdit}
                        />
                    )
                : null,



                <Resource name="home_address" />,
                <Resource name="notifications_locations" />,
                <Resource name="notifications_sessions" />,
                <Resource name="notifications_schedules" />,
                <Resource name="notifications_created_by" />,
                <Resource name="users_family_members" create={UserCreate} />,
            ]}

        </Admin>
    </JssProvider>
);

export default App;



// Colors
const appBarBackgroundBlue = "#2196f3";

const THEME = createMuiTheme({
    typography: {
        useNextVariants: true,
        "fontFamily": "\"proxima-nova\", sans-serif",
    },

    overrides: {
        MuiAppBar: {
            root: {
                background: appBarBackgroundBlue + ' !important'
            },
        },

        MuiPaper: {
            rounded: {
                borderRadius: 5
            }
        },

        MuiFormLabel: {
            root: {
                fontWeight: 500,
                color: '#9f9f9f',
                fontSize: 15,
                lineHeight: 1.57
            }
        },

        MuiInputBase: {
            input: {
                background: 'transparent',
            }
        },

        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            },

            input: {
                padding: '27px 0px 10px',
            },

            multiline: {
                paddingLeft: 0
            }
        },

        MuiFormControl: {
            marginDense: {
                marginTop: 16,
                margiBottom: 8,
            }
        },

        MuiFormHelperText: {
            contained: {
                marginLeft: 0
            }
        },

        MuiMenuItem: {
            dense: {
                fontSize: '1rem'
            }
        },

        MuiTableCell: {
            head: {
                color: "#000",
                textTransform: "uppercase",
                fontWeight: 400,
            },

            root: {
                padding: 0,

                '&:last-child': {
                    paddingRight: 0
                }
            },

            body: {
                fontSize: "1rem"
            }
        },

        MuiPrivateNotchedOutline: {
            legend: {
                borderColor: "transparent"
            }
        },

        MuiListItem: {
            root: {
                boxSizing: 'content-box'
            }
        },

        MuiDrawer: {
            docked: {
                marginRight: 30,
                marginTop: '2em'
            }
        },

        MuiSnackbar: {
            root: {
                zIndex: '100000000 !important'
            }
        }
    },
});
