import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'connected-react-router';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';


class BackButton extends Component {
    handleClick = () => {
        this.props.goBack();
    };

    render() {
        return (
            <div className="wrapperContent">
                <Grid container spacing={10} className='labeled-value'>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            onClick={this.handleClick}
                            style={{
                                textTransform: "capitalize",
                                fontSize: 16,
                                color: "#9f9f9f"
                            }}
                        >
                            <ArrowBack style={{fontSize: 16}} />
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(null, {
    goBack,
})(BackButton);
