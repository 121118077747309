import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    FormDataConsumer
} from 'react-admin';
// import  HolidaySaveButton  from '../components/HolidaySaveButton';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {DatePickerHoliday} from '../../../common';
import moment from 'moment';
import {NtaSaveButton} from '../../common';

export const HolidayFormToolbar = props => {
    const {isDisabled, context, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} context={context}/>
        </Toolbar>
    );
};

export const HolidayFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

class HolidayForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false,
        fromDate: new Date(),
        fromDateValue: "",
        toDateValue: ""
        
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        values["From"] = moment(this.state.fromDateValue);
        values["To"] = moment(this.state.toDateValue);

        fetchIt(
            requestType,
            'holidays',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Holiday saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.holiday = this.props.holiday;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <SimpleForm
                            resource={this.props.resource}
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<HolidayFormToolbar isDisabled={this.state.isDisabled} context={this}/>}
                        >
                            <TextInput source="HolidayName"
                                className='fieldsWidthExpand'
                                validate={required('The Holiday Name field is required')}
                                multiline
                            />
                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return <HolidayFormTitle title={formData.HolidayName} />;
                                    }
                                }
                            </FormDataConsumer>

                            <DatePickerHoliday source='From' minDate={new Date()} context={this} />
                            <DatePickerHoliday source='To' minDate={this.state.fromDate} context={this} />
                        </SimpleForm>
                    </CardContent> 
                 </Card>
            </Fragment>
        );
    }
}

export default HolidayForm;