import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import { connect } from "react-redux";
import SchoolEdit from '../components/SchoolEdit';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    SchoolEdit
);
