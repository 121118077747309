import {connect} from 'react-redux';
import TeacherSendEmailButton from '../components/TeacherSendEmailButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    TeacherSendEmailButton
);