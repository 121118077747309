import React, {Fragment, useState, useEffect, useRef} from 'react';
import {
    GET_ONE,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput
} from 'react-admin';
import {useForm, useFormState} from "react-final-form";
import {Grid, Typography} from "@material-ui/core";
import {fetchIt} from "../../../helpers";
import moment from "moment";
import {weekdays} from '../../../config/constants';
import {ScheduleTimePicker} from '../../../common';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


const ScheduleDaysOfWeekInput = props => {
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [hasChanged, setHasChanged] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [innerErrorsLength, setInnerErrorsLength] = useState(0);
    const [scheduleDaysOfWeek, setScheduleDaysOfWeek] = useState([]);
    const [baseScheduleDayOfWeek, setBaseScheduleDayOfWeek] = useState({
        DayOfWeek: props.eventInitialData.dataSet.weekday,
        StartTime: props.eventInitialData.start.format('h:mmA'),
        Duration: 0,
        Studio: parseInt(props.eventInitialData.dataSet.resource),
        Teachers: []
    });

    const form = useForm();
    const formState = useFormState();

    const {fetchStart, fetchEnd, showNotification} = props;
    const course = formState.values.Course;

    const bottom = useRef(null);
    const scheduleDaysOfWeekLength = scheduleDaysOfWeek.length;
    const errorsLength = Object.keys(props.errors).length;
    const propsForceUpdate = props.forceUpdate;

    const scrollToBottom = () => {
        if (bottom.current) {
            bottom.current.scrollIntoView({behavior: "smooth"})
        }
    };


    useEffect(scrollToBottom, [scheduleDaysOfWeekLength]);


    useEffect(() => {
        if (errorsLength !== innerErrorsLength) {
            setInnerErrorsLength(errorsLength);
        }

        if (forceUpdate !== propsForceUpdate) {
            setForceUpdate(propsForceUpdate);
        }
    }, [errorsLength, innerErrorsLength, forceUpdate, propsForceUpdate]);


    useEffect(() => {
        if ((typeof course !== 'undefined') && (course !== selectedCourse)) {
            // Dispatch an action letting react-admin know an API call is ongoing
            fetchStart();

            fetchIt(
                GET_ONE,
                'courses',
                {id: course}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        if (response && response.data) {
                            let newScheduleDaysOfWeek = [];
                            let newBaseScheduleDayOfWeek = Object.assign({}, baseScheduleDayOfWeek);
                            newBaseScheduleDayOfWeek.Duration = response.data.Duration;
                            setBaseScheduleDayOfWeek(Object.assign({}, newBaseScheduleDayOfWeek));

                            newScheduleDaysOfWeek.push(Object.assign({}, newBaseScheduleDayOfWeek));

                            setScheduleDaysOfWeek(newScheduleDaysOfWeek);
                            setSelectedCourse(response.data.Id);

                            form.change('ScheduleDaysOfWeekInput.Duration0', response.data.Duration);
                            form.change('ScheduleDaysOfWeekInput.Studio0', newBaseScheduleDayOfWeek.Studio);
                            form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
                        }

                        break;

                    case 404:
                        let errorMessage = '';

                        if (!response.data.HasValidationErrors) {
                            errorMessage = response.data.ErrorMessage;
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        );
                }
            }).catch(error => {

            }).then(() => {
                // Dispatch an action letting react-admin know an API call has ended
                fetchEnd();
            });
        }
    }, [course]); // eslint-disable-line


    const onStartTimeChange = (value, index) => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        let newStartTime = '';
        if (value) {
            newStartTime = value.format('h:mmA');
        }

        newScheduleDaysOfWeek[index].StartTime = newStartTime;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
    };


    const onDayOfWeekChange = (event, index) => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].DayOfWeek = event.target.value;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
    };


    const onDurationChange = (event, index) => {
        event.persist();
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].Duration = event.target.value;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);

        if (event.target.value.length === 0) {
            let newErrors = props.errors;
            if (typeof newErrors['sdw_' + index] !== 'object') {
                newErrors['sdw_' + index] = {
                    Idx: index,
                    Message: ''
                };
            }

            props.setErrors(newErrors);
            props.updateParent();
        } else if (
            (typeof props.errors['sdw_' + index] === 'object') &&
            (props.errors['sdw_' + index].Message.length === 0)
        ) {
            let newErrors = props.errors;
            delete newErrors['sdw_' + index];

            props.setErrors(newErrors);
            props.updateParent();
        }

        form.change('ScheduleDaysOfWeekInput.Duration' + index, event.target.value);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
    };


    const onStudioChange = (event, index) => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].Studio = event.target.value;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
    };


    const onTeacherChange = (event, index) => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].Teachers = event.target.value;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
    };


    const addDayOfWeek = () => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek.push(Object.assign({}, baseScheduleDayOfWeek));

        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
        form.change('ScheduleDaysOfWeekInput.Duration' + (newScheduleDaysOfWeek.length - 1), baseScheduleDayOfWeek.Duration);
        form.change('ScheduleDaysOfWeekInput.Studio' + (newScheduleDaysOfWeek.length - 1), baseScheduleDayOfWeek.Studio);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
    };


    const removeDayOfWeek = index => {
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek.splice(index,1);

        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setHasChanged(!hasChanged);
        form.change('ScheduleDaysOfWeek', newScheduleDaysOfWeek);
    };


    if (selectedCourse > 0) {
        return (
            <Fragment>
                {
                    scheduleDaysOfWeek.map((startDate, index) => {
                        let hasError = false;
                        if ((innerErrorsLength > 0) && (typeof props.errors['sdw_' + index] === 'object')) {
                            hasError = true;
                        }

                        return (
                            <div key={index} style={{marginTop: 20}}>
                                <Grid container spacing={5} style={hasError ? {border: '1px solid red'} : {}}>
                                    <Grid item md={3} xs={12}>
                                        <SelectInput
                                            required
                                            value={startDate.DayOfWeek}
                                            source={'ScheduleDaysOfWeekInput.DayOfWeek' + index}
                                            choices={weekdays}
                                            translateChoice={false}
                                            label='Day Of Week'
                                            onChange={event => onDayOfWeekChange(event, index)}
                                        />
                                    </Grid>

                                    <Grid item md={3} xs={12} style={{marginTop: 2}}>
                                        <ScheduleTimePicker
                                            isRequired
                                            label='Start Time *'
                                            source={"ScheduleDaysOfWeekInput.StartTime" + index}
                                            val={moment(startDate.StartTime, 'h:mmA')}
                                            onChange={value => onStartTimeChange(value, index)}
                                            useInitialWidth={true}
                                        />
                                    </Grid>

                                    <Grid item md={2} xs={12}>
                                        <NumberInput
                                            value={startDate.Duration}
                                            label='Duration'
                                            source={'ScheduleDaysOfWeekInput.Duration' + index}
                                            inputProps={{
                                                min: 0
                                            }}
                                            format={v => parseInt(v) >= 0 ? parseInt(v) : ' '}
                                            parse={v => parseInt(v)}
                                            validate={required('The Duration field is required')}
                                            onChange={event => onDurationChange(event, index)}
                                        />
                                        <FormHelperText>in minutes</FormHelperText>
                                    </Grid>

                                    <Grid item md={4} xs={12}>
                                        <ReferenceInput
                                            required
                                            label='Studio'
                                            source={'ScheduleDaysOfWeekInput.Studio' + index}
                                            resource='studios'
                                            reference='studios'
                                            filter={{IsActive: true}}
                                            perPage={1000}
                                            validate={required('The Studio field is required')}
                                            onChange={event => onStudioChange(event, index)}
                                        >
                                            <SelectInput
                                                optionText='Name'
                                                style={{width: '100%'}}
                                                value={startDate.Studio}
                                            />
                                        </ReferenceInput>
                                    </Grid>

                                    {
                                        hasError ?
                                            <Grid item xs={12}>
                                                <FormHelperText error>
                                                    {props.errors['sdw_' + index].Message}
                                                </FormHelperText>
                                            </Grid>
                                            : null
                                    }
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <ReferenceArrayInput
                                            label='Teachers'
                                            source={'ScheduleDaysOfWeekInput.Teachers' + index}
                                            resource='teachers'
                                            reference='teachers'
                                            filter={{IsActive: true}}
                                            perPage={1000}
                                            onChange={event => onTeacherChange(event, index)}
                                        >
                                            <SelectArrayInput
                                                optionText='Name'
                                                style={{width: '100%'}}
                                            />
                                        </ReferenceArrayInput>
                                        <FormHelperText>You can choose more than one teacher</FormHelperText>
                                    </Grid>
                                </Grid>
                                {
                                    scheduleDaysOfWeekLength > 1 ?
                                        <Grid container justify="center" spacing={5}>
                                            <Grid item xs={12} sm={6} md={3} lg={2} xl={1} >
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    color='secondary'
                                                    startIcon={<RemoveIcon />}
                                                    style={{
                                                        borderRadius: 20,
                                                        textTransform: 'unset',
                                                        marginTop: -20
                                                    }}
                                                    onClick={() => removeDayOfWeek(index)}
                                                >
                                                    Remove
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    : null
                                }

                                <Divider style={{marginTop: 15, marginBottom: 40}} />
                            </div>
                        );
                    })
                }

                <Grid container justify="center" spacing={5}>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            component="span"
                            startIcon={<AddIcon />}
                            style={{
                                borderRadius: 20,
                                textTransform: 'unset',
                                width: '100%',
                                marginTop: 30
                            }}
                            onClick={addDayOfWeek}
                            ref={bottom}
                        >
                            Add more days for this course
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    return (
        <Typography variant="h6">No course selected</Typography>
    );
};

export default ScheduleDaysOfWeekInput;
