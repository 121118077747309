import React, {Component, Fragment} from 'react';
import {Create} from 'react-admin';
import CourseForm from '../containers/CourseForm';
import {BackButton} from '../../../../common';


class CourseCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Create Course</h1>
                <Create {...this.props} title=" ">
                    <CourseForm />
                </Create>
            </Fragment>
        );
    }
}
export default CourseCreate;
