import {fetchIt} from './helpers';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';


const dataProvider = {
    getList:    (resource, params) => fetchIt(
        GET_LIST,
        resource,
        params
    ),
    getOne:     (resource, params) => fetchIt(
        GET_ONE,
        resource,
        params
    ),
    getMany:    (resource, params) => fetchIt(
        GET_MANY,
        resource,
        params
    ),
    getManyReference: (resource, params) => fetchIt(
        GET_MANY_REFERENCE,
        resource,
        params
    ),
    create:     (resource, params) => fetchIt(
        CREATE,
        resource,
        params
    ),
    update:     (resource, params) => fetchIt(
        UPDATE,
        resource,
        params
    ),
    updateMany: (resource, params) => fetchIt(
        UPDATE_MANY,
        resource,
        params
    ),
    delete:     (resource, params) => fetchIt(
        DELETE,
        resource,
        params
    ),
    deleteMany: (resource, params) => fetchIt(
        DELETE_MANY,
        resource,
        params
    ),
};

export default dataProvider;
