import React from 'react';
import {
    SimpleShowLayout,
    FunctionField
} from 'react-admin';
import {formatPhone} from "../../../../helpers";
import Grid from '@material-ui/core/Grid';
import moment from "moment/moment";


export const UserFamilyDetails = props => (
    <Grid container>
        <Grid item xs={6} className="showSections">
            <SimpleShowLayout {...props}>
                <FunctionField 
                    label='Email' 
                    render={record => record.Email ? record.Email : 'Not specified'}
                />
                <FunctionField 
                    label="Home Phone" 
                    render={record => {return `${record.HomePhone}` === "null" ? 'Not specified' : formatPhone(record.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10))}} 
                />
                <FunctionField 
                    label="Cell Phone" 
                    render={record => {return `${record.CellPhone}` === "null" ? 'Not specified' : formatPhone(record.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10))}} 
                />
            </SimpleShowLayout>
        </Grid>
        <Grid item xs={6} className="showSections">
            <SimpleShowLayout {...props}>
                <FunctionField 
                    label='Age' 
                    render={record => record.Age ? record.Age : 'Not specified'}
                />
                <FunctionField
                    label='BirthDate'
                    render={record => record.BirthDate ? moment(record.BirthDate, 'MM/DD/YYYY').format('MMMM D, YYYY') : 'Not specified'}
                />
                <FunctionField 
                    label='Gender' 
                    render={record => record.Gender ? record.Gender : 'Not specified'}
                />
            </SimpleShowLayout>
        </Grid>
    </Grid>
);