import React, {Component, Fragment} from 'react';
import {Create} from 'react-admin';
import {CourseLevelForm} from '../';
import {BackButton} from '../../../../common';


class CourseLevelCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Create Course Level</h1>
                <Create {...this.props} title=" ">
                    <CourseLevelForm />
                </Create>
            </Fragment>
        );
    }
}
export default CourseLevelCreate;
