import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceField,
    downloadCSV
} from 'react-admin';
import {ColorField} from 'react-admin-color-input';
import {StudiosListFilters} from '../';
import jsonExport from 'jsonexport/dist';
import {inArray} from '../../../helpers';
import SplitButton from '../../../common/containers/SplitButton';


export const exporter = (records, fetchRelatedRecords) => {
    const headers = ['Id', 'Location', 'Name', 'Description', 'IsActive', 'DisplayColor', 'CreatedTime', 'CreatedBy', 'ModifiedBy', 'ModifiedTime'];

    fetchRelatedRecords(records, 'SchoolLocationId', 'locations').then(locations => {
        const data = records.map(record => {
            let recordData = {};
            // eslint-disable-next-line
            for (const prop in record) {
                if (record.hasOwnProperty(prop) && inArray(prop, headers)) {
                    recordData[prop] = record[prop];
                }
            }

            return ({
                ...recordData,
                Description: record.DescriptionTagless ? record.DescriptionTagless : record.Description,
                Location: locations[record.SchoolLocationId].LocationName,
            });
        });

        jsonExport(data, {
            headers
        }, (err, csv) => {
            downloadCSV(csv, 'StudiosList');
        });
    });
};


export const StudiosList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Studios List</h1>
        <List filters={<StudiosListFilters />} {...props} title=' ' exporter={exporter} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <ReferenceField label="Location" source="SchoolLocationId" reference="locations" linkType="show">
                    <TextField source="LocationName" />
                </ReferenceField>
                <ColorField source="DisplayColor" label="Color" className="colorCode" />
                <TextField source="Name" label="Studio" cellClassName='resizeTd'/>
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton
                    cellClassName='splitButtonTdSmall'
                    successMessage={'Studio deleted'}
                    errorMessage={'Studio NOT deleted'}
                    confirmTitle={'Delete the studio'}
                    confirmContent={'Are you sure you want to delete the studio?'}
                />
            </Datagrid>
        </List>
    </div>
);
