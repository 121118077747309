const hostname = window && window.location && window.location.hostname;
let backendUrl = 'https://dev.adagioballet.net/';
let frontendUrl = 'https://dev.adagioballet.com/';

switch(hostname) {
    case 'localhost':
        frontendUrl = 'http://ballet.cc/';
        backendUrl = 'https://dev.adagioballet.net/';

        break;

    case 'ballet.cc':
        frontendUrl = 'http://ballet.cc/';
        backendUrl = 'https://dev.adagioballet.net/';

        break;

    case 'stage.admin.adagioballet.com':
        frontendUrl = 'https://stage.adagioballet.com/';
        backendUrl = 'https://stage.adagioballet.net/';

        break;

    case 'admin.adagioballet.com':
        frontendUrl = 'https://www.adagioballet.com/';
        backendUrl = 'https://adagioballet.net/';

        break;

    default:
        backendUrl = 'https://dev.adagioballet.net/';
        frontendUrl = 'https://dev.adagioballet.com/';
}


export const BACKEND_URL = backendUrl;
export const FRONTEND_URL = frontendUrl;
export const FRONTEND_SCHEDULE_PAGE_NAME = 'schedule/';
export const BACKEND_IMAGE_SERVICE = 'Public/image.ashx?i=';
export const ENDPOINTS = {
    login: {
        post: {
            url: 'api/connect/token',
            type: 'POST',
            params: {
                grant_type: "password",
                scope: "adagio.admin_access offline_access",
                client_id: "admin.js.client"
            },
            passErrors: true,
            usesUrlEncodedParams: true
        }
    },

    REFRESH: {
        url: 'api/connect/token',
        params: {
            grant_type: "refresh_token",
            scope: "adagio.admin_access offline_access",
            client_id: "admin.js.client"
        }
    },

    logout: {
        post: {
            url: 'api/connect/revocation',
            type: 'POST',
            params: {
                token_type_hint: "refresh_token",
                client_id: "admin.js.client"
            },
            usesUrlEncodedParams: true
        }
    },

    account: {
        GET_ONE: {
            isSecure: true,
            url: 'api/users/account',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/users/account',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    teacher_account: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/teachers/account/details',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/teachers/account/details',
            type: 'PUT',
            passErrors: true
        }
    },

    notifications: {
        GET_LIST: {
            isSecure: true,
            url: 'api/notifications',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/notifications',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/notifications',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/notifications',
            type: 'POST',
            passErrors: true
        }
    },

    home_address: {
        GET_LIST: {
            isSecure: true,
            url: 'api/users/address',
            type: 'GET',
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/users/address',
            type: 'GET',
            passErrors: true
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/users/address',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/users/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    age_intervals: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/age-intervals',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/age-intervals',
            type: 'GET',
            passErrors: true
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/age-intervals',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/age-intervals',
            type: 'POST',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/age-intervals',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    notifications_locations: {
        GET_LIST: {
            isSecure: true,
            url: 'api/notifications/locations',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/notifications/locations',
            type: 'GET',
        }
    },

    notifications_sessions: {
        GET_LIST: {
            isSecure: true,
            url: 'api/notifications/sessions',
            type: 'GET',
            mapFiltersToParams: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/notifications/sessions',
            type: 'GET'
        }
    },

    semesters: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/semesters',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/semesters',
            type: 'GET'
        }
    },

    notifications_schedules: {
        GET_LIST: {
            isSecure: true,
            url: 'api/notifications/schedules',
            type: 'GET',
            mapFiltersToParams: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/notifications/schedules',
            type: 'GET',
            mapFiltersToParams: true
        }
    },

    notifications_created_by: {
        GET_LIST: {
            isSecure: true,
            url: 'api/notifications/sent-by',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/notifications/sent-by',
            type: 'GET',
        }
    },

    register: {
        CREATE: {
            url: 'api/tenants',
            type: 'POST',
            passErrors: true
        }
    },

    schools: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    locations: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'DELETE',
            needsId: true,
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'api/schools/locations',
            type: 'DELETE',
        }
    },

    tenant_users: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/tenant-users',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/tenant-users',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/tenant-users',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/tenant-users',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schools/tenant-users',
            type: 'DELETE',
            needsId: true,
        },
        "SEND_ACTIVATION_EMAIL": {
            isSecure: true,
            url: 'api/schools/tenant-users/resend-activation-email',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "RESET_PASSWORD": {
            isSecure: true,
            url: 'api/schools/tenant-users/change-forgotten-password',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "CHANGE_ACTIVE_STATUS": {
            isSecure: true,
            url: 'api/schools/tenant-users/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "CHANGE_NOTIFICATIONS_STATUS": {
            isSecure: true,
            url: 'api/schools/tenant-users/change-notifications-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    tenant_users_address: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/tenant-users/address',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/tenant-users/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    tenant_users_email: {
        UPDATE: {
            isSecure: true,
            url: 'api/schools/tenant-users/email',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    users_family_members: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            hasParams: true,
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY_REFERENCE: {
            isSecure: true,
            url: 'api/schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/tenant-users/family',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/tenant-users/family-member',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    users_emergency_contact: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/tenant-users/emergency-contact',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/tenant-users/emergency-contact',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    calendar: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'GET',
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schools/calendar',
            type: 'DELETE',
            needsId: true,
        }
    },

    courses: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'api/schedule/courses',
            type: 'DELETE',
            passErrors: true
        }
    },

    studios: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'api/schools/studios',
            type: 'DELETE',
            passErrors: true
        }
    },

    course_categories: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'api/schedule/course-categories',
            type: 'DELETE',
            passErrors: true
        }
    },

    course_levels: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'api/schedule/course-levels',
            type: 'DELETE',
            passErrors: true
        }
    },

    course_image: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/courses/image',
            type: 'GET',
            needsId: true,
            passErrors: true,
            returnsRawData: true,
            options: {responseType: 'blob'}
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/courses/image',
            type: 'POST',
            needsId: true,
            passErrors: true
        }
    },

    teachers: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/teachers',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/teachers',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schools/teachers',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/teachers',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/teachers',
            type: 'POST',
            passErrors: true
        },
        "SEND": {
            isSecure: true,
            url: 'api/schools/teachers/resend-invitation',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    teachers_assignments: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/teachers/assignments',
            type: 'GET',
            needsId: true
        },
    },

    school_years: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/school-years',
            type: 'GET'
        },
    },

    holidays: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/holidays',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/holidays',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/holidays',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/holidays',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schools/holidays',
            type: 'DELETE',
            needsId: true,
        }
    },

    staff: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schools/staff',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schools/staff',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schools/staff',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schools/staff',
            type: 'POST',
            passErrors: true
        },
        "CHANGE_ACTIVE_STATUS": {
            isSecure: true,
            url: 'api/schools/staff/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "SEND": {
            isSecure: true,
            url: 'api/schools/staff/resend-invitation',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    fees: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schedule/fees',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    schedule_move: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/move',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/move',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_duration: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/day/change-duration',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/day/change-duration',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_teachers: {
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/assign-teachers',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'api/schedule/assign-teachers',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_day_of_week: {
        DELETE: {
            isSecure: true,
            url: 'api/schedule/day',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
    },

    price_and_preview: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/price-and-review',
            type: 'POST',
            passErrors: true
        },
    },

    overlapping_validation: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/overlapping-validation',
            type: 'POST',
            passErrors: true
        },
    },

    schedules: {
        GET_LIST: {
            isSecure: true,
            url: 'api/schedule/weekly',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'api/schedule/weekly',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'api/schedule',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'api/schedule',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
    },

};
