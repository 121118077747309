export const styles = () => ({
    neteaScheduler: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden'
    },

    mainTable: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        zIndex: 2
    },

    headRow: {
        height: 48,
        display: 'flex'
    },

    stickyHeadRow: {
        height: 48,
    },

    stickyHeadRowSticked: {
        height: 48,
        display: 'block',
        position: 'fixed',
        transform: 'translate3d(0px, 0px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    stickyHeadRowStickedAndTranslated: {
        height: 48,
        display: 'block',
        position: 'fixed',
        transform: 'translate3d(0px, 48px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    headCell: {
        borderCollapse: 'collapse',
        padding: 0
    },

    timeslot: {
        flexShrink: 0,
        border: '2px solid #969696',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: 48,
        lineHeight: '48px',
        display: 'inline-block',
        width: 180,
        textAlign: 'center'
    },

    timeSubSlot: {
        border: '1px dotted #DBDBDB',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        display: 'inline-block',
        padding: 0
    },

    weekdayRow: {
        height: 48,
        display: 'flex',
        backgroundColor: '#DBDBDB'
    },

    resourceRow: {
        minHeight: 120,
        display: 'flex'
    },

    contentTimeslot: {
        border: '2px solid #969696',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        display: 'inline-block',
        position: 'relative'
    },

    contentResource: {
        border: 'none',
        height: '100%',
        display: 'table',
        width: 170
    },

    scrollingCell: {
        display: 'flex',
        padding: 0,
        overflowX: 'scroll',
        overflowY: 'hidden',
        margin: '0px 0px -15px',
        borderLeft: '1px dotted lightgrey'
    },

    scrollerWrapper: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        height: 15,
        position: 'fixed',
        bottom: 0,
        zIndex: 3
    },
});
