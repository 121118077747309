import React, {Component, Fragment} from 'react';
import {Create} from 'react-admin';
import {CourseCategoryForm} from '../';
import {BackButton} from '../../../../common';


class CourseCategoryCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <h1>Create Course Category</h1>
                <Create {...this.props} title=" ">
                    <CourseCategoryForm />
                </Create>
            </Fragment>
        );
    }
}
export default CourseCategoryCreate;
