import React, {Component} from "react";
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";

class ScheduleTimePicker extends Component {
    renderLabel = (date) => {
        if (date.isValid()) {
          return date.format(this.props.dateFormat ? this.props.dateFormat : 'h:mm A');
        } else {
          return '';
        }
    };

    render() {
        const {
            label,
            source,
            resource,
            isRequired,
            meta,
            disablePast
        } = this.props;

        const {touched, error} = meta;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <TimePicker
                    margin="normal"
                    id={"time_picker_" + source}
                    label={
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    }
                    value={this.props.val ? this.props.val : this.props.input.value}
                    onChange={this.props.onChange ? value => this.props.onChange(value, 'Picker') : this.props.input.onChange}
                    format={this.props.dateFormat ? this.props.dateFormat : 'h:mm A'}
                    style={{marginTop: 10}}
                    error={!!(touched && error)}
                    helperText={touched && error}
                    disablePast={disablePast}
                    labelFunc={this.renderLabel}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
export default addField(ScheduleTimePicker);
