import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';
import {HolidaysListFilters} from '../';
import SplitButton from '../../../common/containers/SplitButton';


export const HolidaysList = props => (
    <div className="wrapperContent">
        <h1 className="listTitle">Holidays List</h1>
        <List filters={<HolidaysListFilters />} {...props} title=' ' sort={{field: 'id', order: 'DESC'}} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="HolidayName" label="Name" sortable={false}/>
                <TextField source="From" label="From Date" sortable={false}/>
                <TextField source="To" label="To Date" sortable={false}/>
                <SplitButton 
                    cellClassName='splitButtonTdSmall'
                    successMessage='Holiday deleted'
                    errorMessage='Holiday NOT deleted'
                    confirmTitle='Delete the holidays'
                    confirmContent='Are you sure you want to delete the holiday?'
                />
            </Datagrid>
        </List>
    </div>
);