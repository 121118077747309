import React, {Fragment} from 'react';
import {SelectInput, TextInput} from 'react-admin';

const AdvancedFilterInput = props => {
    const filterName = props.source ? props.source : 'advanced';

    return (
        <Fragment>
            <TextInput 
            source={filterName + ".field"}
            label='Field'
            inputProps={{
                readOnly: true
            }}
            />
            &nbsp;
            <SelectInput
                source={filterName + ".operator"}
                choices={[
                    {id: 'LIKE', name: 'contains'},
                    {id: 'NOT LIKE', name: 'does not contain'},
                    {id: '=', name: 'equals to'},
                    {id: '<>', name: 'does not equal to'},
                ]}
                translateChoice={false}
                label='Operator'
            />
            &nbsp;
            <TextInput className='fieldsWidthExpand' source={filterName + ".value"} label='Value' />
        </Fragment>
    );
};
export default AdvancedFilterInput;
