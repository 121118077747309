import React from 'react';
import {TextField, FunctionField, RichTextField, SingleFieldList, ChipField} from 'react-admin';
import {formatPhone, formatWorkHour} from "../../../helpers";
import {DetailsLayout, DetailsTileWrapper} from '../../common';


export const SchoolShow = props => {
    const fieldsToFormat = [
        {
            field: 'Phone',
            formatter: formatPhone
        },

        {
            field: 'Fax',
            formatter: formatPhone
        },

        {
            field: 'WorkStartTime',
            formatter: formatWorkHour
        },

        {
            field: 'WorkEndTime',
            formatter: formatWorkHour
        },
    ];

    const options = {
        detailsTitle: 'school',
        titleField: 'SchoolName',
        removeDeleteButton: true,
        removeListButton: true
    };

    return (
        <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
            <DetailsTileWrapper
                sideTitle='School Details'
                sideText='Add your school here.'
            >
                <TextField source='SchoolName' />
                <TextField source='Phone' />
                <TextField source='Email' />
                <TextField source='ContactPerson' />
                <RichTextField source='Description'/>
                <TextField source='Director' />
                <TextField source='Fax' />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <TextField source='URL' label="Url" />
                <WorkDays source="WorkDays" />
                <TextField source='WorkStartTime' />
                <TextField source='WorkEndTime' />
            </DetailsTileWrapper>
            <DetailsTileWrapper
                sideTitle='School Address'
                sideText='This address will appear on your invoices.'
                recordObject='Address'
            >
                <TextField source='Address.StreetAddress' label='Street Address' />
                <TextField source='Address.USState' label='State' />
                <TextField source='Address.ZIP' label='Zip' />
                <TextField source='Address.StreetAddressLine2' label='Street Address Line 2' />
                <TextField source='Address.City' label='City' />
            </DetailsTileWrapper>
        </DetailsLayout>
    );
};


const WorkDays = ({record, source}) => {
    if (!record[source]) {
        return null;
    }

    let myRec = {};
    for (let t = 0; t < record[source].length; t++) {
        myRec[record[source][t]] = {id: record[source][t], name: record[source][t]};
    }

    return (
        <SingleFieldList ids={record[source]} data={myRec}>
            <ChipField source='name' />
        </SingleFieldList>
    );
};
WorkDays.defaultProps = {addLabel: true};
