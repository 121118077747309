import {connect} from 'react-redux';
import ChangeEmailButton from '../components/ChangeEmailButton';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    // REDUX_FORM_NAME
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    // submit
};


export default connect(null, mapDispatchToProps)(
    ChangeEmailButton
);