import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {StudioForm} from '../';
import {BackButton} from '../../../common';


const StudioCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <h1>Create Studio</h1>
            <Create {...props} title=" ">
                <StudioForm />
            </Create>
        </Fragment>
    );
};

export default StudioCreate;
