import React, {Component, Fragment} from 'react';
import {
    CREATE,
    required,
    TextInput,
    Toolbar,
    FormDataConsumer,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import AdagioAutoComplete from '../../../components/AdagioAutoComplete'
import {notificationTypesChoices} from '../';
import {fetchIt} from "../../../helpers";
import {getErrorMessage} from "../../../helpers";
import {Redirect} from 'react-router';
// import {scheduleValidation} from '../../../validators';
import {NtaSaveButton} from '../../common';


export const NotificationCreateToolbar = props => (
    <Toolbar {...props} >
        <NtaSaveButton label="Send" />
    </Toolbar>
);


class NotificationCreateForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        const { fetchStart, fetchEnd, showNotification } = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            CREATE,
            'notifications',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Notification sent.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    render() {
        let props = {};
        props.basePath = this.props.basePath;
        props.record = this.props.record;
        props.resource = this.props.resource;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <SimpleForm
                    { ...props }
                    save={this.handleSubmit}
                    toolbar={<NotificationCreateToolbar />}
                >
                    <ReferenceInput
                        required
                        label='Location'
                        source='LocationId'
                        resource='notifications_locations'
                        reference='notifications_locations'
                        perPage={1000}
                        validate={required('The location field is required')}
                    >
                        <SelectInput optionText='Name' style={{width: 300}} />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {
                            ({formData, ...rest}) => {
                                return formData.LocationId ? (
                                    <ReferenceInput
                                        required
                                        label='Session'
                                        source='SessionId'
                                        resource='notifications_sessions'
                                        reference='notifications_sessions'
                                        filter={{LocationId: formData.LocationId}}
                                        perPage={1000}
                                        validate={required('The session field is required')}
                                    >
                                        <SelectInput optionText='Name' style={{width: 300}}/>
                                    </ReferenceInput>) : null;
                            }
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {
                            ({formData, ...rest}) => {
                                return formData.LocationId && formData.SessionId ? (
                                    <ReferenceInput
                                        required
                                        label="Schedule"
                                        source="ScheduleId"
                                        reference="notifications_schedules"
                                        filter={{LocationId: formData.LocationId, SessionId: formData.SessionId}}
                                        perPage={1000}
                                        validate={required('The Schedule field is required')}
                                    >
                                        <AdagioAutoComplete />
                                    </ReferenceInput>
                                ) : null;
                            }
                        }
                    </FormDataConsumer>

                    <RadioButtonGroupInput
                        source="NotificationType"
                        choices={notificationTypesChoices}
                        defaultValue='2'
                        translateChoice={false}
                    />

                    <TextInput
                        source='Subject'
                        validate={required('The subject field is required')}
                        style={{width: 300}}
                        multiline
                    />

                    <RichTextInput source='Message' validate={required('The message field is required')} />
                </SimpleForm>
            </Fragment>
        );
    }
}
export default NotificationCreateForm;
