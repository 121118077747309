import React, { Component, Fragment } from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    TextField,
    BooleanInput,
    FormDataConsumer,
} from 'react-admin';
import { fetchIt } from "../../../helpers/fetcher";
import { getErrorMessage } from "../../../helpers";
import { Redirect } from 'react-router';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { PhoneMaskedInput, ZipCodeMaskedInput, UsStates } from '../../../common';
import { emailValidation } from '../../../validators';
import { phoneValidation, faxValidation, zipCodeValidation } from '../../../validators';
import { formatPhone } from "../../../helpers";
import {NtaSaveButton} from '../../common';

export const LocationFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

export const LocationFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

export const AddressHeading = () => (
    <h2>
        Address
    </h2>
);

class LocationForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }
        if (values.Phone) {
            values.Phone = values.Phone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        
        if (values.Fax) {
            values.Fax = values.Fax.replace(/\(|\)|\s|-/gi, "").substring(0, 10); 
        }

        fetchIt(
            requestType,
            'locations',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Location saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    values.Phone = formatPhone(values.Phone);
                    values.Fax = formatPhone(values.Fax);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.Phone = formatPhone(values.Phone);
                    values.Fax = formatPhone(values.Fax);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <SimpleForm
                            resource={this.props.resource}
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<LocationFormToolbar isDisabled={this.state.isDisabled} />}
                        >
                            <TextInput source="LocationName" className='fieldsWidthExpand' validate={required('The Location Name field is required')} />
                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return <LocationFormTitle title={formData.LocationName} />;
                                    }
                                }
                            </FormDataConsumer>
                            <PhoneMaskedInput
                                required
                                source='Phone'
                                validate={phoneValidation}
                                className='fieldsWidthExpand'
                            />
                            <PhoneMaskedInput
                                required
                                source='Fax'
                                validate={faxValidation}
                                className='fieldsWidthExpand'
                            />
                            <TextInput type='email' source='Email' className='fieldsWidthExpand' validate={emailValidation} />
                            <TextInput source='ContactPerson' className='fieldsWidthExpand' validate={required('The Contact Person field is required')} />
                            <BooleanInput source='IsActive'  defaultValue={this.props.editMode ? '' : true} />

                            {
                                this.props.editMode ?
                                <Fragment>
                                    <TextField source='CreatedTime' />
                                    <TextField source='ModifiedTime' />
                                    <TextField source='CreatedBy' />
                                    <TextField source='ModifiedBy' />
                                </Fragment>
                                :null
                            }
                            <AddressHeading />
                            <TextInput source='Address.City' label='City' className='fieldsWidthExpand' validate={required('The City field is required')} />
                            <UsStates source='Address.USState' className='fieldsWidthExpand' validate={required('The State field is required')}/>

                            <ZipCodeMaskedInput
                                required
                                source='Address.ZIP'
                                label='ZIP Code'
                                validate={zipCodeValidation}
                                className='fieldsWidthExpand'
                            />
                            <TextInput
                                multiline
                                source='Address.StreetAddress'
                                label='Street Address'
                                validate={required('The Street Address field is required')}
                                className='fieldsWidthExpand'
                            />
                            <TextInput source='Address.StreetAddressLine2' label='Street Address Line 2' className='fieldsWidthExpand' />
                        </SimpleForm>
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}
export default LocationForm;
