import React, { Fragment } from 'react';
import {
    TextField,
    FunctionField,
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../../common';
import {formatPhone} from "../../../../helpers";


export const StaffShow = props => {
    const fieldsToFormat = [
        {
            field: 'CellPhone',
            formatter: formatPhone
        }
    ];

    const options = {
        detailsTitle: 'staff',
        titleField: 'FirstName',
        lastNameField: 'LastName',
        removeDeleteButton: true, 
    };

    return (
        <Fragment>
            <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
                <DetailsTileWrapper
                    sideTitle='Staff Details'
                    sideText='Add your data here.'
                >
                    <FunctionField source='FirstName' label="Name" render={record => `${record.FirstName} ${record.LastName}`} />
                    <TextField source='Email' />
                    <TextField source='CellPhone' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                    <TextField source='Role' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};