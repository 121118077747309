import React, {Component} from "react";
import InputMask from 'react-input-mask';
import {addField, TextInput} from "react-admin";

class ZipCodeMaskedInput extends Component {
    beforeMaskedValueChange = (newState, userInput) => {
        let {value} = newState;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.props.input.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {start: cursorPosition, end: cursorPosition};
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    };

    render() {
        return (
            <InputMask
                mask="99999-9999"
                maskChar={null}
                beforeMaskedValueChange={this.beforeMaskedValueChange}
                placeholder="XXXXX-XXXX"
                onChange={this.props.input.onChange}
                value={this.props.input.value}
                style={{marginTop: 40}}
            >
                {(inputProps) => <TextInput {...inputProps} { ...this.props } />}
            </InputMask>
        );

    }
}
export default addField(ZipCodeMaskedInput);