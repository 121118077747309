import React, {Fragment} from 'react';
import {
    TextField,
    RichTextField,
    FunctionField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    SelectField,
    NumberField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../../common';
import {classTypeChoices} from "../../../../config/constants";
import CourseImage from '../containers/CourseImage';


export const CourseShow = props => {

    return (
        <Fragment>
            <DetailsLayout {...props} >
                <DetailsTileWrapper
                    sideTitle='Course Details'
                    sideText='Add your Course here.'
                >
                    <TextField source='Name' label='Course Name' />
                    <ReferenceArrayField source="Categories" reference="course_categories" resource="course_categories">
                        <SingleFieldList linkType={false}>
                            <ChipField source="Name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceField source="CourseLevel" reference="course_levels" resource="course_levels" linkType={false}>
                        <TextField source="Name" />
                    </ReferenceField>
                    <SelectField source="ClassType" choices={classTypeChoices} />
                    <RichTextField source='Description' />
                    <ReferenceField source="AgeInterval" reference="age_intervals" linkType={false}>
                        <TextField source="Name" />
                    </ReferenceField>
                    <NumberField source="Duration" label="Duration (in minutes)" />
                    <TextField source="Places" />
                    <NumberField source="SingleClassPrice" />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    sideTitle='Course Image'
                    sideText='Add Course Image here.'
                    passComponent={true}
                >
                    <CourseImage source='course_image.Image' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
